import { Breadcrumbs, Typography } from '@mui/material';
import AppBlock from 'components/app-block';
import Header from 'components/header';
import Nature from 'components/nature';
import config from 'config';
import React from 'react';
import { Link as LinkRout } from 'react-router-dom';

const About = () => {
    return (
        <div>
            <Header />
            <div className="wrapper owerflow">
                <section className="section about">
                    <div className="container">
                        <Breadcrumbs className="breadcrumbs for_about" aria-label="breadcrumb">
                            <LinkRout underline="hover" color="inherit" to="/">
                                Главная
                            </LinkRout>
                            <Typography color="text.primary">О компании</Typography>
                        </Breadcrumbs>

                        <div className="about_content-box">
                            <div className="about_desc">
                                <h1 className="page_title">О компании </h1>
                                <div className="content_box for_about w-richtext">
                                    <p>
                                        Более трех лет назад, когда только родилась Марьяша, я загорелась идеей о своем большом продукте, о
                                        чем-то, что было бы полезно каждой девушке.
                                    </p>
                                    <p>
                                        <strong>
                                            ⠀<br />
                                        </strong>
                                        В то время, да и сейчас все полки были забиты средствами с яркими заголовками «Антицеллюлитный»,
                                        «Похудение», волшебные флакончики, обещающие результат без усилий.
                                        <br />
                                        ⠀<br />
                                        Те, кто читают меня давно знают, что если меня что-то не устраивает, я решу любую проблему. Поэтому
                                        я начала глубоко, всесторонне изучать и разбираться в причинах появления целлюлита.
                                        <br />
                                        ⠀<br />
                                        Мы работали с питанием, делали массаж из подручных средств по десяткам методик, многие из которых
                                        очень трудно найти в свободном доступе.
                                    </p>
                                    <p>&nbsp;</p>
                                    <p>
                                        <img
                                            width="814"
                                            height="1024"
                                            className="dhide_img alignnone size-large wp-image-176"
                                            src={`${config.storeUrl}/61a5dd289e3d5790d9da9fa1_Rectangle2035-814x1024.jpg`}
                                            alt=""
                                            srcSet={`
                                            ${config.storeUrl}/61a5dd289e3d5790d9da9fa1_Rectangle2035-814x1024.jpg 814w, 
                                            ${config.storeUrl}/61a5dd289e3d5790d9da9fa1_Rectangle2035-600x755.jpg 600w, 
                                            ${config.storeUrl}/61a5dd289e3d5790d9da9fa1_Rectangle2035-238x300.jpg 238w, 
                                            ${config.storeUrl}/61a5dd289e3d5790d9da9fa1_Rectangle2035-768x967.jpg 768w,
                                            ${config.storeUrl}/61a5dd289e3d5790d9da9fa1_Rectangle2035-200x252.jpg 200w, 
                                            ${config.storeUrl}/61a5dd289e3d5790d9da9fa1_Rectangle2035.jpg 1176w`}
                                            sizes="(max-width: 814px) 100vw, 814px"
                                        />
                                    </p>
                                    <p>
                                        Каждая из участниц моих программ видела результат, и я светилась от счастья. Да, работа заняла очень
                                        много времени. Но мне удалось создать «умную» косметику, которая доводит до результата.
                                        <br />⠀
                                    </p>
                                    <p>
                                        <img
                                            width="750"
                                            height="800"
                                            className="alignnone size-full wp-image-175"
                                            style={{ maxWidth: '375px' }}
                                            src={`https://millidge.site/wp-content/${config.storeUrl}/61a5dd287fc05547d8b76958_Rectangle2036.jpg`}
                                            alt=""
                                            srcSet={`
                                            ${config.storeUrl}/61a5dd287fc05547d8b76958_Rectangle2036.jpg 750w, 
                                            ${config.storeUrl}/61a5dd287fc05547d8b76958_Rectangle2036-600x640.jpg 600w, 
                                            ${config.storeUrl}/61a5dd287fc05547d8b76958_Rectangle2036-281x300.jpg 281w, 
                                            ${config.storeUrl}/61a5dd287fc05547d8b76958_Rectangle2036-236x252.jpg 236w`}
                                            sizes="(max-width: 750px) 100vw, 750px"
                                        />
                                    </p>
                                    <p>
                                        И сейчас я могу с гордостью сказать: это то, чего я хотела!Аналогов MILLIDGE нет. Авторская
                                        разработка состава в собственной лаборатории, я думала об этом более трех лет! Изо дня в день мы
                                        последовательно воплощали мою идею в жизнь!
                                    </p>
                                </div>
                            </div>
                            <div className="about_image_box first_img mhide">
                                <img
                                    src={`${config.storeUrl}/61a5dd289e3d5790d9da9fa1_Rectangle2035.jpg`}
                                    loading="lazy"
                                    width="588"
                                    alt=""
                                    className="about_image"
                                    title=""
                                />
                            </div>
                        </div>
                        <div className="about_content-box right_side">
                            <div className="about_desc2">
                                <div className="about_desc pb0">
                                    <div className="content_box w-richtext">
                                        <p>Да, я хотела создать косметику, но с чего начать, куда обращаться?</p>
                                        <p>
                                            ⠀<br />
                                            На каждом этапе начиная от выбора флакончиков, косметички, упаковки, мы перебрали от 3 до 5
                                            разных поставщиков, чтобы найти то, что действительно отражает моё видение!
                                            <br />
                                            Было предложено более 20-ти различных вариантов кремов, я выбирала, проверяла, хотела сделать
                                            лучший продукт!
                                        </p>
                                    </div>
                                </div>
                                <div className="about_image_box first_img">
                                    <img
                                        src={`${config.storeUrl}/61a5dd28f8821a3e9c5ac048_Rectangle2037.jpg`}
                                        loading="lazy"
                                        width="588"
                                        alt=""
                                        className="about_image"
                                        title=""
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="about_content-box centeres">
                            <div className="about_box col1">
                                <h2 className="nature_title">Millidge - это удовольствие и результат </h2>
                                <div className="content_box about_box1 w-richtext">
                                    <p>Каждый наш продукт &#8212; это решение конкретной задачи и шаг к цели!</p>
                                </div>
                            </div>
                            <div className="about_box">
                                <img
                                    src={`${config.storeUrl}/4B9A8034-Edit-scaled.jpg`}
                                    loading="lazy"
                                    width="588"
                                    alt=""
                                    className="about_image"
                                    title="4B9A8034-Edit"
                                />
                            </div>
                            <div className="about_box3">
                                <div className="content_box about_box12 w-richtext">
                                    <p>Millidge &#8212; больше, чем антицеллюлитная косметика.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Nature />
            </div>
            <AppBlock />
        </div>
    );
};

export default About;
