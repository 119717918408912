import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import currentReducer from './current-reducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    state: currentReducer
});

export default reducer;
