import React from 'react';
import PropTypes from 'prop-types';

const Effect = ({
    blok_effekt_zagolovok,

    blok_effekt_dobavit_effekt_0_effekt,
    blok_effekt_dobavit_effekt_1_effekt,
    blok_effekt_dobavit_effekt_2_effekt,

    blok_effekt_dobavit_blok_effekta_0_zagolovok,
    blok_effekt_dobavit_blok_effekta_1_zagolovok,
    blok_effekt_dobavit_blok_effekta_2_zagolovok,

    blok_effekt_dobavit_blok_effekta_0_opisanie,
    blok_effekt_dobavit_blok_effekta_1_opisanie,
    blok_effekt_dobavit_blok_effekta_2_opisanie,

    blok_effekt_dobavit_blok_effekta_0_img,
    blok_effekt_dobavit_blok_effekta_1_img,
    blok_effekt_dobavit_blok_effekta_2_img
}) => {
    return (
        <section className="section effect">
            <div className="container effect">
                <div className="cat_header_box for_effect">
                    <h2 className="nature_title for_effect">{blok_effekt_zagolovok}</h2>
                    <div>
                        {blok_effekt_dobavit_blok_effekta_0_zagolovok && (
                            <div className="effect_desc_block">
                                <div className="effect_subbox">
                                    <img
                                        src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                        loading="lazy"
                                        alt=""
                                        className="effect_img"
                                        title=""
                                        data-lazy-src={blok_effekt_dobavit_blok_effekta_0_img}
                                    />
                                    <div className="effect_desc_title">{blok_effekt_dobavit_blok_effekta_0_zagolovok}</div>
                                </div>
                                <div className="effect_desc_box">
                                    <div className="effect_desc">{blok_effekt_dobavit_blok_effekta_0_opisanie}</div>
                                </div>
                            </div>
                        )}
                        {blok_effekt_dobavit_blok_effekta_1_zagolovok && (
                            <div className="effect_desc_block">
                                <div className="effect_subbox">
                                    <img
                                        src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                        loading="lazy"
                                        alt=""
                                        className="effect_img"
                                        title=""
                                        data-lazy-src={blok_effekt_dobavit_blok_effekta_1_img}
                                    />
                                    <div className="effect_desc_title">{blok_effekt_dobavit_blok_effekta_1_zagolovok}</div>
                                </div>
                                <div className="effect_desc_box">
                                    <div className="effect_desc">{blok_effekt_dobavit_blok_effekta_1_opisanie}</div>
                                </div>
                            </div>
                        )}
                        {blok_effekt_dobavit_blok_effekta_2_zagolovok && (
                            <div className="effect_desc_block">
                                <div className="effect_subbox">
                                    <img
                                        src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                        loading="lazy"
                                        alt=""
                                        className="effect_img"
                                        title=""
                                        data-lazy-src={blok_effekt_dobavit_blok_effekta_2_img}
                                    />
                                    <div className="effect_desc_title">{blok_effekt_dobavit_blok_effekta_2_zagolovok}</div>
                                </div>
                                <div className="effect_desc_box">
                                    <div className="effect_desc">{blok_effekt_dobavit_blok_effekta_2_opisanie}</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="effect_items">
                    {blok_effekt_dobavit_effekt_0_effekt && (
                        <div className="effect_item">
                            <div className="effect_num">01</div>
                            <div className="effect_title">{blok_effekt_dobavit_effekt_0_effekt}</div>
                        </div>
                    )}
                    {blok_effekt_dobavit_effekt_1_effekt && (
                        <div className="effect_item">
                            <div className="effect_num">02</div>
                            <div className="effect_title">{blok_effekt_dobavit_effekt_1_effekt}</div>
                        </div>
                    )}
                    {blok_effekt_dobavit_effekt_2_effekt && (
                        <div className="effect_item">
                            <div className="effect_num">03</div>
                            <div className="effect_title">{blok_effekt_dobavit_effekt_2_effekt}</div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

Effect.propTypes = {
    blok_effekt_zagolovok: PropTypes.string,

    blok_effekt_dobavit_effekt_0_effekt: PropTypes.string,
    blok_effekt_dobavit_effekt_1_effekt: PropTypes.string,
    blok_effekt_dobavit_effekt_2_effekt: PropTypes.string,

    blok_effekt_dobavit_blok_effekta_0_zagolovok: PropTypes.string,
    blok_effekt_dobavit_blok_effekta_1_zagolovok: PropTypes.string,
    blok_effekt_dobavit_blok_effekta_2_zagolovok: PropTypes.string,

    blok_effekt_dobavit_blok_effekta_0_opisanie: PropTypes.string,
    blok_effekt_dobavit_blok_effekta_1_opisanie: PropTypes.string,
    blok_effekt_dobavit_blok_effekta_2_opisanie: PropTypes.string,

    blok_effekt_dobavit_blok_effekta_0_img: PropTypes.string,
    blok_effekt_dobavit_blok_effekta_1_img: PropTypes.string,
    blok_effekt_dobavit_blok_effekta_2_img: PropTypes.string
};

export default Effect;
