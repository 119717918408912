import React from 'react';
import PropTypes from 'prop-types';

export const PlusMinusCountItem = ({ handleMinusProduct, handleAddToCart, count }) => {
    return (
        <div className="add_count">
            <div
                role="button"
                tabIndex={0}
                onClick={() => handleMinusProduct()}
                onKeyDown={() => handleMinusProduct()}
                className="change-count minus"
            />
            <div className="count_input w-input">{count}</div>
            <div
                role="button"
                tabIndex={0}
                onClick={() => handleAddToCart()}
                onKeyDown={() => handleAddToCart()}
                className="change-count plus"
            />
        </div>
    );
};

PlusMinusCountItem.propTypes = {
    count: PropTypes.number,
    handleMinusProduct: PropTypes.func,
    handleAddToCart: PropTypes.func
};
