import React from 'react';
import { Outlet } from 'react-router';
import Payments from 'pages/payments';
import PaymentsFailed from 'pages/payments/payment-failed';
import PaymentsSuccess from 'pages/payments/payment-success';

// login option 3 routing

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const PaymentsRoutes = {
    path: '/payments/',
    element: <Outlet />,
    children: [
        {
            path: '/',
            element: <Payments />
        },
        {
            path: '/failed',
            element: <PaymentsFailed />
        },
        {
            path: '/success/:orderId',
            element: <PaymentsSuccess />
        }
    ]
};

export default PaymentsRoutes;
