import React from 'react';
import MainLayout from 'layout/main-layout';
import Bestsellers from 'pages/bestsellers';
import Anticellulite from 'pages/anticellulite';
import ForFace from 'pages/for-face';
import Catalog from 'pages/catalog';
import ProductFull from 'pages/product-full';
import ForHandsFoots from 'pages/for-hands-foots';

// login option 3 routing

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const ProductsRoutes = {
    path: '/products/',
    element: <MainLayout />,
    children: [
        {
            path: '/catalog',
            element: <Catalog />
        },
        {
            path: '/bestsellers',
            element: <Bestsellers />
        },
        {
            path: '/anticellulite',
            element: <Anticellulite />
        },
        {
            path: '/for-face',
            element: <ForFace />
        },
        {
            path: '/for-hands-foots',
            element: <ForHandsFoots />
        },
        {
            path: '/product/:slug',
            element: <ProductFull />
        }
    ]
};

export default ProductsRoutes;
