import config from 'config';
import React from 'react';
import Slider from 'react-slick/lib/slider';
import PropTypes from 'prop-types';

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1.5,
    slidesToScroll: 1
};

const Ingredients = ({
    blok_ingredienty_zagolovok,

    imageUrl,

    blok_ingredienty_ingredient_1,

    blok_ingredienty_ingredient_2,

    blok_ingredienty_ingredient_3,

    blok_ingredienty_ingredient_4,

    blok_ingredienty_ingredient_5,

    blok_ingredienty_ingredient_6,

    blok_ingredienty_ingredient_7
}) => {
    const { innerWidth: width } = window;

    return (
        <div>
            <section data-w-id="dedbff88-0195-428e-d06c-a0bc484a237e" className="section component">
                <div className="container">
                    <div className="component_title_box">
                        <h2 className="nature_title for_component">{blok_ingredienty_zagolovok}</h2>
                    </div>
                </div>
                <div className="compotent_circle">
                    <div className="small_circle" />
                </div>
                <div className="compslider for_active">
                    {width < 760 ? (
                        <Slider {...settings}>
                            {blok_ingredienty_ingredient_1 && (
                                <div className="slide">
                                    <div className="comp1">
                                        <div className="component_visible">
                                            <div className="comp_element" />
                                            <div className="comp_title">{blok_ingredienty_ingredient_1}</div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {blok_ingredienty_ingredient_2 && (
                                <div className="slide">
                                    <div className="comp2">
                                        <div className="component_visible">
                                            <div className="comp_element" />
                                            <div className="comp_title">{blok_ingredienty_ingredient_2}</div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {blok_ingredienty_ingredient_3 && (
                                <div className="slide">
                                    <div className="comp3">
                                        <div className="component_visible">
                                            <div className="comp_element" />
                                            <div className="comp_title">{blok_ingredienty_ingredient_3}</div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {blok_ingredienty_ingredient_4 && (
                                <div className="slide">
                                    <div className="comp4">
                                        <div className="component_visible">
                                            <div className="comp_element" />
                                            <div className="comp_title">{blok_ingredienty_ingredient_4}</div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {blok_ingredienty_ingredient_5 && (
                                <div className="slide">
                                    <div className="comp5">
                                        <div className="component_visible">
                                            <div className="comp_element" />
                                            <div className="comp_title">{blok_ingredienty_ingredient_5}</div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {blok_ingredienty_ingredient_6 && (
                                <div className="slide">
                                    <div className="comp6">
                                        <div className="component_visible">
                                            <div className="comp_element" />
                                            <div className="comp_title">{blok_ingredienty_ingredient_6}</div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {blok_ingredienty_ingredient_7 && (
                                <div className="slide">
                                    <div className="comp7">
                                        <div className="component_visible">
                                            <div className="comp_element" />
                                            <div className="comp_title">{blok_ingredienty_ingredient_7}</div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Slider>
                    ) : (
                        <div>
                            {blok_ingredienty_ingredient_1 && (
                                <div className="slide">
                                    <div className="comp1">
                                        <div className="component_visible">
                                            <div className="comp_element" />
                                            <div className="comp_title">{blok_ingredienty_ingredient_1}</div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {blok_ingredienty_ingredient_2 && (
                                <div className="slide">
                                    <div className="comp2">
                                        <div className="component_visible">
                                            <div className="comp_element" />
                                            <div className="comp_title">{blok_ingredienty_ingredient_2}</div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {blok_ingredienty_ingredient_3 && (
                                <div className="slide">
                                    <div className="comp3">
                                        <div className="component_visible">
                                            <div className="comp_element" />
                                            <div className="comp_title">{blok_ingredienty_ingredient_3}</div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {blok_ingredienty_ingredient_4 && (
                                <div className="slide">
                                    <div className="comp4">
                                        <div className="component_visible">
                                            <div className="comp_element" />
                                            <div className="comp_title">{blok_ingredienty_ingredient_4}</div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {blok_ingredienty_ingredient_5 && (
                                <div className="slide">
                                    <div className="comp5">
                                        <div className="component_visible">
                                            <div className="comp_element" />
                                            <div className="comp_title">{blok_ingredienty_ingredient_5}</div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {blok_ingredienty_ingredient_6 && (
                                <div className="slide">
                                    <div className="comp6">
                                        <div className="component_visible">
                                            <div className="comp_element" />
                                            <div className="comp_title">{blok_ingredienty_ingredient_6}</div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {blok_ingredienty_ingredient_7 && (
                                <div className="slide">
                                    <div className="comp7">
                                        <div className="component_visible">
                                            <div className="comp_element" />
                                            <div className="comp_title">{blok_ingredienty_ingredient_7}</div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className="swipe on_result">Листайте вправо, чтобы посмотреть все ингредиенты товара</div>
                <img src={`${config.storeUrl}/61a1f36d5ff09c98c4311780_Rectandgle.png`} loading="eager" alt="" className="componet_bg" />
                <img
                    src={`${config.storeUrl}/61a214e516618a4e5a87e8ad_pod.png`}
                    loading="eager"
                    width="1058"
                    alt=""
                    className="component_product-podium"
                />
                <img src={imageUrl} loading="eager" alt="" className="component_product" title="Главный компонент товара фото-4" />
                <img
                    src={`${config.storeUrl}/61a51b836378fc80048c04d5_Rectanglde.png`}
                    loading="eager"
                    width="171.5"
                    alt=""
                    className="component_grape"
                />
                <img
                    src={`${config.storeUrl}/619ff18dccaf0942287bd1c7_list.png`}
                    loading="eager"
                    width="171.5"
                    alt=""
                    className="component_list"
                />
                <img
                    src={`${config.storeUrl}/619ff18dcc847f185ef8f88a_image208.png`}
                    loading="eager"
                    width="171.5"
                    alt=""
                    className="component_list2"
                />
                <img
                    src={`${config.storeUrl}/61a214158ec39572928d5e08_D0BAD183D181D18220D181D0BFD180D0B0D0B2D0B0.png`}
                    loading="eager"
                    alt=""
                    className="component_bush2"
                />
                <img
                    src={`${config.storeUrl}/61a2141565af7a2467d3eb56_D0BAD183D181D18220D181D0BBD0B5D0B2D0B0.png`}
                    loading="eager"
                    alt=""
                    className="component_bush"
                />
                <img
                    src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                    loading="lazy"
                    alt=""
                    className="component_list3"
                    data-lazy-src={`${config.storeUrl}/61a72f243fbd5737e01dac2c_image201h0.png`}
                />
            </section>
        </div>
    );
};

Ingredients.propTypes = {
    imageUrl: PropTypes.string,

    blok_ingredienty_zagolovok: PropTypes.string,

    blok_ingredienty_ingredient_1: PropTypes.string,

    blok_ingredienty_ingredient_2: PropTypes.string,

    blok_ingredienty_ingredient_3: PropTypes.string,

    blok_ingredienty_ingredient_4: PropTypes.string,

    blok_ingredienty_ingredient_5: PropTypes.string,

    blok_ingredienty_ingredient_6: PropTypes.string,

    blok_ingredienty_ingredient_7: PropTypes.string
};

export default Ingredients;
