import { Breadcrumbs, Typography } from '@mui/material';
import Header from 'components/header';
import config from 'config';
import React from 'react';
import { Link as LinkRout } from 'react-router-dom';

const DeliveryPage = () => {
    return (
        <section className="section faq">
            {/* header */}
            <Header />
            <div className="container">
                <Breadcrumbs className="breadcrumbs" aria-label="breadcrumb">
                    <LinkRout underline="hover" color="inherit" to="/">
                        Главная
                    </LinkRout>
                    <Typography color="text.primary">Доставка и оплата</Typography>
                </Breadcrumbs>

                <div className="delivery_box">
                    <h1 className="page_title">Доставка и оплата </h1>
                    <div className="delivery-qr_box">
                        <div className="del_header-box">
                            <div className="qr-header">Дорогие клиенты</div>
                        </div>
                        <div className="qr_hand">
                            <img
                                src="https://1790009369.rsc.cdn77.org/images/61a622ce2287248db5014704_D0B2Group20220(1).png"
                                loading="eager"
                                width="207"
                                alt=""
                                className="delivery_hand_img"
                            />
                        </div>
                        <div className="devivery-subheader_box">
                            <div className="content_box w-richtext">
                                Для того, чтобы уточнить статус заказа, свяжитесь с нами по email:{' '}
                                <a href="mailto:millidgecosmetic@gmail.com">
                                    <span className="__cf_email__">millidgecosmetic@gmail.com</span>
                                </a>{' '}
                                или WhatsApp{' '}
                            </div>
                        </div>
                    </div>
                    <div className="delivery_subtitle freedel">
                        ДОСТАВКА <span className="delivery_sub">ПО РФ, БЕЛАРУСИ И КАЗАХСТАНУ</span>{' '}
                    </div>
                    <div className="delivery_items">
                        <div className="delivery_item">
                            <div className="delivery_name">По Москве (в пределах МКАД) от </div>
                            <div className="delivery_price">200₽ </div>
                        </div>
                        <div className="delivery_item">
                            <div className="delivery_name">В Санкт-Петербург от </div>
                            <div className="delivery_price">200₽ </div>
                        </div>
                        <div className="delivery_item">
                            <div className="delivery_name">По Московской области от </div>
                            <div className="delivery_price">200₽ </div>
                        </div>
                        <div className="delivery_item">
                            <div className="delivery_name">В другие города России от </div>
                            <div className="delivery_price">200₽ </div>
                        </div>
                        <div className="delivery_item">
                            <div className="delivery_name">В Беларусь и Казахстан от </div>
                            <div className="delivery_price">390₽ </div>
                        </div>
                    </div>
                </div>
                <img
                    src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20994%200'%3E%3C/svg%3E"
                    loading="lazy"
                    width="994"
                    alt=""
                    className="delivery_bg dhide"
                    data-lazy-src={`${config.storeUrl}/61a8c4962d8a67608ad2d8f8_Object202.jpg`}
                />
                <h2 className="page_title centerred">СПОСОБЫ ДОСТАВКИ </h2>
                <div className="faq_box">
                    <div className="delivery_column">
                        <div className="delivery_subtitle big">КУРЬЕРСКАЯ СЛУЖБА </div>
                        <div className="delivery_icons">
                            <img
                                src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                loading="lazy"
                                alt=""
                                className="devivery_ico"
                                title="61a8c36f9730e502d2f5407d_image2012"
                                data-lazy-src={`${config.storeUrl}/61a8c36f9730e502d2f5407d_image2012-1.jpg`}
                            />
                            <img
                                src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                loading="lazy"
                                alt=""
                                className="devivery_ico"
                                title="61a8c36e28b935af9f206067_image2013"
                                data-lazy-src={`${config.storeUrl}/61a8c36e28b935af9f206067_image2013-1.jpg`}
                            />
                            <img
                                src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                loading="lazy"
                                alt=""
                                className="devivery_ico"
                                title="61a8c36e37ddd33d9d61fe30_image2014"
                                data-lazy-src={`${config.storeUrl}/61a8c36e37ddd33d9d61fe30_image2014-1.jpg`}
                            />
                            <img
                                src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                loading="lazy"
                                alt=""
                                className="devivery_ico"
                                title="61a8c36fb62f2eeaf80603d8_image2015"
                                data-lazy-src={`${config.storeUrl}/61a8c36fb62f2eeaf80603d8_image2015-1.jpg`}
                            />
                        </div>
                        <div className="faq_col for_delivery">
                            <div data-hover="false" data-delay="0" className="faq_drop for_delivery w-dropdown">
                                <div className="faq_drop-toggle w-dropdown-toggle">
                                    <div>По Москве (в пределах МКАД) </div>
                                    <div className="faq_btn small" />
                                </div>
                                <nav className="dropdown-list w-dropdown-list">
                                    <div className="faq_desc">
                                        Доставка осуществляется в рабочие и выходные дни с 10:00 до 23:00. Стоимость доставки - 490р.
                                        Доставляем в течение 2-15 дней после оформления заказа на сайте.{' '}
                                    </div>
                                </nav>
                            </div>
                            <div data-hover="false" data-delay="0" className="faq_drop for_delivery w-dropdown">
                                <div className="faq_drop-toggle w-dropdown-toggle">
                                    <div>По Москве за МКАД или Мо </div>
                                    <div className="faq_btn small" />
                                </div>
                                <nav className="dropdown-list w-dropdown-list">
                                    <div className="faq_desc">
                                        Доставка осуществляется в рабочие и выходные дни с 10:00 до 23:00. Стоимость доставки -4900р.
                                        Доставляем в течение 2-15 дней после оформления заказа на сайте.{' '}
                                    </div>
                                </nav>
                            </div>
                            <div data-hover="false" data-delay="0" className="faq_drop for_delivery w-dropdown">
                                <div className="faq_drop-toggle w-dropdown-toggle">
                                    <div>По России (курьером) </div>
                                    <div className="faq_btn small" />
                                </div>
                                <nav className="dropdown-list w-dropdown-list">
                                    <div className="faq_desc">
                                        Доставка осуществляется в рабочие и выходные дни с 10:00 до 23:00. Стоимость доставки - 500р.
                                        Доставляем в течение 2-15 дней после оформления заказа на сайте.{' '}
                                    </div>
                                </nav>
                            </div>
                            <div data-hover="false" data-delay="0" className="faq_drop for_delivery w-dropdown">
                                <div className="faq_drop-toggle w-dropdown-toggle">
                                    <div>Республика Беларусь и Казахстан (курьером) </div>
                                    <div className="faq_btn small" />
                                </div>
                                <nav className="dropdown-list w-dropdown-list">
                                    <div className="faq_desc">
                                        Доставка осуществляется в рабочие и выходные дни с 10:00 до 23:00. Стоимость доставки - 590р.
                                        Доставляем в течение 2-15 дней после оформления заказа на сайте.{' '}
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div className="delivery_column">
                        <div className="delivery_subtitle big">Почта россии </div>
                        <div className="delivery_icons">
                            <img
                                src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                loading="lazy"
                                alt=""
                                className="devivery_ico"
                                title="61a8c36ff655eb265923dfa6_image2016"
                                data-lazy-src={`${config.storeUrl}/61a8c36ff655eb265923dfa6_image2016-1.jpg`}
                            />
                        </div>
                        <div className="faq_col for_delivery">
                            <div data-hover="false" data-delay="0" className="faq_drop for_delivery w-dropdown">
                                <div className="faq_drop-toggle w-dropdown-toggle">
                                    <div>По Москве (почтой) </div>
                                    <div className="faq_btn small" />
                                </div>
                                <nav className="dropdown-list w-dropdown-list">
                                    <div className="faq_desc">
                                        Сроки доставки до отделения почты России - от 3 до 15 дней. Стоимость доставки -190р.{' '}
                                    </div>
                                </nav>
                            </div>
                            <div data-hover="false" data-delay="0" className="faq_drop for_delivery w-dropdown">
                                <div className="faq_drop-toggle w-dropdown-toggle">
                                    <div>По Москве за МКАД или Мо (почтой) </div>
                                    <div className="faq_btn small" />
                                </div>
                                <nav className="dropdown-list w-dropdown-list">
                                    <div className="faq_desc">
                                        Сроки доставки до отделения почты России - от 3 до 15 дней. Стоимость доставки -190р.{' '}
                                    </div>
                                </nav>
                            </div>
                            <div data-hover="false" data-delay="0" className="faq_drop for_delivery w-dropdown">
                                <div className="faq_drop-toggle w-dropdown-toggle">
                                    <div>По России (почтой) </div>
                                    <div className="faq_btn small" />
                                </div>
                                <nav className="dropdown-list w-dropdown-list">
                                    <div className="faq_desc">
                                        Сроки доставки до отделения почты России - от 3 до 15 дней. Стоимость доставки -190р.{' '}
                                    </div>
                                </nav>
                            </div>
                            <div data-hover="false" data-delay="0" className="faq_drop for_delivery w-dropdown">
                                <div className="faq_drop-toggle w-dropdown-toggle">
                                    <div>Республика Беларусь и Казахстан (почтой) </div>
                                    <div className="faq_btn small" />
                                </div>
                                <nav className="dropdown-list w-dropdown-list">
                                    <div className="faq_desc">
                                        Сроки доставки до отделения почты России - от 3 до 15 дней. Стоимость доставки - от 490р.{' '}
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <img src="" loading="eager" width="994" alt="" className="delivery_bg plhide" title="Доставка и оплата" />
        </section>
    );
};

export default DeliveryPage;
