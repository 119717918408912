import { Breadcrumbs, Typography } from '@mui/material';
import Header from 'components/header';
import React from 'react';
import { Link as LinkRout } from 'react-router-dom';

const Oferta = () => {
    return (
        <div>
            <Header />
            <div className="wrapper owerflow">
                <section className="section  faq">
                    <div className="container">
                        <Breadcrumbs className="breadcrumbs" aria-label="breadcrumb">
                            <LinkRout underline="hover" color="inherit" to="/">
                                Главная
                            </LinkRout>
                            <Typography color="text.primary">Оферта</Typography>
                        </Breadcrumbs>
                        <h1 className="page_title">Оферта </h1>

                        <div className="faq_box">
                            <div className="content_box w-richtext">
                                <p>
                                    <b>Публичная оферта-договор о продаже товаров дистанционным способом</b>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>Новосибирск</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>Ред. от 15.12.2021</span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Индивидуальный предприниматель Тюменцев Алексей Сергеевич, ИНН 540601979397
                                    </span>
                                    <span style={{ fontWeight: 400 }}>
                                        , далее именуемый «Продавец», публикует настоящую публичную оферту, являющуюся предложением
                                        заключить договор о продаже товаров дистанционным способом (далее- Оферта) через интернет-магазин по
                                        адресу:{' '}
                                    </span>
                                    <a href="https://millidge.ru/">
                                        <span style={{ fontWeight: 400 }}>https://millidge.site</span>
                                    </a>{' '}
                                    <span style={{ fontWeight: 400 }}>
                                        {' '}
                                        в адрес физических лиц (далее – Покупатель) в соответствии с пунктом 2 статьи 437 Гражданского
                                        Кодекса Российской Федерации (ГК РФ).{' '}
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Заказывая Товары, Покупатель соглашается с условиями продажи Товаров, изложенными в настоящей
                                        Оферте. В случае несогласия с условиями Оферты Покупатель обязан немедленно прекратить использование
                                        сервиса и покинуть сайт{' '}
                                    </span>
                                    <a href="https://millidge.ru/">
                                        <span style={{ fontWeight: 400 }}>https://millidge.site</span>
                                    </a>
                                    <span style={{ fontWeight: 400 }}>.</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        В случае принятия изложенных ниже условий (акцепта настоящей оферты) и совершения действий по
                                        выполнению указанных ниже условий, договор между Продавцом и Покупателем считается заключенным на
                                        условиях, указанных в настоящем договоре-оферте.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <ol>
                                    <li>
                                        <span style={{ fontWeight: 400 }}> Общие положения </span>
                                    </li>
                                </ol>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        1.1. Настоящее предложение является публичной офертой (в соответствии со статьей 435 и частью 2
                                        статьи 437 Гражданского кодекса РФ) и содержит все существенные условия договора купли-продажи
                                        дистанционным способом, т. е. через интернет-магазин{' '}
                                    </span>
                                    <a href="https://millidge.ru/">
                                        <span style={{ fontWeight: 400 }}>https://millidge.site/</span>
                                    </a>
                                    <span style={{ fontWeight: 400 }}>. </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>1.2. Акцептом настоящей оферты (договора) является </span>
                                    <span style={{ fontWeight: 400 }}>оформление заказа на Товар и полная его оплата</span>
                                    <span style={{ fontWeight: 400 }}> Покупателем в соответствии с условиями настоящей оферты.</span>
                                </p>
                                <p>&nbsp;</p>
                                <ol start="2">
                                    <li>
                                        <span style={{ fontWeight: 400 }}> Термины и определения. </span>
                                    </li>
                                </ol>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        2.1. «Товар» &#8212; косметическая продукция в ассортименте, представленном в интернет-магазине.
                                        Товар, представленный на Сайте, по качеству и упаковке соответствует ГОСТу и техническим условиям
                                        (ТУ), что подтверждается соответствующими документами (сертификатами и т.д.).
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        2.2. «Каталог» &#8212; информация о товарах, размещенная в интернет-магазине на сайте{' '}
                                    </span>
                                    <a href="https://millidge.ru/">
                                        <span style={{ fontWeight: 400 }}>https://millidge.site/</span>
                                    </a>
                                    <span style={{ fontWeight: 400 }}>. </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        2.3. «Заказ» &#8212; заявка на приобретение товара, содержащая наименование, ассортимент, количество
                                        выбранного Покупателем товара, оформленная в интернет-магазине.{' '}
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        2.4. «Интернет-магазин» &#8212; сайт Продавца, предназначенный для заключения договоров розничной
                                        купли-продажи дистанционным способом, в соответствии со ст. 497 ГК РФ (на основании ознакомления
                                        Покупателя с предложенным Продавцом описанием Товара, содержащимся в Каталоге и представленным на
                                        фотоснимках посредством сети интернет, исключающей возможность непосредственного ознакомления
                                        Покупателя с Товаром).{' '}
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        2.5. «Покупатель» &#8212; любое физическое лицо, заказавшее и оплатившее товар в порядке и на
                                        условиях, установленных настоящим Соглашением, на территории Российской Федерации.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <ol start="3">
                                    <li>
                                        <span style={{ fontWeight: 400 }}> Предмет договора </span>
                                    </li>
                                </ol>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        3.1. Продавец обязуется передать в собственность Покупателю товар, а Покупатель обязуется принять и
                                        оплатить товар на условиях настоящего договора.{' '}
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <ol start="4">
                                    <li>
                                        <span style={{ fontWeight: 400 }}> Заказ товаров </span>
                                    </li>
                                </ol>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        4.1. Заказ покупателя может быть оформлен Покупателем самостоятельно через сайт интернет-магазина.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        4.2. Для оформления заказа Покупатель заполняет поля, обязательные для заполнения.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        4.3. В случае изменения каких-либо данных, ранее предоставленных Покупателем и необходимых для
                                        надлежащего исполнения Продавцом обязательств по продаже и доставке товаров, Покупатель обязуется
                                        незамедлительно уведомить об этом Продавца, направив соответствующее письмо Продавцу{' '}
                                    </span>
                                    <span style={{ fontWeight: 400 }}>
                                        по адресу: millidgecosmetic@gmail.com, или по телефону +7 (995) 156-62-28
                                    </span>
                                    <span style={{ fontWeight: 400 }}>. </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}> </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>5.Оформление заказа </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        5.1. Покупатель вправе оформить заказ на любой товар, представленный в Каталоге интернет-магазина.{' '}
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Покупатель вправе оформить предзаказ на товар, который отсутствует на складе, но доступен к
                                        предзаказу, внеся 100% предоплату если не указано другое. Сроки доставки предзаказанного товара не
                                        более 4 недель с момента оплаты.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>5.2. Не позднее 10</span>
                                    <span style={{ fontWeight: 400 }}> дней</span>
                                    <span style={{ fontWeight: 400 }}>
                                        {' '}
                                        после поступления заказа Продавец подтверждает принятие заказа, а также запрашивает необходимые
                                        детали заказа и согласовывает с Покупателем дату доставки Товара.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        5.3. Покупатель несет ответственность за правильность предоставленных для заказа сведений. Продавец
                                        не несет ответственности за невыполнение заказа в случае наличия ошибок или предоставления неполных
                                        сведений о фамилии, имени Покупателя, адреса доставки и телефона для связи.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>5.4. Внешний вид товара, включая</span>{' '}
                                    <span style={{ fontWeight: 400 }}>
                                        цвет упаковки, может незначительно отличаться от представленных на фотографии в интернет-магазине. В
                                        случае возникновения у Покупателя вопросов, касающихся свойств и характеристик Товара, перед
                                        оформлением Заказа Покупатель должен обратиться к Продавцу.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        5.5. Допускается изменения состава Заказа до его передачи курьерской службе или на почту России по
                                        согласованию с Покупателем по адресу: millidgecosmetic@gmail.com, или по телефону +7 (995)
                                        156-62-28.{' '}
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        5.6. В случае отсутствия заказанных Покупателем Товаров на складе Продавца, последний вправе
                                        исключить указанный Товар из Заказа / аннулировать Заказ Покупателя, уведомив об этом Покупателя
                                        звонком менеджера интернет-магазина Продавца (либо путем направления соответствующего электронного
                                        сообщения по адресу, указанному Покупателем при заказе).
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        В случае частичной или полной аннуляции предоплаченного Заказа стоимость аннулированного Товара
                                        возвращается Продавцом Покупателю способом, которым Товар был оплачен.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>6.Конфиденциальность и защита персональной информации </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        6.1. При оформлении заказа Покупатель предоставляет следующую информацию: фамилия, имя, адрес
                                        электронной почты, телефон, адрес доставки.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        6.2. Предоставляя свои персональные данные при Заказе Товара, Покупатель соглашается на их обработку
                                        Продавцом,
                                    </span>{' '}
                                    <span style={{ fontWeight: 400 }}>
                                        совершаемую с использованием средств автоматизации или без использования таких средств, включая
                                        сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение,
                                        использование, передачу (предоставление, доступ), обезличивание, блокирование, удаление, уничтожение
                                        персональных данных в соответствии со ст.3, ст.9, ст.15 ФЗ «О персональных данных» от 27.07.2006г.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        6.3. Продавец использует предоставленную информацию для выполнения своих обязательств перед
                                        Покупателем.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        6.4. Продавец обязуется не разглашать полученную от Покупателя информацию. Не считается нарушением
                                        предоставление Продавцом информации третьим лицам, действующим на основании договора с Продавцом (в
                                        том числе, службам доставки), для исполнения обязательств перед Покупателем.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        6.5. Не считается нарушением обязательств разглашение информации в соответствии с требованиями
                                        закона.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>7.Доставка, оплата и приемка товара. </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>7.1. Срок доставки заказов </span>
                                    <span style={{ fontWeight: 400 }}>от 2 дней до 4 недель </span>
                                    <span style={{ fontWeight: 400 }}>
                                        в зависимости от выбранного Покупателем способа доставки и региона, срок сообщается Покупателю
                                        менеджером интернет-магазина при согласовании даты доставки. Продавец не несет ответственности за
                                        изменение сроков доставки в случае обстоятельств, которые он не мог ни предвидеть, ни предотвратить.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Доставка осуществляется: курьерской службой или почтой России. Стоимость доставки не входит в
                                        стоимость товаров и оплачивается Покупателем помимо стоимости товаров, если не действуют особые
                                        условия.
                                    </span>
                                    <span style={{ fontWeight: 400 }}>
                                        {' '}
                                        Общая стоимость товаров с доставкой сообщается менеджером интернет-магазина в момент согласования
                                        заказа.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>7.2. Оплата товара:</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        7.2.1. Оплата товара и доставки производится в виде 100% предоплаты и осуществляется Покупателем по
                                        его выбору:
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        &#8212; через электронные платежи с помощью платежных систем. После заказа товаров Покупатель
                                        перенаправляется на сервис платежной системы, на котором может оплатить товар и доставку любым
                                        удобным ему способом из тех вариантов, которые предложены сервисом;
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>&#8212; Картой курьеру</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>&#8212; наличными курьеру при передаче товара Покупателю. </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        7.2.2. После поступления денежных средств на счет Продавца менеджер интернет-магазина{' '}
                                    </span>
                                    <span style={{ fontWeight: 400 }}>
                                        формирует и отправляет заказ не позднее 3 рабочих дней с момента оплаты товаров или по согласованию
                                        с Клиентом
                                    </span>
                                    <span style={{ fontWeight: 400 }}>
                                        . Обязанность Покупателя по уплате цены товара считается исполненной с момента подтверждения оплаты
                                        от платежной системы\банка Покупателя. Оплата производится в российских рублях.
                                    </span>{' '}
                                    <span style={{ fontWeight: 400 }}>
                                        Стоимость услуг не включает комиссию, взимаемую банками или платежными системами за проведение
                                        платежа.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        7.2.3. Продавец вправе в любое время изменить цены на товары, публикуя новую стоимость на сайте.
                                        Новая цена не распространяется на уже размещенные заказы.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        7.3. В случае аннулирования оплаченного Заказа до передачи в службу доставки сумма предоплаты
                                        полностью возвращается Продавцом Покупателю в соответствии с п.8.4. настоящего Договора.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        7.4. Доставленный товар передается Покупателю по месту его жительства или иному указанному им
                                        адресу, а при отсутствии Покупателя &#8212; любому лицу, предъявившему квитанцию или иной документ,
                                        подтверждающий заключение договора или оформление доставки товара.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        7.5. В случае если доставка товара произведена в установленные договором сроки, но товар не был
                                        передан Покупателю по его вине, последующая доставка производится в новые сроки, согласованные с
                                        Продавцом,{' '}
                                    </span>
                                    <span style={{ fontWeight: 400 }}>
                                        после повторной оплаты Покупателем стоимости услуг по доставке товара.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        7.6. В момент доставки Покупатель должен внимательно осмотреть товар в присутствии курьера,
                                        убедиться в отсутствии видимых механических и иных внешних повреждений, проверить срок годности
                                        доставленного Товар, а также убедиться в том, что товар полностью соответствует заказанному через
                                        интернет-магазин (наименование, цвет, комплектность, ассортимент, количество). Разница в оттенках
                                        цвета товара не является недостатком товара. При нарушении целостности упаковки, обнаружении вмятин
                                        и других повреждений, несоответствия товара заказу Покупатель вправе отказаться от получения Товара,
                                        после чего сообщить об отказе менеджеру Интернет-магазина. Риск утраты или повреждения товара
                                        переходит Покупателю в момент передачи товара.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Если Получателем не были заявлены претензии в вышеуказанном порядке, то Продавец считается полностью
                                        и надлежащим образом исполнившим свою обязанность по передаче Товара.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        В случае возврата доставленного посредством курьерской компании Товара в связи с наличием претензий
                                        к Товару Получатель обязан приложить к отправлению, содержащему возвращаемый Товар,{' '}
                                    </span>
                                    <span style={{ fontWeight: 400 }}>следующие документы:</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        &#8212; заявление на возврат денежных средств с указанием причины;
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>&#8212; бланк возврата.</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>7.7. </span>
                                    <i>
                                        <span style={{ fontWeight: 400 }}>При доставке Почтой</span>
                                    </i>
                                    <span style={{ fontWeight: 400 }}>
                                        : При получении посылки Покупатель должен внимательно осмотреть ее упаковку на предмет целостности и
                                        отсутствия внешних повреждений. При нарушении целостности упаковки, обнаружении вмятин и других
                                        повреждений Покупатель вправе не принимать посылку, потребовать от работников почты составления акта
                                        о возврате и отправки заказа по обратному адресу, после чего сообщить о сложившейся ситуации
                                        менеджеру Интернет-магазина.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        При отсутствии каких-либо претензий Покупателю необходимо подписать сопроводительные документы.
                                        Данная подпись служит подтверждением того, что Покупатель не имеет претензий к комплектации заказа,
                                        к количеству и внешнему виду Товара.{' '}
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>7.8. </span>
                                    <i>
                                        <span style={{ fontWeight: 400 }}>Доставка товара Курьерской службой</span>
                                    </i>
                                    <span style={{ fontWeight: 400 }}>:</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        7.8.1 Право собственности и риск случайной гибели, утраты или повреждения товара переходит с
                                        Продавца на Курьерскую службу с момента передачи товара Курьерской службе в месте исполнения
                                        договора при подписании Сторонами акта приёма товара.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        7.8.2 Обязательство по передаче товара Покупателю считается исполненным в момент передачи товара
                                        Покупателю Курьерской службой.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        7.8.3 Стоимость доставки товара в рамках каждого заказа рассчитывается исходя из веса и объема всех
                                        заказанных товаров, адреса доставки заказа, расценок курьерской службы и оплачивается Покупателем
                                        сверх стоимости Заказа.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        7.9. В случае неосуществления доставки предоплаченного заказа более 25 дней с момента даты его
                                        предполагаемой доставки по вине Покупателя договор будет считаться расторгнутым, а уплаченная сумма
                                        подлежит возврату за вычетом расходов Продавца и стоимости доставки в течение 10 дней с момента
                                        расторжения договора.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        7.10. В случае неосуществления доставки предоплаченного заказа более 25 дней с момента даты его
                                        предполагаемой доставки по вине Продавца договор будет считаться расторгнутым, а уплаченная сумма
                                        подлежит возврату полностью в течение 10 дней с момента расторжения договора.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>8.Порядок возврата товара</span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>8.1. Возврат товаров надлежащего качества.</span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        8.2.1. Покупатель вправе отказаться от заказанного Товара в любое время до его получения.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        8.2.2. Парфюмерно-косметические товары не подлежат возврату или обмену в соответствии с
                                        постановлением Правительства РФ от 31.12.2020 № 2463.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        8.2.3. Для возврата денежных средств при отказе от товаров до их получения необходимо отправить
                                        заявление в письменной
                                    </span>{' '}
                                    <span style={{ fontWeight: 400 }}>
                                        форме по адресу: 115432, Москва, ул.Сайкина, д.19 с указанием номера заказа.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        8.2.4. Возврат стоимости товара осуществляется Продавцом в течение 10 дней с даты получения
                                        заявления путем перечисления соответствующей суммы на банковский счет Покупателя, с которого
                                        производилась оплата Товара.{' '}
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>8.3. Возврат товаров ненадлежащего качества.</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        8.3.1. В случае обнаружения недостатков товара, если они не были заранее оговорены Продавцом,
                                        Покупатель вправе по своему выбору потребовать замены такого товара товаром надлежащего качества
                                        либо соразмерного уменьшения покупной цены.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Вместо предъявления указанных требований Покупатель вправе отказаться от приобретенного товара и
                                        потребовать возврата уплаченной за товар денежной суммы.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        При этом Покупатель по требованию Продавца и за его счет должен возвратить полученный товар
                                        ненадлежащего качества.{' '}
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        После получения Товара претензии к внешним дефектам товара, его количеству, комплектности и
                                        товарному виду не принимаются.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        8.3.2. При возврате товара ненадлежащего качества Покупателю возвращается стоимость товара, доставки
                                        и обратной пересылки.{' '}
                                    </span>
                                    <span style={{ fontWeight: 400 }}>
                                        При частичном возврате стоимость доставки заказа Покупателю не возвращается.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        8.4. Возврат стоимости товара ненадлежащего качества осуществляется Продавцом путем перечисления
                                        соответствующей суммы на банковский счет Покупателя, с которого производилась оплата Товара. Расходы
                                        на осуществление возврата суммы, уплаченной Покупателем в соответствии с договором, несет Продавец.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        8.5.Требования о возврате уплаченной за товар денежной суммы подлежат удовлетворению на основании
                                        заявления Покупателя в течение 10 дней со дня предъявления соответствующего требования.{' '}
                                    </span>
                                </p>
                                <ol start="9">
                                    <li>
                                        <span style={{ fontWeight: 400 }}>
                                            <br />
                                        </span>
                                        <span style={{ fontWeight: 400 }}> Обстоятельства непреодолимой силы </span>
                                    </li>
                                </ol>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        9.1. Стороны освобождаются от ответственности за неисполнение или ненадлежащее исполнение своих
                                        обязательств по Договору, если докажут, что надлежащее исполнение оказалось невозможным вследствие
                                        обстоятельств непреодолимой силы, которые стороны не могли предвидеть и избежать &#8212;
                                        форс-мажорных обстоятельств. При этом наличие форс-мажорных обстоятельств, продлевает срок
                                        исполнения Сторонами принятых на себя по Договору обязательств до прекращения соответствующих
                                        форс-мажорных обстоятельств. В случае если указанные обстоятельства действуют более чем 30
                                        (тридцать) дней, Стороны имеют право в одностороннем порядке расторгнуть настоящий Договор.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <ol start="10">
                                    <li>
                                        <span style={{ fontWeight: 400 }}> Ответственность сторон </span>
                                    </li>
                                </ol>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        10.1. Продавец не несет ответственности и не возмещает убытки, возникшие у Покупателя из-за действий
                                        или бездействия третьих лиц.{' '}
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>10.2.</span>{' '}
                                    <span style={{ fontWeight: 400 }}>
                                        Продавец не несет ответственности за ущерб, причиненный Покупателю вследствие ненадлежащего
                                        использования Товаров, приобретенных у Продавца.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        10.3. Продавец не несет ответственности за невозможность выполнения заказа Покупателя по каким-либо
                                        независящим от него причинам, включая нарушение работы линий связи и т.п.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        10.4. Во всех остальных случаях Стороны несут ответственность в соответствии с законодательством РФ.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>11 . Срок действия настоящего договора. </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        11.1. Настоящий договор вступает в силу с даты оформления заказа и действует до полного выполнения
                                        Сторонами всех обязательств по нему.{' '}
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <ol start="12">
                                    <li>
                                        <span style={{ fontWeight: 400 }}> Заключительные условия.</span>
                                    </li>
                                </ol>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        12.1. Все споры, возникающие между сторонами, стороны будут пытаться решить путем переговоров.
                                        Претензии направляются в письменном виде: Продавцу &#8212; по адресу электронной почты, указанному в
                                        настоящем договоре, Покупателю &#8212; по адресу электронной почты, указанному им при заказе товара.
                                        Срок ответа на претензию &#8212; 7 дней.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        12.2. Неурегулированные споры подлежат рассмотрению в судебном порядке согласно действующему
                                        законодательству РФ.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        12.3. Во всем остальном, что не предусмотрено настоящим Договором, Стороны руководствуются
                                        действующим законодательством РФ.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <b>Реквизиты Продавца:</b>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>Индивидуальный предприниматель Тюменцев Алексей Сергеевич,</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Адрес: 630007, Россия, Новосибирская обл, г Новосибирск, пр-кт Красный, д 11, кв 45,
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>ИНН 540601979397,</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>ОГРН 321547600134421,</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Адрес для претензий:{' '}
                                        <a className="foonter_nav_item o3" href="mailto:millidgecosmetic@gmail.com">
                                            millidgecosmetic@gmail.com
                                        </a>
                                    </span>
                                    <span style={{ fontWeight: 400 }}>, </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>Телефон +7 (995) 156-62-28</span>
                                </p>
                                <p>&nbsp;</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Oferta;
