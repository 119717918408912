import { Breadcrumbs, Typography } from '@mui/material';
import Header from 'components/header';
import React from 'react';
import { Link as LinkRout } from 'react-router-dom';

const PersonalDataUse = () => {
    return (
        <div>
            <Header />
            <div className="wrapper owerflow">
                <section className="section  faq">
                    <div className="container">
                        <Breadcrumbs className="breadcrumbs" aria-label="breadcrumb">
                            <LinkRout underline="hover" color="inherit" to="/">
                                Главная
                            </LinkRout>
                            <Typography color="text.primary">Согласие на обработку персональных данных</Typography>
                        </Breadcrumbs>
                        <h1 className="page_title">Согласие на обработку персональных данных </h1>

                        <div className="faq_box">
                            <div className="content_box w-richtext">
                                <p>
                                    <b>
                                        Согласие на обработку персональных данных, разрешенных субъектом персональных данных для
                                        распространения
                                    </b>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        КАКИЕ ПЕРСОНАЛЬНЫЕ ДАННЫЕ БУДУТ ДОСТУПНЫ НЕОГРАНИЧЕННОМУ КРУГУ ЛИЦ?
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>Мы можем опубликовать в тексте ваших отзывов о товарах:</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>Ссылку на Ваш аккаунт в Инстаграме;</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>фотография/изображение;</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>иные данные.</span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Вы вправе самостоятельно указать нам перечень Ваших персональных данных, разрешенных для
                                        распространения. Для этого путем отправки нам письма на электронную почту
                                    </span>{' '}
                                    <a href="mailto:millidgecosmetic@gmail.com">
                                        <i>
                                            <span style={{ fontWeight: 400 }}>millidgecosmetic@gmail.com</span>
                                        </i>
                                    </a>{' '}
                                    <span style={{ fontWeight: 400 }}>
                                        укажите только те данные и список ограничений, которые Вы хотите установить. Вы вправе установить
                                        запреты и/или ограничения в отношении всех или части персональных данных.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>В ТЕЧЕНИЕ КАКОГО ВРЕМЕНИ ДЕЙСТВУЕТ СОГЛАСИЕ?</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Согласие действует до момента достижения целей обработки персональных данных: в течение срока
                                        действия договора с Вами, и по окончании срока действия &#8212; 5 лет, либо до отзыва согласия до
                                        истечения этого срока.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Вы в любой момент до истечения указанного срока можете отозвать свое согласие, написав заявление с
                                        указанием Ваших данных на нашу электронную почту:{' '}
                                    </span>
                                    <a href="mailto:millidgecosmetic@gmail.com">
                                        <i>
                                            <span style={{ fontWeight: 400 }}>millidgecosmetic@gmail.com</span>
                                        </i>
                                    </a>
                                    <span style={{ fontWeight: 400 }}>. </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        С КАКОЙ ЦЕЛЬЮ ПРОИЗВОДИТСЯ ОБРАБОТКА ДАННЫХ, РАЗРЕШЕННЫХ ДЛЯ РАСПРОСТРАНЕНИЯ?
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        В целях размещения Ваших отзывов о наших продуктах мы хотим выкладывать в наших официальных
                                        сообществах в социальных сетях, на вышеуказанных сайтах и в прочих рекламных материалах фото- и
                                        видео-материалы с Вашим участием.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Для этого мы просим Вас дать свое согласие на обработку Ваших персональных данных, разрешенных Вами
                                        для распространения:
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>«</span>
                                    <b>
                                        Согласие на обработку персональных данных, разрешенных субъектом персональных данных для
                                        распространения:
                                    </b>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Настоящим свободно, своей волей и в своем интересе, руководствуясь статьей 10.1 Федерального закона
                                        от 27.07.2006 № 152-ФЗ «О персональных данных»,{' '}
                                    </span>
                                    <b>даю согласие</b>
                                    <span style={{ fontWeight: 400 }}> Индивидуальному предпринимателю </span>
                                    <i>
                                        <span style={{ fontWeight: 400 }}>
                                            Тюменцеву Алексею Сергеевичу, ИНН 540601979397, ОГРН 321547600134421, Адрес: 630007, Россия,
                                            Новосибирская обл, г Новосибирск, пр-кт Красный, д 11, кв 45 (далее – Оператор),
                                        </span>
                                    </i>
                                </p>
                                <p>
                                    <b>на распространение</b>
                                    <span style={{ fontWeight: 400 }}>
                                        {' '}
                                        Оператором моих персональных данных с целью размещения информации обо мне на информационных ресурсах
                                        Оператора, публикации моих отзывов в соответствии со следующим перечнем:
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>&#8212; </span>
                                    <span style={{ fontWeight: 400 }}>ссылка на аккаунты в социальных сетях;</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>&#8212; фотография/изображение.</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Настоящее согласие вступает в силу с даты отправки моих данных, указанных в веб-форме на Сайте
                                        Оператора, и действует в течение получения мною услуг Оператора, и 5 лет по окончании срока действия
                                        договора с Оператором, а также иных сроков, установленных действующим законодательством РФ и{' '}
                                    </span>
                                    <i>
                                        <span style={{ fontWeight: 400 }}>Политикой обработки персональных данных</span>
                                    </i>
                                    <span style={{ fontWeight: 400 }}> Оператора. </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Я уведомлен о том, что информационными ресурсами Оператора, посредством которых будет осуществляться
                                        предоставление доступа неограниченному кругу лиц и иные действия с моими персональными данными,
                                        будут следующие ресурсы:{' '}
                                    </span>
                                    <a href="https://millidge.site/">
                                        <span style={{ fontWeight: 400 }}>https://millidge.site/</span>
                                    </a>
                                    <span style={{ fontWeight: 400 }}>.</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default PersonalDataUse;
