import config from 'config';
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="footer_nav_items">
                    <Link className="foonter_nav_item o1" to="/delivery">
                        Доставка и оплата
                    </Link>
                    <Link className="foonter_nav_item o2" to="/about">
                        О компании
                    </Link>
                    <Link className="footer_logo w-inline-block" to="/">
                        <img
                            src="data:image/svg+xml,%3Csvg%20xmlns='https://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                            loading="lazy"
                            alt="Millidge logo"
                            className="logo_ico"
                            title=""
                            data-lazy-src={`${config.storeUrl}/Millidge.svg`}
                        />
                    </Link>
                    <a href="mailto:millidgecosmetic@gmail.com" className="foonter_nav_item o3">
                        <span className="__cf_email__">millidgecosmetic@gmail.com</span>
                    </a>
                </div>
                <div className="footer_box">
                    <Link className="footer_item" to="/privacy-policy">
                        Политика конфиденциальности
                    </Link>
                    <div className="footer_item">Все права защищены</div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
