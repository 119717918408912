import React from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';

const PaymentsSuccess = () => {
    const { orderId } = useParams();
    return (
        <div className="container_payment_common">
            <div className="container_payment">
                <div className="success-box">
                    <div className="dot_payment" />
                    <div className="dot_payment two" />
                    <div className="face_payment">
                        <div className="eye" />
                        <div className="eye right_payment" />
                        <div className="mouth happy" />
                    </div>
                    <div className="shadow_payment scale_payment" />
                    <div className="message_payment">
                        <h1 className="alert_payment">Успешно!</h1>
                        <p className="p_payment">Вы совершили покупку!</p>
                    </div>
                    <button type="button" className="button_payment button-box">
                        <Link className="alert_payment green" to={`/prizes/${orderId}`}>
                            Вернуться
                        </Link>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PaymentsSuccess;
