import { Breadcrumbs, Typography } from '@mui/material';
import Header from 'components/header';
import { Product } from 'components/product';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link as LinkRout } from 'react-router-dom';

const Anticellulite = () => {
    const products = useSelector((state) => state.state.products);
    const filteredProducts = products.filter((item) => item.categories.split(', ').includes('Антицеллюлитные средства'));
    return (
        <div>
            <Header />
            <div className="wrapper owerflow">
                <section className="section">
                    <div className="container">
                        <Breadcrumbs className="breadcrumbs" aria-label="breadcrumb">
                            <LinkRout underline="hover" color="inherit" to="/">
                                Главная
                            </LinkRout>
                            <Typography color="text.primary"> Антицеллюлитные средства</Typography>
                        </Breadcrumbs>
                        <h1 className="page_title"> Антицеллюлитные средства </h1>
                        <div className="products">
                            {filteredProducts.map((product) => (
                                <Product key={product.id} {...product} />
                            ))}
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Anticellulite;
