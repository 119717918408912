import config from 'config';
import React from 'react';
import PropTypes from 'prop-types';

const ProductApplication = ({ blok_primenenie_kontent }) => {
    return (
        <div>
            <section className="section usage">
                <div className="container usage">
                    <div className="usage_content">
                        <h2 className="nature_title">Применение </h2>
                        <div className="usage_desc">
                            <div>
                                <p>{blok_primenenie_kontent}</p>
                            </div>
                        </div>
                    </div>
                    <div className="rellbox for_usage">
                        <div className="usage_label">
                            <img
                                src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                loading="lazy"
                                alt=""
                                className="app_label_ico"
                                data-lazy-src={`${config.storeUrl}/61a51f64c14dd2c4a5deb5e9_Group204d4.png`}
                            />
                            <noscript>
                                <img
                                    src={`${config.storeUrl}/61a51f64c14dd2c4a5deb5e9_Group204d4.png`}
                                    loading="lazy"
                                    alt=""
                                    className="app_label_ico"
                                />
                            </noscript>
                            <div className="usage_percent_desc">Посмотрите видео как применять</div>
                        </div>
                        <img src={`${config.storeUrl}/61a51fff92ae1cdcc26e9ed9_2Hand.png`} loading="eager" alt="" className="usage-hand1" />
                        <img src={`${config.storeUrl}/61a51fffbc97911f5207edef_1Hand.png`} loading="eager" alt="" className="usage-hand2" />
                        <div className="usage_video-box">
                            <div className="video_bg">
                                <img
                                    src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                    loading="lazy"
                                    alt=""
                                    className="lb_bg_img"
                                    title=""
                                    data-lazy-src={`${config.storeUrl}/61b0ed61fbb93774612b19fb_video.png`}
                                />
                                <noscript>
                                    <img
                                        src={`${config.storeUrl}/61b0ed61fbb93774612b19fb_video.png`}
                                        loading="lazy"
                                        alt=""
                                        className="lb_bg_img"
                                        title=""
                                    />
                                </noscript>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

ProductApplication.propTypes = {
    blok_primenenie_kontent: PropTypes.string
};

export default ProductApplication;
