import config from 'config';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ADD_TO_CART, CURRENT_SET_MENU, DEL_CART_PRODUCT, MINUS_CART_PRODUCT } from 'store/current-actions';
import { useCookies } from 'react-cookie';
import { PlusMinusCountItem } from 'components/plus-minus-count-item';

/* 
<div
    role="button"
    tabIndex={0}
    onClick={() => handleOpenCart()}
    onKeyDown={() => handleOpenCart()}
    className="nav_row for_cart"
>
*/

const Header = () => {
    const [state, setstate] = useState(false);
    const dispatch = useDispatch();
    const isOpen = useSelector((state) => state.state.isOpen);
    const cart = useSelector((state) => state.state.cart);

    const [cartItemsCount, setCartItemsCount] = useState(0);
    const [cartItemsCost, setCartItemsCost] = useState(0);

    const [, setCookie] = useCookies(['productsCart']);

    useEffect(() => {
        setCookie('productsCart', cart);
    }, [cart]);

    useEffect(() => {
        if (cart) {
            let count = 0;
            cart.forEach((element) => {
                count += element.count;
            });
            setCartItemsCount(count);
        }
    }, [cart]);

    useEffect(() => {
        if (cart) {
            let cost = 0;
            cart.forEach((element) => {
                cost += element.count * element.cost;
            });
            setCartItemsCost(cost);
        }
    }, [cart]);

    const handleOpenMenu = () => {
        dispatch({ type: CURRENT_SET_MENU, payload: !isOpen });
    };

    const handleAddToCart = (cartItem) => {
        dispatch({ type: ADD_TO_CART, payload: cartItem });
    };

    const handleMinusProduct = (id) => {
        dispatch({ type: MINUS_CART_PRODUCT, payload: id });
    };

    const handleDelCartProduct = (id) => {
        dispatch({ type: DEL_CART_PRODUCT, payload: id });
    };

    const handleOpenCart = () => {
        setstate(!state);
    };

    return (
        <header className="header">
            <div className="container on_header">
                <div
                    role="button"
                    tabIndex={0}
                    onClick={() => handleOpenMenu()}
                    onKeyDown={() => handleOpenMenu()}
                    className="nav_row for_nav"
                >
                    <div data-w-id="d46d4331-a235-1e13-d4a5-f50e65cb0c05" className="nav-circle for_main">
                        <img
                            src="data:image/svg+xml,%3Csvg%20xmlns='https://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                            loading="lazy"
                            alt=""
                            className="nav_ico"
                            data-lazy-src={`${config.storeUrl}/619ff18df5d581fe733fe91a_nav.png`}
                        />
                    </div>
                    <div className="nav_title">МЕНЮ</div>
                </div>
                <Link className="nav_row for_logo" to="/">
                    <img
                        src="data:image/svg+xml,%3Csvg%20xmlns='https://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                        loading="lazy"
                        alt="Millidge logo"
                        className="logo_ico"
                        title=""
                        data-lazy-src={`${config.storeUrl}/Millidge.svg`}
                    />
                </Link>
                <div className="nav_row for_cart">
                    <div className="fixed_cart big">
                        <div
                            role="button"
                            tabIndex={0}
                            onClick={() => handleOpenCart()}
                            onKeyDown={() => handleOpenCart()}
                            className="nav-circle mhide"
                        >
                            <img
                                src="data:image/svg+xml,%3Csvg%20xmlns='https://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                loading="lazy"
                                alt=""
                                className="cart_ico"
                                data-lazy-src={`${config.storeUrl}/619ff18da59fba9326e450e1_shopping-bag201.svg`}
                            />
                            <div className="cart_count-box">
                                <div className="cart_count" data-wc="cart_count">
                                    {cartItemsCount}
                                </div>
                            </div>
                        </div>
                        <div
                            style={{ display: state ? 'block' : 'none', opacity: state ? 1 : 0 }}
                            className="mini_cart-box"
                            data-wc="mini_cart"
                        >
                            {cartItemsCount > 0 ? (
                                <div data-content="cart_content">
                                    <div className="mini_cart_items">
                                        {cart.length > 0 &&
                                            cart.map((product) => (
                                                <div key={product.id} className="mini_cart_item" data-content="cart_item">
                                                    <Link
                                                        className="cart_image_box mini-cart w-inline-block"
                                                        to={`/products/product/${product.id}`}
                                                    >
                                                        <img
                                                            src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                                            data-lazy-src={product.imageUrl ?? null}
                                                            loading="lazy"
                                                            wp_size="product-thumbnail"
                                                            alt=""
                                                            className="cart_image"
                                                        />
                                                    </Link>
                                                    <div className="cart_col mini-cart">
                                                        <div className="cart_product_info mini-cart">
                                                            <h4>{product.name}</h4>
                                                            <div className="cart_item_price mini-cart" data-content="product_price">
                                                                <span className="woocommerce-Price-amount amount">
                                                                    <bdi>
                                                                        {product.cost},00&nbsp;
                                                                        <span className="woocommerce-Price-currencySymbol">
                                                                            <span className="rur">&#x440;</span>
                                                                        </span>
                                                                    </bdi>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="cart_cell_right">
                                                        <div className="cart_item_form w-form">
                                                            <PlusMinusCountItem
                                                                handleMinusProduct={() => handleMinusProduct(product.id)}
                                                                handleAddToCart={() => handleAddToCart(product)}
                                                                count={product.count}
                                                            />
                                                        </div>
                                                        <div
                                                            role="button"
                                                            tabIndex={0}
                                                            onClick={() => handleDelCartProduct(product.id)}
                                                            onKeyDown={() => handleDelCartProduct(product.id)}
                                                            className="cart_dell mini-cart w-inline-block"
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                    <div className="mini_cart-total">
                                        <div className="cart_total">Итого:</div>
                                        <div className="item_index total" data-wc="cart_total">
                                            <span className="woocommerce-Price-amount amount">
                                                <bdi>
                                                    {cartItemsCost},00&nbsp;
                                                    <span className="woocommerce-Price-currencySymbol">
                                                        <span className="rur">
                                                            &#x440;<span>&#x443;&#x431;.</span>
                                                        </span>
                                                    </span>
                                                </bdi>
                                            </span>
                                        </div>
                                    </div>
                                    <Link className="button mini-cart w-button" to="/cart/">
                                        оформить заказ
                                    </Link>
                                </div>
                            ) : (
                                <div className="cart_empty for_mini" data-content="cart_empty">
                                    Ваша корзина пуста
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="fixed_cart small">
                        <Link className="nav-circle mhide" to="/cart">
                            <img
                                src="data:image/svg+xml,%3Csvg%20xmlns='https://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                loading="lazy"
                                alt=""
                                className="cart_ico"
                                data-lazy-src={`${config.storeUrl}/619ff18da59fba9326e450e1_shopping-bag201.svg`}
                            />
                            <div className="cart_count-box">
                                <div className="cart_count" data-wc="cart_count">
                                    {cartItemsCount}
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
