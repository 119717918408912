import { Checkbox, FormControlLabel, Button } from '@mui/material';
import Footer from 'components/footer';
import Menu from 'components/menu';
import PositionedSnackbar from 'components/snackbar/snackbar';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { prizes, winnerPrizes } from './prizes-data';
import { ordersAPI } from 'api';
import { useAsync, useAsyncFn } from 'react-use';
import LoadingOverlay from 'react-loading-overlay';
import { Link } from 'react-router-dom';

const Prizes = () => {
    const { orderId } = useParams();
    const prizesData = winnerPrizes();
    const [winnerNumber, setCurrentNumber] = useState(+orderId);
    const [scratchCardsCount, setScratchCardsCount] = useState(12);

    const [checked, setChecked] = useState(false);
    const [checkedBorder, setCheckedBorder] = useState(false);

    const [currentPriz, setCurrentPriz] = useState(null);
    const [allPrizes, setAllPrizes] = useState([]);

    const [popUpPriz, setPopUpPriz] = useState(null);

    const [error, setError] = useState(null);
    const [successGive, setSuccessGive] = useState(false);

    const getOrderState = useAsync(async () => {
        const response = await ordersAPI.getByRetailCrmId(orderId);
        if (response.ok) {
            const orderJson = await response.json();
            if (orderJson.isGetPrize) {
                setError('PRIZE_ALREADY_GIVE');
            } else {
                const countItems = orderJson.countItems;
                setScratchCardsCount(countItems);
            }
        } else {
            setError('NOT_FOUND');
        }
    }, []);

    const [state, updateOrderItems] = useAsyncFn(
        // eslint-disable-next-line consistent-return
        async () => {
            try {
                const sentedPrize = allPrizes.map((prize) => ({
                    productName: prize.title,
                    quantity: 1,
                    initialPrice: 0
                }));
                const response = await ordersAPI.updateOrderItems({
                    orderId,
                    items: sentedPrize
                });
                setSuccessGive(true);
                // eslint-disable-next-line consistent-return
                return response;
            } catch (err) {
                Object.entries(err).forEach(([key, value]) => {
                    console.log(key, value);
                });
            }
        },
        [allPrizes]
    );

    const handleOpenPopUpCart = (priz) => {
        setPopUpPriz(priz);
    };

    const handleOpenCart = (idx) => {
        if (checked) {
            setAllPrizes([...allPrizes, prizesData[winnerNumber]]);
            setCurrentPriz({ idx, priz: prizesData[winnerNumber] });
            setCurrentNumber(winnerNumber + 1);
            setScratchCardsCount(scratchCardsCount - 1);
            setCheckedBorder(false);
        } else {
            setCheckedBorder(true);
        }
    };

    function decodeErrorMsg(error) {
        switch (error) {
            case 'NOT_FOUND':
                return 'Заказ не найден';
            case 'PRIZE_ALREADY_GIVE':
                return 'Призы заказа уже добавлены!';
            default:
                return '';
        }
    }

    if (successGive) {
        return (
            <div className="rellbox overflow body on_thank">
                <Menu />
                <div className="empty_header" />
                <img
                    src="https://1790009369.rsc.cdn77.org/images/6221f25359f198e04c5c4d05_D0A1D0BBD0BED0B920120(1).png"
                    loading="lazy"
                    width="1920"
                    alt=""
                    className="thank_bg_image"
                />
                <img
                    src="https://1790009369.rsc.cdn77.org/images/6221f145905f0017c860bfc5_Freesia20Flower.G05201.png"
                    loading="lazy"
                    alt=""
                    className="thank_flover"
                />
                <div className="wrapper owerflow for_thank">
                    <section className="section about">
                        <div className="container">
                            <div className="thank_content">
                                <div className="thank_subtitle">Скретч-карта</div>
                                <div className="thank_title">MILLIDGE</div>
                                <div className="thank_desc">
                                    <div>
                                        <strong>Ваши подарки успешно добавлены в заказ!</strong>
                                    </div>

                                    <div>
                                        <Link to="/products/catalog">
                                            <a>Вернуться к покупкам</a>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <Footer />
                    <PositionedSnackbar />
                </div>
            </div>
        );
    }

    if (error != null) {
        return (
            <div className="rellbox overflow body on_thank">
                <Menu />
                <div className="empty_header" />
                <img
                    src="https://1790009369.rsc.cdn77.org/images/6221f25359f198e04c5c4d05_D0A1D0BBD0BED0B920120(1).png"
                    loading="lazy"
                    width="1920"
                    alt=""
                    className="thank_bg_image"
                />
                <img
                    src="https://1790009369.rsc.cdn77.org/images/6221f145905f0017c860bfc5_Freesia20Flower.G05201.png"
                    loading="lazy"
                    alt=""
                    className="thank_flover"
                />
                <div className="wrapper owerflow for_thank">
                    <section className="section about">
                        <div className="container">
                            <div className="thank_content">
                                <div className="thank_subtitle">Скретч-карта</div>
                                <div className="thank_title">MILLIDGE</div>
                                <div className="thank_desc">
                                    <div>
                                        <strong>{decodeErrorMsg(error)}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <Footer />
                    <PositionedSnackbar />
                </div>
            </div>
        );
    }

    return (
        <LoadingOverlay active={state.loading || getOrderState.loading} spinner>
            <div className="rellbox overflow body on_thank">
                <Menu />
                <div className="empty_header" />
                <img
                    src="https://1790009369.rsc.cdn77.org/images/6221f25359f198e04c5c4d05_D0A1D0BBD0BED0B920120(1).png"
                    loading="lazy"
                    width="1920"
                    alt=""
                    className="thank_bg_image"
                />
                <img
                    src="https://1790009369.rsc.cdn77.org/images/6221f145905f0017c860bfc5_Freesia20Flower.G05201.png"
                    loading="lazy"
                    alt=""
                    className="thank_flover"
                />
                <div className="wrapper owerflow for_thank">
                    <section className="section about">
                        <div className="container">
                            <div className="thank_content">
                                <div className="thank_subtitle">Скретч-карта</div>
                                <div className="thank_title">MILLIDGE</div>
                                <div className="thank_desc">
                                    <div>
                                        <strong>Спасибо за покупку!</strong>
                                        <br />
                                        Вам доступны:{' '}
                                        <span data-content="prize_count_text" className="card_info">
                                            {scratchCardsCount} скретч-карт
                                        </span>
                                    </div>
                                    <div>Выбери скретч-карту и получи приз!</div>
                                </div>
                            </div>
                            <div className="w-form">
                                <div className={checkedBorder ? 'w-checkbox prize-soglasie politics_border' : 'w-checkbox prize-soglasie'}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checked}
                                                onChange={(event) => setChecked(event.target.checked)}
                                                name="checked"
                                                color="primary"
                                            />
                                        }
                                        label="Я ознакомлен и согласен с условиями продажи Товаров и даю согласие на обработку моих персональных
                                            данных в соответствии с политикой обработки персональных данных"
                                    />
                                </div>
                            </div>
                            {allPrizes.length > 0 && (
                                <div data-content="prize_box" className="prize-box w-clearfix">
                                    <div className="prize_info">
                                        Ваши призы:
                                        <br />
                                        <span className="text-span-3">(нажми на приз для просмотра)</span>
                                    </div>
                                    {allPrizes.map((priz, idx) => (
                                        <div
                                            key={idx}
                                            role="button"
                                            tabIndex={0}
                                            onClick={() => handleOpenPopUpCart(priz)}
                                            onKeyDown={() => handleOpenPopUpCart(priz)}
                                            className="selected-prizes"
                                        >
                                            <div className="button prize_button w-button">{priz.title}</div>
                                        </div>
                                    ))}
                                    <div>
                                        <Button onClick={() => updateOrderItems()} size="large" variant="contained" className="add-to-cart">
                                            Получить призы
                                        </Button>
                                    </div>
                                </div>
                            )}
                            {scratchCardsCount > 0 && (
                                <div className="thank_items">
                                    {prizes.map((priz, idx) => (
                                        <div
                                            key={priz.id}
                                            role="button"
                                            tabIndex={0}
                                            onClick={() => (scratchCardsCount > 0 ? handleOpenCart(idx) : undefined)}
                                            onKeyDown={() => (scratchCardsCount > 0 ? handleOpenCart(idx) : undefined)}
                                            className="thank_item"
                                        >
                                            {(currentPriz?.idx ?? 99) !== idx ? (
                                                <div
                                                    className="front"
                                                    style={{
                                                        backgroundImage: `url(${priz.cardImage})`
                                                    }}
                                                >
                                                    <img
                                                        src="https://1790009369.rsc.cdn77.org/images/6221f4f59dd898753a44e5df_Group2012620(1).png"
                                                        loading="lazy"
                                                        alt=""
                                                        className="choise_text"
                                                    />
                                                </div>
                                            ) : (
                                                <div
                                                    key={priz.id}
                                                    data-bg={currentPriz.priz.imageUrl}
                                                    className="back"
                                                    style={{
                                                        backgroundImage: `url(${currentPriz.priz.imageUrl})`
                                                    }}
                                                />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </section>

                    {popUpPriz && (
                        <div className="win_popup">
                            <div className="win_popup_close" />
                            <div className="win_popup_window">
                                <div
                                    role="button"
                                    tabIndex={-1}
                                    onClick={() => handleOpenPopUpCart(null)}
                                    onKeyDown={() => handleOpenPopUpCart(null)}
                                    className="close_ico for_win"
                                >
                                    <img
                                        src="https://1790009369.rsc.cdn77.org/images/61c99fc18a62bb04e0602923_Close.svg"
                                        loading="lazy"
                                        alt=""
                                        className="for_win"
                                    />
                                </div>
                                <div className="win_popup_title">ВАШ ПРИЗ</div>
                                <div className="win_subtitle_box">
                                    <div data-content="prize_text" className="prize-desc">
                                        {popUpPriz.title}
                                        <br />
                                        {popUpPriz.desc}
                                        <br />
                                        {popUpPriz.link && 'Пройдите по ссылке'}
                                        <br />
                                        {popUpPriz.link && <a href={popUpPriz.link}>{popUpPriz.link}</a>}
                                    </div>
                                </div>
                                <img
                                    src={popUpPriz.imageUrl}
                                    loading="lazy"
                                    data-content="prize_image"
                                    alt=""
                                    className="win_product_image"
                                />
                                <img
                                    src="https://1790009369.rsc.cdn77.org/images/61c99f5b612531d4e56f76dd_Rectangled.png"
                                    loading="lazy"
                                    alt=""
                                    className="win_bg_img"
                                />
                                <img
                                    src="https://1790009369.rsc.cdn77.org/images/61c99f5cc7e9a9580da3cde4_D0BAD0BED0BDD184D0B5D182D182D0B82.png"
                                    loading="lazy"
                                    alt=""
                                    className="win_popup_conf"
                                />
                            </div>
                        </div>
                    )}

                    <Footer />
                    <PositionedSnackbar />
                </div>
            </div>
        </LoadingOverlay>
    );
};

export default Prizes;
