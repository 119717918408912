import config from 'config';
import React from 'react';

const Nature = () => {
    return (
        <div>
            <section data-w-id="cfee9515-daa9-5d5a-bef9-18a31bbab915" className="section nature">
                <img
                    src={`${config.storeUrl}/Cream-Tube-1.png`}
                    loading="eager"
                    width="454"
                    alt=""
                    className="nature_cream"
                    title="Cream Tube 1"
                />
                <div className="container for_nature">
                    <div className="nature_desc_side">
                        <div className="nature_title">ИСПОЛЬЗУЕМ </div>
                        <div className="rellbox">
                            <div className="nature_title">
                                <div className="head_circle" />
                                {'  '}ТОЛЬКО ЛУЧШИЕ НАТУРАЛЬНЫЕ КОМПОНЕНТЫ
                            </div>
                        </div>
                        <p className="nature_subhead">
                            Мы стараемся сделать состав максимально натуральным и применяем большое количество натуральных масел и активных
                            экстрактов.
                        </p>
                        <p className="nature_desc">Состав каждого продукта вы можете прочитать на страницах, посвященным средству. </p>
                    </div>
                    <a href="#" className="nature-sertificate_side w-inline-block w-lightbox">
                        <div className="nature_sertificate_circle">
                            <div className="natue-sertificate_title" />
                            <div className="nature-sertificate_desc" />
                            <img
                                src="https://1790009369.rsc.cdn77.org/images/61a09880ad4af805ff2b95d6_Group2089.png"
                                loading="eager"
                                alt=""
                                className="sertificate_img"
                            />
                        </div>
                    </a>
                </div>
                <img
                    src="https://1790009369.rsc.cdn77.org/images/619ff18dcb8792855ed8123f_Group2036.png"
                    loading="eager"
                    alt=""
                    className="nature_bg_img"
                />
                <img
                    src="https://1790009369.rsc.cdn77.org/images/619ff18df072830356f8316f_image2010.png"
                    loading="eager"
                    alt=""
                    className="nature-list1 mhide"
                />
                <img
                    src="https://1790009369.rsc.cdn77.org/images/61a625e74d25231e5f7132a5_image2010D181.png"
                    loading="eager"
                    alt=""
                    className="nature-list1 dhide"
                />
                <img
                    src="https://1790009369.rsc.cdn77.org/images/619ff18dccaf0964f77bd1c6_image209.png"
                    loading="eager"
                    alt=""
                    className="nature-list2"
                />
                <img
                    src="https://1790009369.rsc.cdn77.org/images/619ff18dcc847f185ef8f88a_image208.png"
                    loading="eager"
                    alt=""
                    className="nature-list3"
                />
                <img
                    src="https://1790009369.rsc.cdn77.org/images/619ff18d84ed5fd7c3f187c0_cofe2.png"
                    loading="eager"
                    alt=""
                    className="nature_cofee"
                />
                <img
                    src="https://1790009369.rsc.cdn77.org/images/619ff18e365d322fb194b746_Rectangled.png"
                    loading="eager"
                    alt=""
                    className="nature-grape_bg"
                />
                <img
                    src="https://1790009369.rsc.cdn77.org/images/619ff18ecb879224d2d81240_Rectangldse.png"
                    loading="eager"
                    alt=""
                    className="nature-bush_bg"
                />
            </section>
        </div>
    );
};

export default Nature;
