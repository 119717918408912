export const deliveryTypesParse = (country) => {
    switch (country) {
        // case 'Belarus':
        //     return [
        //         {
        //             title: 'Курьерской службой',
        //             type: 'COURIER',
        //             deliveryCost: 590
        //         },
        //         {
        //             title: 'Почта России',
        //             type: 'PR',
        //             deliveryCost: 590
        //         }
        //     ];
        case 'Russia':
            return [
                {
                    title: 'Курьерской службой',
                    type: 'COURIER',
                    deliveryCost: 490
                },
                {
                    title: 'Доставка до ПВЗ',
                    type: 'SDEK',
                    deliveryCost: 200
                }
            ];
        // case 'Kazahstan':
        //     return [];
        default:
            return [];
    }
};
