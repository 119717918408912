import { Breadcrumbs, Typography } from '@mui/material';
import Header from 'components/header';
import React from 'react';
import { Link as LinkRout } from 'react-router-dom';

const PrivacyPolicy = () => {
    return (
        <div>
            <Header />
            <div className="wrapper owerflow">
                <section className="section  faq">
                    <div className="container">
                        <Breadcrumbs className="breadcrumbs" aria-label="breadcrumb">
                            <LinkRout underline="hover" color="inherit" to="/">
                                Главная
                            </LinkRout>
                            <Typography color="text.primary">Политика конфиденциальности</Typography>
                        </Breadcrumbs>
                        <h1 className="page_title">Политика конфиденциальности </h1>
                        <div className="faq_box">
                            <div className="content_box w-richtext">
                                <p>
                                    <strong>Кто мы: </strong>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Интернет-магазин косметических средств Millidge (зарегистрированный товарный знак), продажи
                                        осуществляются от имени ИП Тюменцева А.С.
                                    </span>
                                </p>
                                <h2> </h2>
                                <p>
                                    <strong>Типы данных, которые мы собираем:</strong>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Идентифицирующая вас информация (ФИО, ссылки на аккаунты в соцсетях, IP адрес, тип браузера,
                                        информация о геолокации и тп){' '}
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>Контактная информация (эл.почта, телефон, адрес доставки)</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>Финансовая информация (данные об оплате)</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>Техническая информация о том, как вы используете наш сайт.</span>
                                </p>
                                <h2> </h2>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Мы не собираем чувствительную информацию: специальные или биометрические данные.
                                    </span>
                                </p>
                                <h2> </h2>
                                <p>
                                    <strong>Как мы используем вашу информацию:</strong>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Для того, чтобы исполнять договоры с вами и доставлять вам товары
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>Для того, чтобы улучшать сервисы сайта</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>Для предоставления персонализированной поддержки</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Для направления рекламной информации (если вы дали на это согласие)
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>Для публикации отзывов о наших товарах с вашими данными</span>
                                </p>
                                <h2> </h2>
                                <p>
                                    <strong>Другие лица, которые могут обрабатывать ваши данные:</strong>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>Сервисы доставки, почта России</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>Банки и платежные агрегаторы</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>Сервисы Яндекс.Метрика и Google аналитика </span>
                                </p>
                                <h2> </h2>
                                <p>
                                    <strong>Мы используем кукис:</strong>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Мы используем только необходимые кукис для корректной работы сайта
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Вы можете отключить все кукис, но это может затормозить работу сайта, а также мы не узнаем вас и не
                                        сможем сохранить вашу корзину на будущее или мы не сможем решать возникающие проблемы более
                                        эффективно.
                                    </span>
                                </p>
                                <h2> </h2>
                                <p>
                                    <strong>Как мы собираем данные:</strong>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Мы собираем данные от людей, просматривающих сайт, и клиентов, заказывающих товары.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Часть информации собирается автоматически (техническая информация, собираемая в пассивном режиме),
                                        часть мы собираем, когда вы оставляете ваши данные в форме заказа.
                                    </span>
                                </p>
                                <h2> </h2>
                                <p>
                                    <strong>Вы имеете право:</strong>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Знать информацию, которую мы о вас собрали, и просить актуализировать ее
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Запретить распространение ваших данных неограниченному кругу лиц
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>Отказаться от рекламных рассылок</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>Попросить нас удалить все ваши данные</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>Пожаловаться нам </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Вы можете реализовать свои права путем отправки нам сообщения на электронную почту{' '}
                                        <a
                                            className="foonter_nav_item o3"
                                            href="/cdn-cgi/l/email-protection#b8d5d1d4d4d1dcdfdddbd7cbd5ddccd1dbf8dfd5d9d1d496dbd7d5"
                                        >
                                            <span
                                                className="__cf_email__"
                                                data-cfemail="2e43474242474a494b4d415d434b5a474d6e49434f4742004d4143"
                                            >
                                                [email&#160;protected]
                                            </span>
                                        </a>
                                    </span>
                                    <i>
                                        <span style={{ fontWeight: 400 }}>.</span>
                                    </i>
                                </p>
                                <h2> </h2>
                                <p>
                                    <strong>Где мы храним ваши данные:</strong>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Данные хранятся в нашем офисе в Новосибирске с использованием базы данных, находящейся в России.
                                    </span>
                                </p>
                                <h2> </h2>
                                <p>
                                    <strong>Как долго мы храним ваши данные?</strong>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Мы храним ваши данные в течение срока действия нашего договора и не более, чем 5 лет после окончания
                                        нашего взаимодействия. По истечении этого срока мы удалим ваши данные, за исключением случаев,
                                        указанных в законе. В таком случае данные будут архивированы.
                                    </span>
                                </p>
                                <h2> </h2>
                                <p>
                                    <strong>Третьи лица, которые могут использовать ваши данные:</strong>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Мы заключаем договоры с третьими лицами для проведения платежей, доставки товаров, хостинга нашего
                                        сайта, управления рассылками, веб-аналитики и тп.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Ваши данные передаются только когда это необходимо и с обеспечением конфиденциальности ваших данных
                                        третьими лицами.{' '}
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Ваши данные также могут быть переданы государственным органам в соответствии с обязательными
                                        требованиями закона.
                                    </span>
                                </p>
                                <h4>
                                    <br />
                                    <a href="/privacy-policy-full">
                                        <span style={{ fontWeight: 400 }}>
                                            Если вы хотите ознакомиться со полными условиями обработки ваших данных,{' '}
                                        </span>
                                        <span style={{ fontWeight: 400 }}>здесь</span>
                                        <span style={{ fontWeight: 400 }}> размещена полная версия политики конфиденциальности.</span>
                                    </a>
                                </h4>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
