import React from 'react';
import { Link } from 'react-router-dom';

const PaymentsFailed = () => {
    return (
        <div className="container_payment_common">
            <div className="container_payment">
                <div className="error-box">
                    <div className="dot_payment" />
                    <div className="dot_payment two" />
                    <div className="face2">
                        <div className="eye" />
                        <div className="eye right_payment" />
                        <div className="mouth sad" />
                    </div>
                    <div className="shadow_payment move_payment" />
                    <div className="message_payment">
                        <h1 className="alert_payment">Ошибка!</h1>
                        <p className="p_payment">Упс, что то пошло не так!</p>
                    </div>
                    <button type="button" className="button_payment button-box">
                        <Link className="alert_payment green" to="/cart">
                            Вернуться
                        </Link>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PaymentsFailed;
