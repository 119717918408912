import { Breadcrumbs, Typography } from '@mui/material';
import Header from 'components/header';
import React from 'react';
import { Link as LinkRout } from 'react-router-dom';

const PrivacyPolicyFull = () => {
    return (
        <div>
            <Header />
            <div className="wrapper owerflow">
                <section className="section">
                    <div className="container">
                        <Breadcrumbs className="breadcrumbs" aria-label="breadcrumb">
                            <LinkRout underline="hover" color="inherit" to="/">
                                Главная
                            </LinkRout>
                            <Typography color="text.primary">Политика конфидециальности полная версия</Typography>
                        </Breadcrumbs>
                        <h1 className="page_title">Политика конфидециальности полная версия </h1>
                        <div className="faq_box">
                            <div className="content_box w-richtext">
                                <p>
                                    <b>Политика обработки персональных данных</b>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>г. Новосибирск </span> <span style={{ fontWeight: 400 }} />
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>Редакция от 15.12.2021 г.</span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Настоящая Политика обработки персональных данных (далее &#8212; Политика конфиденциальности)
                                        определяет порядок обработки и защиты персональной информации о физических лицах (далее –
                                        Пользователи), которая может быть получена Индивидуальным предпринимателем Тюменцевым Алексеем
                                        Сергеевичем (далее – ИП Тюменцев А.С.) при заказе Товаров ИП Тюменцева А.С, оставлении отзывов на
                                        них Пользователем, в интернет-магазине по адресу{' '}
                                    </span>
                                    <a href="https://millidge.site/">
                                        <span style={{ fontWeight: 400 }}>https://millidge.site/</span>
                                    </a>
                                    <span style={{ fontWeight: 400 }}>, (далее &#8212; Сайт), включая поддомены.</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        {' '}
                                        Использование сервисов Сайта означает безоговорочное согласие Пользователя с настоящей Политикой и
                                        указанными в ней условиями обработки его персональной информации; в случае несогласия с этими
                                        условиями Пользователь должен воздержаться от использования сервисов, сайта.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Текст Политики доступен Пользователям в сети Интернет по адресу{' '}
                                    </span>
                                    <a href="https://millidge.site/privacy-policy/">
                                        <span style={{ fontWeight: 400 }}>https://millidge.site/privacy-policy/</span>
                                    </a>
                                    <span style={{ fontWeight: 400 }}>. </span>
                                </p>
                                <ol>
                                    <li>
                                        <span style={{ fontWeight: 400 }}> ОБЩИЕ ПОЛОЖЕНИЯ</span>
                                    </li>
                                </ol>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        1.1. В рамках настоящей Политики под персональной информацией Пользователя понимаются:
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        1.1.1. Персональная информация, которую Пользователь предоставляет о себе самостоятельно при заказе
                                        товаров ИП Тюменцева А.С.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        1.1.2. Данные, которые автоматически передаются сервисам Сайта в процессе их использования с помощью
                                        установленного на устройстве Пользователя программного обеспечения, в том числе IP-адрес, данные
                                        файлов cookie, информация о браузере Пользователя (или иной программе, с помощью которой
                                        осуществляется доступ к сервисам), технические характеристики оборудования и программного
                                        обеспечения, используемых Пользователем, дата и время доступа к сервисам, адреса запрашиваемых
                                        страниц и иная подобная информация.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        1.1.3. Настоящая Политика конфиденциальности применяется только к Сайту
                                    </span>{' '}
                                    <span style={{ fontWeight: 400 }}>
                                        и ко всем субдоменам, созданным на его основе. Оператор не контролирует и не несет ответственности
                                        за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на Сайте{' '}
                                    </span>
                                    <span style={{ fontWeight: 400 }}>и</span>
                                    <span style={{ fontWeight: 400 }}> на всех субдоменах, созданных на его основе.</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        1.1.4. Оператором персональных данных и Администратором сайта является:{' '}
                                    </span>
                                </p>
                                <p>
                                    <i>
                                        <span style={{ fontWeight: 400 }}>Индивидуальный предприниматель Тюменцев Алексей Сергеевич,</span>
                                    </i>
                                </p>
                                <p>
                                    <i>
                                        <span style={{ fontWeight: 400 }}>
                                            Адрес: 630007, Россия, Новосибирская обл, г Новосибирск, пр-кт Красный, д 11, кв 45,
                                        </span>
                                    </i>
                                </p>
                                <p>
                                    <i>
                                        <span style={{ fontWeight: 400 }}>ИНН 540601979397,</span>
                                    </i>
                                </p>
                                <p>
                                    <i>
                                        <span style={{ fontWeight: 400 }}>ОГРН 321547600134421,</span>
                                    </i>
                                </p>
                                <p>
                                    <i>
                                        <span style={{ fontWeight: 400 }}>Эл.почта: millidgecosmetic@gmail.com</span>
                                    </i>
                                    <i>
                                        <span style={{ fontWeight: 400 }}>,</span>
                                    </i>
                                </p>
                                <p>
                                    <i>
                                        <span style={{ fontWeight: 400 }}>Телефон +7 (995) 156-62-28</span>
                                    </i>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        1.1.5. Пользователь выражает свое согласие с условиями Политики и дает Администратору сайта
                                        конкретное и сознательное согласие на обработку Администратором сайта своих персональных данных на
                                        условиях, предусмотренных Политикой и Федеральным законом от 27.07.2006 N 152-ФЗ (ред. от
                                        29.07.2017) &#171;О персональных данных&#187;:{' '}
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        &#8212; при оформления заказа товаров &#8212; в момент проставления галочки в поле “ Я даю свое
                                        согласие на обработку моих персональных данных и принимаю условия Договора-оферты” и в момент
                                        нажатия кнопки “Перейти к оплате”,
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        &#8212; при оставлении отзыва о товарах Оператора на Сайте, на страницах в социальных сетях
                                        Оператора.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <ol start="2">
                                    <li>
                                        <span style={{ fontWeight: 400 }}> ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</span>
                                    </li>
                                </ol>
                                <p>
                                    <span style={{ fontWeight: 400 }}>2.1.</span>{' '}
                                    <span style={{ fontWeight: 400 }}>
                                        Настоящая Политика устанавливает обязательства Администратора сайта по неразглашению и обеспечению
                                        режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет по запросу
                                        Администратора сайта при регистрации на сайте или при оформлении заявки на
                                        Произведения/товары/услуги.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        2.2. В случаях, предусмотренных Политикой, обработка персональных данных осуществляется при наличии
                                        согласия субъекта персональных данных.
                                    </span>
                                </p>
                                <ol start="3">
                                    <li>
                                        <span style={{ fontWeight: 400 }}> СБОР ПЕРСОНАЛЬНЫХ ДАННЫХ.</span>
                                    </li>
                                </ol>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        3.1. Персональные данные, разрешённые к обработке в рамках настоящей Политики, предоставляются
                                        Пользователем путём заполнения формы заказа на Сайте или в результате размещения своего отзыва о
                                        товарах Исполнителя, и включают (может включать частично или в полном объеме) в себя следующую
                                        информацию:
                                    </span>
                                </p>
                                <ul>
                                    <li style={{ fontWeight: 400 }} aria-level="1">
                                        <span style={{ fontWeight: 400 }}>фамилию, имя, отчество Пользователя; </span>
                                    </li>
                                    <li style={{ fontWeight: 400 }} aria-level="1">
                                        <span style={{ fontWeight: 400 }}>контактная информация (телефон, e-mail);</span>
                                    </li>
                                    <li style={{ fontWeight: 400 }} aria-level="1">
                                        <span style={{ fontWeight: 400 }}>адрес проживания (доставки);</span>
                                    </li>
                                    <li style={{ fontWeight: 400 }} aria-level="1">
                                        <span style={{ fontWeight: 400 }}>ссылки на аккаунты в социальных сетях;</span>
                                    </li>
                                    <li style={{ fontWeight: 400 }} aria-level="1">
                                        <span style={{ fontWeight: 400 }}>банковские реквизиты, заполняемые при оформлении заказа;</span>
                                    </li>
                                    <li style={{ fontWeight: 400 }} aria-level="1">
                                        <span style={{ fontWeight: 400 }}>изображение Пользователя.</span>
                                    </li>
                                </ul>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        3.2. Администратор сайта защищает данные, которые автоматически передаются в процессе просмотра
                                        рекламных блоков и при посещении страниц, на которых установлен статистический скрипт системы
                                        (&#171;пиксель&#187;):
                                    </span>
                                </p>
                                <ul>
                                    <li>
                                        <span style={{ fontWeight: 400 }}>IP адрес;</span>
                                    </li>
                                    <li>
                                        <span style={{ fontWeight: 400 }}>информация из cookies;</span>
                                    </li>
                                    <li>
                                        <span style={{ fontWeight: 400 }}>
                                            информация о браузере (или иной программе, которая осуществляет доступ к показу рекламы);
                                        </span>
                                    </li>
                                    <li>
                                        <span style={{ fontWeight: 400 }}>время доступа;</span>
                                    </li>
                                </ul>
                                <ul>
                                    <li style={{ fontWeight: 400 }} aria-level="1">
                                        <span style={{ fontWeight: 400 }}>
                                            информация об устройстве, используемом Пользователем для доступа к сайту;
                                        </span>
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <span style={{ fontWeight: 400 }}>адрес страницы, на которой расположен рекламный блок;</span>
                                    </li>
                                    <li>
                                        <span style={{ fontWeight: 400 }}>реферер (адрес предыдущей страницы),</span>
                                    </li>
                                    <li>
                                        <span style={{ fontWeight: 400 }}>количество посещений страниц;</span>
                                    </li>
                                    <li>
                                        <span style={{ fontWeight: 400 }}>информация о перемещении по страницам сайта;</span>
                                    </li>
                                    <li>
                                        <span style={{ fontWeight: 400 }}>длительность пользовательской сессии;</span>
                                    </li>
                                    <li>
                                        <span style={{ fontWeight: 400 }}>страна пользователя;</span>
                                    </li>
                                    <li>
                                        <span style={{ fontWeight: 400 }}>регион пользователя;</span>
                                    </li>
                                    <li>
                                        <span style={{ fontWeight: 400 }}>часовой пояс, установленный на устройстве пользователя;</span>
                                    </li>
                                    <li>
                                        <span style={{ fontWeight: 400 }}>провайдер пользователя.</span>
                                    </li>
                                </ul>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        3.3. Отключение cookies может повлечь невозможность доступа к частям сайта, требующим авторизации, к
                                        потере сохраненной Пользователем информации, в том числе, содержимого корзины.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        3.4. Администратор сайта осуществляет сбор статистики об IP-адресах своих посетителей. Данная
                                        информация используется с целью выявления и решения технических проблем.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        3.5. Любая иная персональная информация, неоговоренная выше (используемые браузеры и операционные
                                        системы и т.д.), подлежит надежному хранению и нераспространению, за исключением случаев,
                                        предусмотренных в разделе 4 настоящей Политики.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        3.6. Пассивный сбор данных Пользователя, которые автоматически передаются в процессе просмотра
                                        информации на Сайте и при посещении сайта.
                                    </span>
                                </p>
                                <p>
                                    <b>3.7. Сбор IP адресов пользователей и файлов cookies:</b>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        IP-адрес &#8212; это номер, который автоматически присваивается компьютеру Пользователя провайдером
                                        интернет-услуг. IP-адрес идентифицируется и автоматически регистрируется в файлах сервера Оператора
                                        каждый раз, когда пользователь посещает Сайт; а также регистрируется время визита и просмотренные
                                        страницы. Сбор IP-адресов является стандартной практикой в Интернете и производится автоматически
                                        многими веб-сайтами. Оператор использует IP-адреса в целях расчета уровня посещаемости Сайта,
                                        диагностики проблем на сервере и администрирования Сайта.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Файлы cookie — это небольшие фрагменты информации о посещении сайта, которые сервер отправляет на
                                        устройство Пользователя и хранятся на устройстве Пользователя. Файлы cookie запоминают информацию о
                                        предпочтениях Пользователя, позволяют в более удобном режиме просматривать посещенные сайты в
                                        течение определенного времени. Например, тип браузера, время, проведенное на Сайте, просмотренные
                                        страницы и предпочтения языка, статистическая информация об использовании Сайта в целях постоянного
                                        усовершенствования его дизайна и функциональности.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>Оператор использует файлы cookies для того, чтобы:</span>
                                </p>
                                <ul>
                                    <li>
                                        <span style={{ fontWeight: 400 }}>улучшать качество работы с Сервисами;</span>
                                    </li>
                                    <li>
                                        <span style={{ fontWeight: 400 }}>сохранять настройки Пользователя;</span>
                                    </li>
                                    <li>
                                        <span style={{ fontWeight: 400 }}>
                                            отображать рекламу, которая может заинтересовать Пользователя;
                                        </span>
                                    </li>
                                    <li>
                                        <span style={{ fontWeight: 400 }}>анализировать статистику использования Сервисов.</span>
                                    </li>
                                </ul>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Пользователь может отказаться принимать файлы cookie, выполнив инструкции своего браузера; однако, в
                                        этом случае могут возникнуть некоторые неудобства при использовании Сайта.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        На основании данных, полученных с помощью файлов cookie, Оператор также проводит статистические и
                                        маркетинговые исследования, исправляет ошибки в сервисах Сайта.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        3.8. Пользователь выражает свое согласие с условиями Политики и дает Администратору Сайта конкретное
                                        и сознательное согласие на обработку Администратором Сайта своих персональных данных, перечисленных
                                        в настоящей Политике, на условиях, предусмотренных Политикой и Федеральным законом от 27.07.2006 N
                                        152-ФЗ (ред. от 29.07.2017) &#171;О персональных данных&#187;.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <ol start="4">
                                    <li>
                                        <span style={{ fontWeight: 400 }}> ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЕЙ</span>
                                    </li>
                                </ol>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        4.1. Оператор собирает и хранит только ту персональную информацию, которая необходима для
                                        предоставления сервисов или исполнения соглашений и договоров с Пользователем, за исключением
                                        случаев, когда законодательством предусмотрено обязательное хранение персональной информации в
                                        течение определенного законом срока.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        4.2. Персональную информацию Пользователя Оператор обрабатывает в следующих целях:
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        4.2.1. Для выполнения обязательств по договору, заключенному с Пользователем.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>4.2.2. Доставки товаров Пользователю.</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        4.2.3. Установления с Пользователем обратной связи, включая направление уведомлений, запросов,
                                        касающихся использования Сайта, исполнения заказа, обработку запросов и заявок от Пользователя.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        4.2.4. Определения местонахождения Пользователя для обеспечения безопасности, предотвращения
                                        мошенничества.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        4.2.5. Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        4.2.6. Уведомления Пользователя Сайта об изменениях, дополнениях к договору с Пользователем, в том
                                        числе акциях, событиях и специальных предложениях.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        4.2.7. Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении
                                        проблем, связанных с использованием Сайта или доступа к произведениям.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        4.2.8. Осуществления рекламной деятельности с согласия Пользователя.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        4.2.9. Размещение оставленных Пользователем комментариев в официальных группах в социальных сетях и
                                        иных сообществах Оператора в сети Интернет.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <ol start="5">
                                    <li>
                                        <span style={{ fontWeight: 400 }}>
                                            {' '}
                                            УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЕЙ И ЕЕ ПЕРЕДАЧИ ТРЕТЬИМ ЛИЦАМ
                                        </span>
                                    </li>
                                </ol>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        5.1. Обработка персональных данных осуществляется Обществом на основаниях, определенных
                                        законодательством Российской Федерации.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        5.2. Согласие на обработку персональных данных, разрешенных субъектом персональных данных для
                                        распространения, оформляется отдельно от иных согласий субъекта персональных данных на обработку его
                                        персональных данных.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        5.3. Обработка персональных данных Пользователя производится Оператором с использованием баз данных
                                        на территории Российской Федерации.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        5.4. Обработка персональных данных Оператором осуществляется на законной и справедливой основе и
                                        ограничивается достижением конкретных, заранее определенных и законных целей, в том числе
                                        перечисленных в настоящей Политике.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        5.5. Обработке подлежат только персональные данные, которые отвечают целям их обработки. Содержание
                                        и объем обрабатываемых Оператором персональных данных соответствуют заявленным целям обработки,
                                        избыточность обрабатываемых персональных данных не допускается.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        5.6. При обработке персональных данных обеспечивается точность персональных данных, их достаточность
                                        и, в необходимых случаях, актуальность по отношению к целям обработки персональных данных.{' '}
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        5.7. В отношении персональной информации Пользователя сохраняется ее конфиденциальность, кроме
                                        случаев добровольного предоставления Пользователем информации о себе для общего доступа
                                        неограниченному кругу лиц.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        5.8. Оператор вправе передать персональную информацию Пользователя третьим лицам в следующих
                                        случаях:
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>5.8.1. Пользователь выразил согласие на такие действия.</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        5.8.2. Передача необходима для использования Пользователем определенного сервиса либо для исполнения
                                        определенного соглашения или договора с Пользователем.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        5.8.3. Передача предусмотрена российским или иным применимым законодательством в рамках
                                        установленной законодательством процедуры.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        5.8.4. В случае продажи Сайта к приобретателю переходят все обязательства по соблюдению условий
                                        настоящей Политики применительно к полученной им персональной информации.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        5.9. При утрате или разглашении персональных данных Оператор информирует Пользователя об утрате или
                                        разглашении персональных данных.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        5.10. Оператор совместно с Пользователем принимает все необходимые меры по предотвращению убытков
                                        или иных отрицательных последствий, вызванных утратой или разглашением персональных данных
                                        Пользователя.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <ol start="6">
                                    <li>
                                        <span style={{ fontWeight: 400 }}> СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ.</span>
                                    </li>
                                </ol>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        6.1. Обработка персональных данных Пользователя осуществляется в течение срока оказания услуг
                                        Обществом, в течение срока использования Сайта и сервисов Оператора и в течение иного срока,
                                        необходимого для достижения целей обработки персональных данных, а также в течение 5 лет после
                                        достижения цели обработки, любым законным способом, в том числе в информационных системах
                                        персональных данных с использованием средств автоматизации или без использования таких средств.
                                        Обработка персональных данных Пользователей осуществляется в соответствии с Федеральным законом от
                                        27.07.2006 № 152-ФЗ «О персональных данных». Обрабатываемые персональные данные подлежат уничтожению
                                        либо обезличиванию по окончанию срока обработки или в случае утраты необходимости в достижении целей
                                        обработки.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        6.2. Передача (распространение, предоставление, доступ) персональных данных, разрешенных
                                        Пользователем для распространения, должна быть прекращена в любое время по требованию Пользователя.
                                        Данное требование должно включать в себя фамилию, имя, отчество (при наличии), контактную информацию
                                        (номер телефона, адрес электронной почты или почтовый адрес) Пользователя, предоставленные Оператору
                                        при первоначальном обращении, а также перечень персональных данных, обработка которых подлежит
                                        прекращению. Указанные в данном требовании персональные данные могут обрабатываться только
                                        Оператором, которому оно направлено.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        6.3. Действие согласия Пользователя на обработку персональных данных, разрешенных Пользователем для
                                        распространения, прекращается с момента поступления Оператору требования, указанного в п.6.2.
                                        настоящей Политики.
                                    </span>
                                </p>
                                <ol start="7">
                                    <li>
                                        <span style={{ fontWeight: 400 }}> МЕРЫ ЗАЩИТЫ ПЕРСОНАЛЬНЫХ ДАННЫХ.</span>
                                    </li>
                                </ol>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        7.1. Оператор принимает необходимые организационные, административные и технические меры для защиты
                                        персональной информации Пользователя от неправомерного или случайного доступа, уничтожения,
                                        изменения, блокирования, копирования, распространения, а также от иных неправомерных действий
                                        третьих лиц.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        7.2. При утрате или разглашении персональных данных Оператор информирует Пользователя об утрате или
                                        разглашении персональных данных
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        7.3. Оператор совместно с Пользователем принимает все необходимые меры по предотвращению убытков или
                                        иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.
                                    </span>
                                </p>
                                <ol start="8">
                                    <li>
                                        <span style={{ fontWeight: 400 }}> ИСПОЛЬЗОВАНИЕ И РАСКРЫТИЕ ИНФОРМАЦИИ</span>
                                    </li>
                                </ol>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        8.1. Оператор вправе предоставлять персональные данные Пользователей третьим лицам в следующих
                                        случаях:
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        8.1.1. аффилированным лицам Оператора. При этом Оператор остается ответственным за соблюдение
                                        конфиденциальности и защиты персональных данных в случае совместного использования информации о
                                        субъектах персональных данных;
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        8.1.2. сторонним партнерам, совместно с которыми Оператор продвигает свои услуги на рынок под
                                        совместным брендом или проводит совместные маркетинговые мероприятия;
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        8.1.3. сторонним поставщикам таких услуг Оператору, как например, хостинг и администрирование
                                        веб-сайта, анализ данных, обработка платежей, доставка товаров, обеспечение инфраструктуры,
                                        ИТ-услуги, обработка кредитных карт, специализированных профессиональных услуг (консалтинг, аудит,
                                        услуги по информационной безопасности) и другие услуги, чтобы позволить им предоставлять свои
                                        услуги;
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        8.1.4. третьей стороне в случае реорганизации, слияния, продажи, создания совместного предприятия,
                                        передачи или ликвидации организации, активов или долей полностью или частично (включая все
                                        вышеперечисленное в связи с банкротством или подобным судебным процессом).
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Вместе с тем Оператор в обязательном порядке включает в договоры с поставщиками таких услуг
                                        положения о конфиденциальности и защите персональных данных или подписывает отдельное соглашение о
                                        конфиденциальности и защите персональных данных.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        8.2. Кроме того, Оператор использует и раскрывает информацию, собранную через Сайт, которая,
                                        необходима или уместна:
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        а) в соответствии с применимым законодательством, включая законы, действующие за пределами РФ;
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>б) в целях содействия судебному процессу;</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>в) в качестве ответа на запросы государственных органов власти;</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        г) в целях принудительного применения условий и положений Политики, иных соглашений с Оператором;
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        д) в целях защиты деятельности Оператора и деятельности аффилированных лиц.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        8.3. Оператор также может раскрывать информацию, собранную пассивно, если такая информация
                                        обезличена (например, публикация статистики посещений на Сайте).
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>8.4. Трансграничная передача. </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Оператор обязан убедиться в том, что иностранным государством, на территорию которого предполагается
                                        осуществлять передачу персональных данных, обеспечивается адекватная защита прав субъектов
                                        персональных данных, до начала осуществления такой передачи. Трансграничная передача персональных
                                        данных на территории иностранных государств, не обеспечивающих адекватной защиты прав субъектов
                                        персональных данных, может осуществляться в случаях: наличия согласия в письменной форме субъекта
                                        персональных данных на трансграничную передачу его персональных данных; исполнения договора,
                                        стороной которого является субъект персональных данных.
                                    </span>
                                </p>
                                <ol start="9">
                                    <li>
                                        <span style={{ fontWeight: 400 }}> ОБЯЗАТЕЛЬСТВА СТОРОН</span>
                                    </li>
                                </ol>
                                <p>
                                    <span style={{ fontWeight: 400 }}>9.1. Пользователь обязан:</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        9.1.1. Предоставить информацию о персональных данных, необходимую для пользования Сайтом.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        9.1.2. Обновлять, дополнять предоставленную информацию о персональных данных в случае изменения
                                        данной информации.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>9.2. Оператор обязан:</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        9.2.1. Использовать полученную информацию исключительно для целей, указанных в настоящей Политике
                                        конфиденциальности.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        9.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного
                                        письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование либо
                                        разглашение иными возможными способами переданных персональных данных Пользователя, за исключением
                                        предусмотренных настоящей Политикой конфиденциальности.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        9.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных
                                        Пользователя согласно порядку, обычно используемому для защиты такого рода информации в существующем
                                        деловом обороте.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        9.2.4. Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с
                                        момента обращения или запроса Пользователя или его законного представителя либо уполномоченного
                                        органа по защите прав субъектов персональных данных на период проверки в случае выявления
                                        недостоверных персональных данных или неправомерных действий.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        9.2.5. В срок не позднее трех рабочих дней с момента получения соответствующего согласия
                                        Пользователя опубликовать информацию об условиях обработки и о наличии запретов и условий на
                                        обработку неограниченным кругом лиц персональных данных, разрешенных Пользователем для
                                        распространения.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>9.3. Пользователь вправе: </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        9.3.1. По своему усмотрению предоставлять Оператору персональные данные для их обработки на
                                        условиях, указанных в Политике, устанавливать запреты на передачу (кроме предоставления доступа)
                                        этих персональных данных Оператором неограниченному кругу лиц, а также устанавливать запреты на
                                        обработку или условия обработки (кроме получения доступа) персональных данных, разрешенных
                                        Пользователем для распространения путем отправки обращения на электронную почту Оператора:
                                        millidgecosmetic@gmail.com
                                    </span>
                                    <span style={{ fontWeight: 400 }}>.</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        9.3.2. Требовать от Оператора уточнения своих персональных данных, их блокирования или уничтожения в
                                        случае, если такие данные являются неполными, устаревшими, недостоверными, незаконно полученными или
                                        не являются необходимыми для заявленной цели обработки. Требование направляется на электронную почту
                                        Оператора millidgecosmetic@gmail.com
                                    </span>
                                    <span style={{ fontWeight: 400 }}> </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        9.3.3. Направить Оператору заявление об отзыве своего согласия на обработку персональных данных в
                                        порядке, предусмотренном п.9.3.2. Политики
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        9.3.4. На основании запроса получать от Оператора информацию, касающуюся обработки его персональных
                                        данных в порядке, предусмотренном п.9.3.2. Политики.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        9.3.5. Отказаться от получения рекламной почтовой рассылки Исполнителя, других сообщений от
                                        Исполнителя, следуя инструкциям в этих сообщениях. В случае отказа Исполнитель вправе отправлять
                                        Пользователю сообщения нерекламного характера, например уведомления об изменениях в порядке оказания
                                        услуг или информацию об аккаунте Пользователя.
                                    </span>
                                </p>
                                <ol start="10">
                                    <li>
                                        <span style={{ fontWeight: 400 }}> ОТВЕТСТВЕННОСТЬ СТОРОН</span>
                                    </li>
                                </ol>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        10.1. Оператор, не исполнивший свои обязательства, несет ответственность за убытки, понесенные
                                        Пользователем в связи с неправомерным использованием персональных данных, в соответствии с
                                        законодательством Российской Федерации.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        10.2. В случае утраты или разглашения конфиденциальной информации Оператор не несет ответственности,
                                        если данная конфиденциальная информация:
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        10.2.1. Стала публичным достоянием до ее утраты или разглашения.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        10.2.2. Была получена от третьей стороны до момента ее получения Оператором.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>10.2.3. Была разглашена с согласия Пользователя.</span>
                                </p>
                                <p>&nbsp;</p>
                                <ol start="11">
                                    <li>
                                        <span style={{ fontWeight: 400 }}> РАЗРЕШЕНИЕ СПОРОВ</span>
                                    </li>
                                </ol>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        11.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем Сайта и
                                        Оператором, обязательным является предъявление претензии (письменного предложения о добровольном
                                        урегулировании спора).
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        11.2. Получатель претензии в течение 10 (десяти) календарных дней со дня получения претензии
                                        письменно уведомляет заявителя претензии о результатах рассмотрения претензии.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        11.3. При недостижении соглашения спор будет передан на рассмотрение в суд в соответствии с
                                        действующим законодательством Российской Федерации.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        11.4. К настоящей Политике конфиденциальности и отношениям между Пользователем и Оператором
                                        применяется действующее законодательство Российской Федерации.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <ol start="12">
                                    <li>
                                        <span style={{ fontWeight: 400 }}> ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</span>
                                    </li>
                                </ol>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        12.1. Администратор Сайта вправе вносить изменения в настоящую Политику конфиденциальности без
                                        согласия Пользователя.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        12.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте, если иное
                                        не предусмотрено новой редакцией Политики конфиденциальности.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <b>Реквизиты Оператора персональных данных</b>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <i>
                                        <span style={{ fontWeight: 400 }}>Индивидуальный предприниматель Тюменцев Алексей Сергеевич,</span>
                                    </i>
                                </p>
                                <p>
                                    <i>
                                        <span style={{ fontWeight: 400 }}>
                                            Адрес: 630007, Россия, Новосибирская обл, г Новосибирск, пр-кт Красный, д 11, кв 45,
                                        </span>
                                    </i>
                                </p>
                                <p>
                                    <i>
                                        <span style={{ fontWeight: 400 }}>ИНН 540601979397,</span>
                                    </i>
                                </p>
                                <p>
                                    <i>
                                        <span style={{ fontWeight: 400 }}>ОГРН 321547600134421,</span>
                                    </i>
                                </p>
                                <p>
                                    <i>
                                        <span style={{ fontWeight: 400 }}>Адрес для претензий: millidgecosmetic@gmail.com</span>
                                    </i>
                                    <i>
                                        <span style={{ fontWeight: 400 }}>,</span>
                                    </i>
                                </p>
                                <p>
                                    <i>
                                        <span style={{ fontWeight: 400 }}>Телефон +7 (995) 156-62-28</span>
                                    </i>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default PrivacyPolicyFull;
