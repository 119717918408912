import { Button } from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { ADD_TO_CART } from 'store/current-actions';
import config from 'config';

const ModalDescription = ({ visible, onClose, sostav, zagolovok_sostava }) => {
    return (
        <div className="modal_window-2" style={{ display: visible ? 'block' : 'none' }}>
            <Button className="close_ico" onClick={() => onClose()} size="large" variant="text" color="inherit">
                <img
                    src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                    loading="lazy"
                    alt=""
                    data-lazy-src={`${config.storeUrl}/61a7ae3adfdf5e169c5ba87b_Close.svg`}
                />
            </Button>

            <div className="header_cream">{zagolovok_sostava}</div>
            <div className="structure_cream">{sostav}</div>
        </div>
    );
};

ModalDescription.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    sostav: PropTypes.string,
    zagolovok_sostava: PropTypes.string
};

const ProductInfoVertical = ({
    id,
    naznachenie_tovara,
    title,
    weight,
    weightType,
    cost,
    imageUrl,
    sostav,
    zagolovok_sostava,
    desc,
    postoyannye_dannye_podpis_sostav,
    postoyannye_dannye_zagolovok_sostava,
    postoyannye_dannye_zagolovok_kompleks,
    postoyannye_dannye_podpis_kompleks
}) => {
    /* const [openAllElemets, setOpenAllElemets] = useState(false); */
    const [openIngridientsModal, setOpenIngridientsModal] = useState(false);
    const dispatch = useDispatch();

    const handleAddToCart = (cartItem) => {
        dispatch({ type: ADD_TO_CART, payload: cartItem });
    };

    /* const handleOpenAllElemets = () => {
        setOpenAllElemets(!openAllElemets);
    }; */

    const handleOpenIngridientsModal = () => {
        setOpenIngridientsModal(!openIngridientsModal);
    };

    return (
        <div>
            <section className="section main_single">
                <div className="container single2">
                    <div className="product_title_box">
                        <div className="single_subtitle">{naznachenie_tovara}</div>
                        <h1 className="page_title">{title}</h1>
                    </div>
                    <ModalDescription
                        visible={openIngridientsModal}
                        onClose={setOpenIngridientsModal}
                        sostav={sostav}
                        zagolovok_sostava={zagolovok_sostava}
                    />
                    <div className="single_content-2">
                        <div className="single_product_imagebox-2">
                            <div className="product-option_box-2 for_single">
                                <div className="wieght">Вес</div>
                                <div className="product_weight big-size">
                                    {weight} {weightType}
                                </div>
                                <div className="wieght">упаковки</div>
                            </div>
                            <img src={imageUrl} loading="eager" width="690" alt="" className="product_image2-2" />
                        </div>
                        <div className="slide-product_desc for_single2">
                            <div className="single_desc">Описание товара:</div>
                            <div className="w-form">
                                <div className="w-checkbox product-desc">
                                    <div className="product_description" htmlFor="checkbox-2">
                                        <p>{desc}</p>
                                        {/* {productInfo.contain.slice(0, openAllElemets ? 99 : 1).map((item, idx) => (
                                            <ul key={idx}>
                                                <li>{item}</li>
                                            </ul>
                                        ))} */}
                                    </div>
                                    {/* <Button onClick={() => handleOpenAllElemets()} size="large" variant="text" color="inherit">
                                        {openAllElemets ? 'Скрыть' : 'Раскрыть'}
                                        <img
                                            src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                            loading="lazy"
                                            alt=""
                                            className={openAllElemets ? 'single_title_ico_transform' : 'single_title_ico'}
                                            data-lazy-src={`${config.storeUrl}/61a5062df134885e97321ae2_Groubp.svg`}
                                        />
                                    </Button> */}
                                </div>
                            </div>
                            <div className="single_product_wc">
                                <div className="mini_product_infobox">
                                    <div className="product_price for_bests" data-content="product_price" style={{ display: 'block' }}>
                                        {cost},00&nbsp;
                                        <span className="rur">
                                            &#x440;<span>&#x443;&#x431;.</span>
                                        </span>
                                    </div>
                                </div>

                                <div className="product_form w-form">
                                    <Button
                                        onClick={() =>
                                            handleAddToCart({
                                                id,
                                                naznachenie_tovara,
                                                title,
                                                weight,
                                                weightType,
                                                cost,
                                                imageUrl,
                                                sostav,
                                                zagolovok_sostava,
                                                desc
                                            })
                                        }
                                        size="large"
                                        variant="contained"
                                        className="add-to-cart"
                                    >
                                        <span className="small-btn-icon" /> в корзину
                                    </Button>
                                </div>
                            </div>

                            <div className="single_product_items2">
                                <div className="single_product_item2">
                                    <Button onClick={() => handleOpenIngridientsModal()} size="small" variant="text" color="inherit">
                                        <div data-w-id="fbafb3dd-3b79-e7ea-6d7c-ee6f87235a02" className="single_title_link-2">
                                            <div className="single_product_title link">{postoyannye_dannye_zagolovok_sostava}</div>
                                            <img
                                                src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                                loading="lazy"
                                                alt=""
                                                className="single_title_ico"
                                                data-lazy-src={`${config.storeUrl}/61a5010213ed521f2ee51f25_point-to-right202.svg`}
                                            />
                                        </div>
                                    </Button>

                                    <div className="single-subheader_box">
                                        <div>{postoyannye_dannye_podpis_sostav}</div>
                                    </div>
                                    <img
                                        src={`${config.storeUrl}/61ae7acafa8b5c81e547417c_D181D0BED181D182D0B0D0B220D0BFD180D0BED0B4D183D0BAD182D0B0201.png`}
                                        loading="eager"
                                        width="83"
                                        alt=""
                                        className="single_item_img"
                                    />
                                </div>
                                {postoyannye_dannye_podpis_kompleks && (
                                    <div className="single_product_item2">
                                        <div className="single_product_title">{postoyannye_dannye_zagolovok_kompleks}</div>
                                        <div className="single-subheader_box-long2">
                                            <div>{postoyannye_dannye_podpis_kompleks}</div>
                                        </div>
                                        <img
                                            src={`${config.storeUrl}/61ae7ac97dd99e46227bac93_Frame2021.png`}
                                            loading="eager"
                                            width="103"
                                            alt=""
                                            className="single_item_img3"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <img
                    src={`${config.storeUrl}/61a4ff252619b35214c1c43d_12.png`}
                    loading="eager"
                    alt=""
                    className="best-bush_bg for_single mob"
                />
            </section>
        </div>
    );
};

ProductInfoVertical.propTypes = {
    naznachenie_tovara: PropTypes.string,
    title: PropTypes.string,
    id: PropTypes.number,
    weight: PropTypes.number,
    weightType: PropTypes.string,
    imageUrl: PropTypes.string,
    sostav: PropTypes.string,
    zagolovok_sostava: PropTypes.string,
    desc: PropTypes.string,
    postoyannye_dannye_podpis_sostav: PropTypes.string,
    postoyannye_dannye_zagolovok_sostava: PropTypes.string,
    postoyannye_dannye_zagolovok_kompleks: PropTypes.string,
    postoyannye_dannye_podpis_kompleks: PropTypes.string,
    cost: PropTypes.number
};

export default ProductInfoVertical;
