const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    /* storeUrl: process.env.NODE_ENV === 'production' ? 'https://1790009369.rsc.cdn77.org/images' : 'https://1790009369.rsc.cdn77.org/images', */
    basename: '',
    defaultPath: '',
    storeUrl: 'https://1790009369.rsc.cdn77.org/images',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12
};

export default config;
