import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { ADD_TO_CART } from 'store/current-actions';
import { Link } from 'react-router-dom';

export const Product = ({ id, imageUrl, title, cost, weight, isSlider, weightType }) => {
    const dispatch = useDispatch();

    const handleAddToCart = (cartItem) => {
        dispatch({ type: ADD_TO_CART, payload: cartItem });
    };
    return (
        <div className={isSlider ? 'product_item' : 'product'}>
            <div className="product_box for_cat">
                <div>
                    <Link className="product-image_link w-inline-block" to={`/products/product/${id}`}>
                        <div className="abs for_product_img">
                            <img src={imageUrl} loading="lazy" width="124.5" alt="" className="product_image" title="" />
                        </div>
                    </Link>
                    <Link className="title_link w-inline-block" to={`/products/product/${id}`}>
                        <h3 className="product_title">{title}</h3>
                    </Link>
                </div>
                <div>
                    <div className="product-price_box">
                        <div className="product_price" data-content="product_price" style={{ display: 'block' }}>
                            {cost}&nbsp;
                            <span className="rur">
                                &#x440;<span>&#x443;&#x431;.</span>
                            </span>
                        </div>
                        <div>
                            <div className="product_weight">
                                <span>{weight}</span> <span>{weight && weightType}</span>
                            </div>
                        </div>
                        <div />
                    </div>
                    <div className="product_form w-form">
                        <Button
                            onClick={() => handleAddToCart({ id, name: title, cost, imageUrl, weight, weightType })}
                            size="large"
                            variant="contained"
                            className="add-to-cart"
                        >
                            Добавить в корзину
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

Product.propTypes = {
    id: PropTypes.number,
    imageUrl: PropTypes.string,
    title: PropTypes.string,
    cost: PropTypes.number,
    weight: PropTypes.number,
    weightType: PropTypes.string,
    isSlider: PropTypes.bool
};
