export const prizes = [
    {
        id: 1,
        title: 'Астрологический прогноз',
        type: 'easy',
        imageUrl: '/345.png',
        desc: 'Поздравляем! Вы выиграли персональный астрологический прогноз на 2022 год. 🥳 Астрология — обрела безумную популярность в последние годы, личных астрологов имеют даже политики и мировые звезды! Нажимайте на ссылку и узнайте, что подготовили для Вас звезды!',
        link: 'https://millidge.ru/horoscope',
        cardImage: '/Novyi-proekt-5.png',
        startNumber: 5,
        step: 5,
        hardNumber: null
    },
    {
        id: 2,
        title: 'Гайд: 5 причин отеков и секретные способы решения',
        type: 'easy',
        imageUrl: '/456.png',
        desc: 'Поздравляем!  Мы открыли для Вас доступ к антицеллюлитному гайду.  «5 причин отеков и секретные способы борьбы с ними»',
        link: '/anti.pdf',
        cardImage: '/Novyi-proekt-5.png',
        startNumber: 4,
        step: 5,
        hardNumber: null
    },
    {
        id: 9,
        title: 'Инвестор плюс',
        type: 'easy',
        imageUrl: 'https://1790009369.rsc.cdn77.org/images/Investor-plyus-1-300x300.png',
        desc: 'Поздравляем! Вы выиграли обучение «ИНВЕСТОР+», стоимостью 5 490 ₽ 🥳 Пройдя его, Вы сформируете собственную подушку безопасности, которая позволит:✅  Иметь пассивный доход от 40 000 ₽ и ЗАРАБАТЫВАТЬ на фондовом рынке; ✅  Дать качественное образование своему ребёнку в престижном университете; ✅  Выйти на достойную пенсию, которая будет в 3-4 раза выше государственной;Чтобы получить доступ, необходимо перейти по ссылке и зарегистрироваться: ',
        link: 'https://lk.investor-plus.com/register-member-free?type=millidge',
        cardImage: '/Novyi-proekt-11.png',
        productId: 'investor',
        startNumber: 1,
        step: 5,
        hardNumber: null
    },
    {
        id: 3,
        title: 'Скраб для губ',
        type: 'medium',
        imageUrl: '/234.png',
        desc: 'Поздравляем, вы выиграли Скраб для губ, мы обязательно вложим его в ваш заказ.',
        link: null,
        cardImage: '/Novyi-proekt-12.png',
        productId: 7741,
        startNumber: 2,
        step: 5,
        hardNumber: null
    },
    {
        id: 4,
        title: 'Бальзам для губ',
        type: 'easy',
        imageUrl: '/123.png',
        desc: 'Поздравляем, вы выиграли бальзам для губ, мы обязательно вложим его в ваш заказ.',
        link: null,
        cardImage: '/Novyi-proekt-11.png',
        productId: 406,
        startNumber: 3,
        step: 5,
        hardNumber: null
    },
    {
        id: 5,
        title: 'Телевизор Samsung',
        type: 'medium',
        imageUrl: 'https://1790009369.rsc.cdn77.org/images/televizor-samsung-600x601.png',
        desc: 'Поздравляем! Вы счастливчик — сегодня вы выиграли Телевизор Samsung 🥳  Надеемся, этот подарок будет долго вас радовать!  Для того, чтобы получить приз:  Сделайте скриншот этого экрана  Выложите в инстаграм историю с отметкой @kto_takaya @millidge.cosmetics С вами свяжется менеджер для уточнения адреса доставки приза',
        link: null,
        cardImage: '/Novyi-proekt-12.png',
        productId: 585,
        startNumber: 100000,
        step: 1000000,
        hardNumber: null
    },
    {
        id: 6,
        title: 'Гриль Delonghi',
        type: 'medium',
        imageUrl: 'https://1790009369.rsc.cdn77.org/images/Snimok-ekrana-2021-12-28-v-12.42.24-1536x846.png',
        desc: 'Поздравляем! Вы счастливчик — сегодня вы выиграли Гриль Delonghi 🥳  Надеемся, этот подарок будет долго вас радовать!  Для того, чтобы получить приз:  Сделайте скриншот этого экрана  Выложите в инстаграм историю с отметкой @kto_takaya @millidge.cosmetics С вами свяжется менеджер для уточнения адреса доставки приза',
        link: null,
        cardImage: '/Novyi-proekt-11.png',
        productId: 407,
        startNumber: 100000000,
        step: 1000000000,
        hardNumber: null
    },
    {
        id: 10,
        title: 'Денежный приз',
        type: 'medium',
        imageUrl: '/2000.jpg',
        desc: 'Поздравляем! Вы счастливчик — сегодня вы выиграли денежный приз 🥳  Надеемся, этот подарок будет долго вас радовать!  Для того, чтобы получить приз:  Сделайте скриншот этого экрана  Выложите в инстаграм историю с отметкой @kto_takaya @millidge.cosmetics С вами свяжется менеджер для уточнения адреса доставки приза',
        link: null,
        cardImage: '/Novyi-proekt-11.png',
        productId: '2000',
        startNumber: 99999999999,
        step: 399999999,
        hardNumber: null
    },
    {
        id: 11,
        title: 'Денежный приз',
        type: 'medium',
        imageUrl: '/5000.jpg',
        desc: 'Поздравляем! Вы счастливчик — сегодня вы выиграли денежный приз 🥳  Надеемся, этот подарок будет долго вас радовать!  Для того, чтобы получить приз:  Сделайте скриншот этого экрана  Выложите в инстаграм историю с отметкой @kto_takaya @millidge.cosmetics С вами свяжется менеджер для уточнения адреса доставки приза',
        link: null,
        cardImage: '/Novyi-proekt-12.png',
        productId: '5000',
        startNumber: 99999999,
        step: 21111111111,
        hardNumber: null
    },
    {
        id: 8,
        title: 'Денежный приз',
        type: 'hard',
        imageUrl: 'https://1790009369.rsc.cdn77.org/images/1000r-1-300x300.png',
        desc: 'Поздравляем! Вы счастливчик — сегодня вы выиграли денежный приз 🥳  Надеемся, этот подарок будет долго вас радовать!  Для того, чтобы получить приз:  Сделайте скриншот этого экрана  Выложите в инстаграм историю с отметкой @kto_takaya @millidge.cosmetics С вами свяжется менеджер для уточнения адреса доставки приза',
        link: null,
        cardImage: '/Novyi-proekt-9.png',
        productId: '1000',
        startNumber: 9999999,
        step: 1000000,
        hardNumber: null
    },
    {
        id: 7,
        title: 'iPhone',
        type: 'hard',
        imageUrl: 'https://1790009369.rsc.cdn77.org/images/iphone-12-1-300x300.png',
        desc: 'Поздравляем! Вы счастливчик — сегодня вы стали обладателем iPhone 🥳  Надеемся, этот подарок будет долго вас радовать!  Для того, чтобы получить приз:  Сделайте скриншот этого экрана  Выложите в инстаграм историю с отметкой @kto_takaya @millidge.cosmetics С вами свяжется менеджер для уточнения адреса доставки приза',
        link: null,
        cardImage: '/Novyi-proekt-12.png',
        productId: 'iphone',
        startNumber: 9900000,
        step: 10000000,
        hardNumber: null
    }
];

export const winnerPrizes = () => {
    const numbers = prizes.map((priz) => ({
        ...priz,
        numbers_win: Array.apply(0, Array(5000)).map((_, idx) => priz.startNumber + priz.step * idx)
    }));
    return Array.apply(0, Array(30000)).map((_, idx) => {
        let win = {};
        numbers.forEach((item) => {
            if (item.hardNumber !== null && item.hardNumber === idx + 1) {
                win = item;
            } else if (item.numbers_win.includes(idx + 1)) {
                win = item;
            }
        });
        return win;
    });
};
