import { Breadcrumbs, Typography } from '@mui/material';
import Header from 'components/header';
import React from 'react';
import { Link as LinkRout } from 'react-router-dom';

const Faq = () => {
    return (
        <div>
            <Header />
            <div className="wrapper owerflow">
                <section className="section faq">
                    <div className="container">
                        <Breadcrumbs className="breadcrumbs" aria-label="breadcrumb">
                            <LinkRout underline="hover" color="inherit" to="/">
                                Главная
                            </LinkRout>
                            <Typography color="text.primary">Частые вопросы</Typography>
                        </Breadcrumbs>
                        <h1 className="page_title">Частые вопросы </h1>
                        <div className="faq_box">
                            <div className="faq_col">
                                <div data-hover="false" data-delay="0" className="faq_drop w-dropdown">
                                    <div className="faq_drop-toggle w-dropdown-toggle">
                                        <div>
                                            Можно ли использовать обёртывания при беременности и грудном вскармливании? Какие существуют
                                            противопоказания для обёртываний?{' '}
                                        </div>
                                        <div className="faq_btn" />
                                    </div>
                                    <nav className="dropdown-list w-dropdown-list">
                                        <div className="faq_desc">
                                            Термокрем Chili Power можно использовать при беременности и грудном вскармливании однако делать
                                            это следует с осторожностью. Дело в том, что при беременности индивидуальная чувствительность к
                                            компонентам может быть выше за счет чего существует риск чрезмерного нагрева тканей!{' '}
                                        </div>
                                    </nav>
                                </div>
                                <div data-hover="false" data-delay="0" className="faq_drop w-dropdown">
                                    <div className="faq_drop-toggle w-dropdown-toggle">
                                        <div>Как часто можно проводить скрабирование с средствами Cococnut Care и Guarana Magic? </div>
                                        <div className="faq_btn" />
                                    </div>
                                    <nav className="dropdown-list w-dropdown-list">
                                        <div className="faq_desc">
                                            Мы не рекомендуем применять скрабы чаще 2-раз в неделю для жирной кожи и 1-го раза в неделю для
                                            сухой и чувствительной кожи!{' '}
                                        </div>
                                    </nav>
                                </div>
                                <div data-hover="false" data-delay="0" className="faq_drop w-dropdown">
                                    <div className="faq_drop-toggle w-dropdown-toggle">
                                        <div>Скрабы подходят для сухого и влажного скрабирования? </div>
                                        <div className="faq_btn" />
                                    </div>
                                    <nav className="dropdown-list w-dropdown-list">
                                        <div className="faq_desc">
                                            Все наши скрабы подходят как для сухого, так и для влажного скрабирования{' '}
                                        </div>
                                    </nav>
                                </div>
                            </div>
                            <div className="faq_col">
                                <div data-hover="false" data-delay="0" className="faq_drop w-dropdown">
                                    <div className="faq_drop-toggle w-dropdown-toggle">
                                        <div>
                                            Какие средства Millidge можно, а какие нельзя применять при варикозном расширении вен и болезни
                                            сосудов?{' '}
                                        </div>
                                        <div className="faq_btn" />
                                    </div>
                                    <nav className="dropdown-list w-dropdown-list">
                                        <div className="faq_desc">
                                            При варикозном расширении вен и болезнях сосудов противопоказано использовать: <br />
                                            - Chili Power - разогревающий крем с роликом <br />
                                            - Банку для вакуумного массажа
                                            <br />
                                            Все остальные продукты могут использоваться свободно{' '}
                                        </div>
                                    </nav>
                                </div>
                                <div data-hover="false" data-delay="0" className="faq_drop w-dropdown">
                                    <div className="faq_drop-toggle w-dropdown-toggle">
                                        <div>Какие продукты нужно использовать совместно, а какие нельзя совмещать? </div>
                                        <div className="faq_btn" />
                                    </div>
                                    <nav className="dropdown-list w-dropdown-list">
                                        <div className="faq_desc">
                                            Вся продукция Millidge совместима между собой, вы можете комбинировать средства для достижения
                                            лучшего эффекта, например: использовать скраб, а затем проводить массаж вакуумной банкой по
                                            маслу Ginger Power. Или чередовать массаж вакуумной банкой Ginger Power и роликовый массаж Chili
                                            Magic{' '}
                                        </div>
                                    </nav>
                                </div>
                                <div data-hover="false" data-delay="0" className="faq_drop w-dropdown">
                                    <div className="faq_drop-toggle w-dropdown-toggle">
                                        <div>Как часто можно выполнять самомассаж на линейках Chili Magic и Ginger Power? </div>
                                        <div className="faq_btn" />
                                    </div>
                                    <nav className="dropdown-list w-dropdown-list">
                                        <div className="faq_desc">
                                            Мы рекомендуем начинать с трех массажей в неделю продолжительностью 5-7 минут и постепенно
                                            наращивать частоту и длительность массажа до 5 раз в неделю и 10-15 минут соответственно!{' '}
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Faq;
