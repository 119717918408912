import { Breadcrumbs, Typography } from '@mui/material';
import Header from 'components/header';
import React from 'react';
import { Link as LinkRout } from 'react-router-dom';

const RulesScretch = () => {
    return (
        <div>
            <Header />
            <div className="wrapper owerflow">
                <section className="section  faq">
                    <div className="container">
                        <Breadcrumbs className="breadcrumbs" aria-label="breadcrumb">
                            <LinkRout underline="hover" color="inherit" to="/">
                                Главная
                            </LinkRout>
                            <Typography color="text.primary">Правила проведения акции &#171;Скретч Карта&#187;</Typography>
                        </Breadcrumbs>
                        <h1 className="page_title">Правила проведения акции &#171;Скретч Карта&#187; </h1>
                        <div className="faq_box">
                            <div className="content_box w-richtext">
                                <p>
                                    <b>Правила проведения и условия участия в рекламной акции</b>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <b>«Скретч Карта»</b>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Настоящие Правила определяют порядок, условия, место и сроки проведения Рекламной акции «
                                    </span>
                                    <span style={{ fontWeight: 400 }}>Скретч Карта</span>
                                    <span style={{ fontWeight: 400 }}>
                                        », размер призового фонда Рекламной акции, сроки, место и порядок получения призов. В случае
                                        приостановления или досрочного прекращения проведения Рекламной акции Организатор Акции обязан
                                        публично уведомить об этом действующих и потенциальных участников в порядке, установленном п.3.2.
                                        настоящих Правил.{' '}
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Принимая участие в Рекламной акции, Участники полностью соглашаются с настоящими правилами и
                                        принимают их (далее &#8212; «Правила»).
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <ol>
                                    <li>
                                        <b> Общие положения.</b>
                                    </li>
                                </ol>
                                <p>
                                    <span style={{ fontWeight: 400 }}>1.1. Рекламная акция под условным наименованием «</span>
                                    <span style={{ fontWeight: 400 }}>Скретч Карта</span>
                                    <span style={{ fontWeight: 400 }}>
                                        » (далее «Акция») направлена на продвижение товаров и услуг Индивидуального предпринимателя
                                        Тюменцева Алексея Сергеевича, повышение узнаваемости бренда Millidge, повышение продаж товаров под
                                        брендом Millidge.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        1.2. Акция не является лотереей, не содержит элемента риска, не требует обязательной регистрации или
                                        направления уведомления в соответствующие государственные органы и проводится в соответствии с
                                        настоящими условиями (далее &#8212; «Правила»). Плата за участие в Акции не взимается. Участие в
                                        Акции не является обязательным.
                                    </span>{' '}
                                    <span style={{ fontWeight: 400 }}>
                                        При проведении Розыгрыша не используются процедуры и алгоритмы, позволяющие предопределить результат
                                        проведения Розыгрыша до начала его проведения.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <b>1.3. Сведения об Организаторе Акции.</b>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>1.3.1. Организатором Акции является </span>
                                    <b>Индивидуальный предприниматель Тюменцев Алексей Сергеевич,</b>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Адрес: 630007, Россия, Новосибирская обл, г Новосибирск, пр-кт Красный, д 11, кв 45,
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>ИНН 540601979397,</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>ОГРН 321547600134421,</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>Адрес для претензий: </span>
                                    <a href="millidgecosmetic@gmail.com">
                                        <span style={{ fontWeight: 400 }}>millidgecosmetic@gmail.com</span>
                                    </a>
                                    <span style={{ fontWeight: 400 }}>, </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>Телефон +7 (995) 156-62-28.</span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Организатор Акции обеспечивает формирование призового фонда, указанного в разделе 4.1. Правил,
                                        обеспечивает коммуникацию с победителями, вручение призов Акции, указанных в разделе 4.2
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>1.3.2. </span>
                                    <span style={{ fontWeight: 400 }}>Соорганизатором Акции является </span>
                                    <b>Индивидуальный предприниматель Миллер Артемий Александрович </b>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Адрес: Московская область, г. Видное, ул. Завидная, д. 9, кв.302
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>ИНН 023302360939, </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>ОГРНИП 315028000116885 </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>e-mail: </span>
                                    <a href="mailto:Artemiy.miller.88@mail.ru">
                                        <span style={{ fontWeight: 400 }}>Artemiy.miller.88@mail.ru</span>
                                    </a>
                                    <span style={{ fontWeight: 400 }}> </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Соорганизатор Акции обеспечивает формирование призового фонда, указанного в разделе 4.2. Правил,
                                        обеспечивает коммуникацию с победителями, вручение призов Акции, указанных в разделе 4.2. Правил.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <b>1.4. Определения, содержащиеся в настоящих Правилах:</b>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <b>Участник Акции</b>
                                    <span style={{ fontWeight: 400 }}>
                                        {' '}
                                        &#8212; это любое физическое лицо, совершившее покупку на сайте Акции, удовлетворяющее следующим
                                        требованиям к Участнику:
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        &#8212; Участниками могут быть дееспособные физические лица старше 14 лет, постоянно проживающие на
                                        территории Российской Федерации (далее по тексту – «Участник»).
                                    </span>{' '}
                                    <span style={{ fontWeight: 400 }}>
                                        Участник Акции, не достигший возраста 18 лет, обязан предоставить Согласие родителей (законных
                                        представителей) на участие в Акции.
                                    </span>
                                    <span style={{ fontWeight: 400 }}>
                                        Граждане иностранных государств не приобретают статуса Участника Акции, независимо от совершения
                                        действий, необходимых для участия в Акции.
                                    </span>{' '}
                                    <span style={{ fontWeight: 400 }}>
                                        Призы Акции не доставляются на территорию иностранных государств.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        &#8212; Участниками не могут быть работники и члены их семей Организатора Акции, а также работники
                                        других юридических лиц и/или индивидуальных предпринимателей, причастных к организации и проведению
                                        Акции, и члены их семей.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <b>Приз</b>
                                    <span style={{ fontWeight: 400 }}>
                                        {' '}
                                        – ценный подарок, подлежащий вручению Победителю Акции, точное описание которого установлено в
                                        разделе 4 настоящих Правил.
                                    </span>
                                </p>
                                <p>
                                    <b>Скретч-карта</b>
                                    <span style={{ fontWeight: 400 }}>
                                        {' '}
                                        – виртуальная карта для проведения моментального розыгрыша призов Акции, при{' '}
                                    </span>
                                    <span style={{ fontWeight: 400 }}>активации </span>
                                    <span style={{ fontWeight: 400 }}>
                                        которой появляется надпись с указанием одного из Призов Акции, указанных в разделе 4 Правил.
                                    </span>
                                </p>
                                <p>
                                    <b>Победители Акции </b>
                                    <span style={{ fontWeight: 400 }}>
                                        – Участники, которым по результатам мгновенного розыгрыша призов с помощью скретч-карты выпал один
                                        из призов.
                                    </span>
                                </p>
                                <p>
                                    <b>Сайт</b>
                                    <span style={{ fontWeight: 400 }}> Акции (сайт)</span> <span style={{ fontWeight: 400 }} />
                                    <a href="https://millidge.site/">
                                        <span style={{ fontWeight: 400 }}>https://millidge.site/</span>
                                    </a>
                                    <span style={{ fontWeight: 400 }} />
                                </p>
                                <p>
                                    <b>Территория проведения Акции</b>
                                    <span style={{ fontWeight: 400 }}> – Российская Федерация. </span>
                                </p>
                                <p>&nbsp;</p>
                                <ol start="2">
                                    <li>
                                        <b> Условия проведения Акции:</b>
                                    </li>
                                </ol>
                                <p>&nbsp;</p>
                                <p>
                                    <i>
                                        <span style={{ fontWeight: 400 }}>Сроки проведения Акции:</span>
                                    </i>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        2.1. Общий срок проведения Акции – с 12ч 00 мин 21 марта 2022 года по 23 ч 59 мин 25 марта 2022 года
                                        (далее по тексту – «Период проведения Акции»). Все действия, предусмотренные настоящими Правилами,
                                        должны быть совершены и фиксируются Организатором по московскому времени.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        2.2. Общий период определения победителей Акции: с 00ч 00 мин 21 марта 2022 года по 23 ч 59 мин 25
                                        марта 2022 года:
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        2.3. Общий период вручения призов Акции: с 00ч 00 мин 21 марта 2022 года по 23 ч 59 мин 25 марта
                                        2022 года включительно. Время доставки приза в натуральной форме не включается в указанный срок.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <i>
                                        <span style={{ fontWeight: 400 }}>Условия участия в Акции:</span>
                                    </i>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>2.4. Для участия в Акции необходимо:</span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        В период с 11ч 00 мин 21 марта 2022 года по 23 ч 59 мин 25 марта 2022 года совершать покупки на
                                        сайте{' '}
                                    </span>
                                    <a href="https://millidge.site/">
                                        <span style={{ fontWeight: 400 }}>https://millidge.site/</span>
                                    </a>
                                    <span style={{ fontWeight: 400 }}> на любую сумму. </span>
                                    <span style={{ fontWeight: 400 }}>Количество покупок не ограничено.</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>После оплаты покупки </span>
                                    <span style={{ fontWeight: 400 }}>
                                        для участника предоставляется возможность выбрать одну из виртуальных скретч-карт{' '}
                                    </span>
                                    <span style={{ fontWeight: 400 }}>для проведения моментального розыгрыша призов.</span>
                                </p>
                                <p>
                                    <i>
                                        <span style={{ fontWeight: 400 }}>2.5. Порядок проведения розыгрыша.</span>
                                    </i>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        2.5.1. Участник выбирает скретч-карту из предложенных, совершает действия по{' '}
                                    </span>
                                    <span style={{ fontWeight: 400 }}>активации </span>
                                    <span style={{ fontWeight: 400 }}>
                                        виртуальной скретч-карты. Приз, право на получение которого предоставляется Участнику, указан в поле
                                        скретч-карты.{' '}
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        2.5.2. Розыгрыш проводится без применения специального оборудования. Предлагаемые Участнику
                                        скретч-карты отображаются в случайном порядке с помощью генератора, выбор скретч-карты Участник
                                        осуществляет самостоятельно.
                                    </span>
                                    <span style={{ fontWeight: 400 }} />
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>2.5.3. </span>
                                    <span style={{ fontWeight: 400 }}>
                                        Процедура по определению Победителей одинакова для всех призов и участников Акции.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        2.5.4. Победители информируются о выигрыше в момент активации скретч-карты, а также дополнительно по
                                        адресу электронной почты после активации скретч-карты.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        2.6. Факт выполнения условий участия в Акции Участником подразумевает, что он ознакомлен и согласен
                                        с настоящими Правилами и выражает свое согласие на обработку персональных данных в соответствии с
                                        настоящими Правилами.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        2.7. Организатор Акции в одностороннем порядке и без объяснения причин имеет право в любой момент
                                        исключить из числа Участников или Призеров лиц:
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        2.7.1. не предоставивших документы, информацию, сведения, установленные настоящими Правилами, для
                                        вручения Приза;
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        2.7.2. предоставивших о себе искажённую информацию или в отношении которых имеется подозрение о
                                        совершении мошеннических действий, целью которых является необоснованное получение Приза;
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>2.7.3. нарушивших иные положения настоящих Правил.</span>
                                </p>
                                <p>
                                    <i>
                                        <span style={{ fontWeight: 400 }}>Порядок получения Призов:</span>
                                    </i>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        2.8. Для получения призов в виде товаров бренда Millidge Победитель предоставляет Организатору
                                        следующую информацию:
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>&#8212; фамилию, имя,</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>&#8212; адрес доставки приза. </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Приз отправляется Организатором Победителю не позднее 3 рабочих дней с даты получения указанной
                                        информации.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        2.9. Для получения призов в виде обучающих курсов, оплаты пользования приложением для мобильных
                                        телефонов для правильного питания Победитель предоставляет Организатору следующую информацию:
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>&#8212; фамилию, имя,</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>&#8212; номер телефона,</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>&#8212; адрес электронной почты.</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Информация для получения приза направляется Победителю по указанным им каналам связи. Приз
                                        отправляется Организатором Победителю не позднее 3 рабочих дней с даты получения указанной
                                        информации.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        2.10. Для получения денежного приза Победитель предоставляет Организатору следующую информацию:
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>&#8212; фамилию, имя,</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        &#8212; реквизиты банковского счета/карты, оформленного на имя Победителя, для перевода платежа.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Приз отправляется Организатором Победителю не позднее 3 рабочих дней с даты получения указанной
                                        информации.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        2.11. Для получения Приза в виде телевизора или смартфона Победитель обязуется представить
                                        Организатору следующую обязательную информацию и документы по адресу электронной почты Организатора:
                                        ФИО, серию и номер паспорта, дату его выдачи, гражданство, ИНН и место регистрации, номер телефона,
                                        адрес электронной почты, а также подписанный акт приема-передачи приза.
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Приз отправляется Победителю Организатором за его счет не позднее 3 рабочих дней с даты получения
                                        указанной информации{' '}
                                    </span>
                                    <span style={{ fontWeight: 400 }}>способом, согласованным с Победителем</span>
                                    <span style={{ fontWeight: 400 }}>.</span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        С момента передачи Приза транспортной компании/почтовой службе Победитель несет риск случайной
                                        гибели или порчи этого Приза.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <ol start="3">
                                    <li>
                                        <b> Способы информирования об Акции:</b>
                                    </li>
                                </ol>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>3.1.</span>{' '}
                                    <span style={{ fontWeight: 400 }}>
                                        Полную информацию об Организаторе, правилах проведения Акции, порядке определения победителей Акции,
                                        количестве призов по результатам Акции, сроках, месте и порядке их получения можно получить:
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <ul>
                                    <li style={{ fontWeight: 400 }} aria-level="1">
                                        <span style={{ fontWeight: 400 }}>В сети Интернет по адресу </span>
                                        <a href="https://millidge.site/">
                                            <span style={{ fontWeight: 400 }}>https://millidge.site/</span>
                                        </a>{' '}
                                        <span style={{ fontWeight: 400 }}>;</span>
                                    </li>
                                </ul>
                                <p>&nbsp;</p>
                                <ul>
                                    <li style={{ fontWeight: 400 }} aria-level="1">
                                        <span style={{ fontWeight: 400 }}>В аккаунте Instagram Организатора Акции: @</span>
                                        <span style={{ fontWeight: 400 }}>Millidge.winers</span>
                                        <span style={{ fontWeight: 400 }}>. </span>
                                    </li>
                                </ul>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>3.2.</span>{' '}
                                    <span style={{ fontWeight: 400 }}>
                                        Об изменениях условий проведения Акции Участники Акции информируются посредством размещения
                                        информации на сайте Акции и в аккаунте Организатора Акции.{' '}
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <ol start="4">
                                    <li>
                                        <b> Призовой фонд Акции.</b>
                                    </li>
                                </ol>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        4.1. Призовой фонд Акции, формируемый за счет средств Организатора Акции, включает в себя следующие
                                        Призы:
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <ul>
                                    <li style={{ fontWeight: 400 }} aria-level="1">
                                        <span style={{ fontWeight: 400 }}>
                                            Товары бренда Millidge, стоимость любого приза не превышает 4 000 (Четыре тысячи) рублей:
                                        </span>
                                    </li>
                                </ul>
                                <p>&nbsp;</p>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <span style={{ fontWeight: 400 }}>Наименование приза</span>
                                            </td>
                                            <td>
                                                <span style={{ fontWeight: 400 }}>Количество, шт.</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span style={{ fontWeight: 400 }}>Скраб для губ</span>
                                            </td>
                                            <td>
                                                <span style={{ fontWeight: 400 }}>5000</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span style={{ fontWeight: 400 }}>Бальзам для губ</span>
                                            </td>
                                            <td>
                                                <span style={{ fontWeight: 400 }}>5000</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p>&nbsp;</p>
                                <ul>
                                    <li style={{ fontWeight: 400 }} aria-level="1">
                                        <span style={{ fontWeight: 400 }}>
                                            Смартфоны марки Iphone 12 с объемом памяти 64 GB – 5 штук за весь период Акции.
                                        </span>
                                    </li>
                                </ul>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Стоимость одного смартфона составляет 80 000 (Восемьдесят тысяч) рублей. Приз состоит из:
                                    </span>
                                </p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>&#8212; смартфона стоимостью 80 000 (Восемьдесят тысяч) рублей,</span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        ПРИМЕЧАНИЕ: Обязательства Организатора относительно качества Призов ограничены гарантиями,
                                        предоставленными его изготовителем. Выбор цвета и модели приза, его технических параметров и иные
                                        организационные моменты определяются по усмотрению Организатора и не зависят от пожеланий,
                                        требований или потребностей победителя.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Организатор Акции не несет ответственности за любой ущерб, нанесенный как здоровью (жизни)
                                        обладателю Приза, так и имуществу, здоровью или жизни третьих лиц в течение всего срока эксплуатации
                                        Призов.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <ul>
                                    <li style={{ fontWeight: 400 }} aria-level="1">
                                        <span style={{ fontWeight: 400 }}>
                                            Оплата пользования приложением для мобильных телефонов для правильного питания «Астрологический
                                            прогноз»
                                        </span>
                                        <span style={{ fontWeight: 400 }}>
                                            {' '}
                                            &#8212; 5000 штук за весь период Акции. Стоимость приза не превышает 4 000 (Четыре тысячи)
                                            рублей.
                                        </span>
                                    </li>
                                </ul>
                                <p>&nbsp;</p>
                                <ul>
                                    <li style={{ fontWeight: 400 }} aria-level="1">
                                        <span style={{ fontWeight: 400 }}>
                                            Денежные призы по 1000 (Одна тысяча) рублей – 50 штук за весь период Акции
                                        </span>
                                    </li>
                                </ul>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        4.2. Призовой фонд Акции, формируемый за счет средств Соорганизатора Акции, включает в себя
                                        следующие Призы:
                                    </span>
                                </p>
                                <ul>
                                    <li style={{ fontWeight: 400 }} aria-level="1">
                                        <span style={{ fontWeight: 400 }}>
                                            Обучающий курс «Инвестор плюс» &#8212; 5000 шт. за весь период Акции,
                                        </span>
                                    </li>
                                    <li style={{ fontWeight: 400 }} aria-level="1">
                                        <span style={{ fontWeight: 400 }}>
                                            Обучающий гайд «5 причин отеков и секретные способы решения» &#8212; 5000 шт. за весь период
                                            Акции.
                                        </span>
                                    </li>
                                    <li aria-level="1">Астрологический прогноз 5000 шт за весь период акции</li>
                                </ul>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        Стоимость любого обучающего курса не превышает 4 000 (Четыре тысячи) рублей.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        4.3. Выплата Участнику денежного эквивалента стоимости Приза, возврат и обмен Приза, замена Приза по
                                        просьбе участников и победителя Организатором не производится.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        4.4. Победитель рекламной акции самостоятельно уплачивает налоги на доходы физических лиц в связи с
                                        получением подарка.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        4.5. Организатор имеет право провести интервьюирование и сделать фото- и видеосессию Победителей
                                        Акции с полученным Призом и разместить эту информацию в интернете и в социальных сетях или иных
                                        публичных ресурсах.
                                    </span>{' '}
                                    <span style={{ fontWeight: 400 }}>
                                        Участник подтверждает свое согласие на участие в рекламных интервью об участии в Акции, а также на
                                        осуществление Организатором и/или третьими лицами по заданию Организатора фото- и видеосъемки
                                        Победителя, а также на использование созданных фото- и видеозаписей с Победителем без получения
                                        дополнительного согласия на такое использование и без уплаты какого-либо вознаграждения за такое
                                        использование, в том числе в средствах массовой информации, в частности, в рекламных целях, с правом
                                        передачи права использования указанных фото- и видеозаписей с Победителем Акции третьим лицам.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        4.6. Право на получение приза не может быть уступлено либо иным образом передано Участником Акции
                                        другому лицу.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <ol start="5">
                                    <li>
                                        <b> Условия обработки Персональных Данных:</b>
                                    </li>
                                </ol>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>5.1.</span>{' '}
                                    <span style={{ fontWeight: 400 }}>
                                        Участники понимают и соглашаются с тем, что персональные данные Участника указанные ими для участия
                                        в Акции, будут обрабатываться Организатором и Соорганизатором Акции всеми необходимыми способами,
                                        включая сбор, хранение, накопление, систематизацию, уточнение (обновление, изменение),
                                        использование, передачу, обезличивание, блокирование, удаление и уничтожение в целях проведения
                                        Акции (включая, но не ограничиваясь, получение призов, их вручение и предоставление информации о
                                        доходах физического лица, полученных в рамках настоящей Акции в налоговые органы) и дают согласие на
                                        такую обработку при принятии настоящих Правил. Срок обработки персональных данных &#8212; на весь
                                        срок проведения Акции и выдачи призов,
                                    </span>{' '}
                                    <span style={{ fontWeight: 400 }}>
                                        за исключением случаев, когда законодательством предусмотрено обязательное хранение персональной
                                        информации в течение определенного законом срока.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>5.2.</span>{' '}
                                    <span style={{ fontWeight: 400 }}>
                                        Персональные данные собираются в целях возможности получения Участником Призов и сообщения
                                        Участникам о выигрыше, а также осуществления любых контактов с Участником в отношении настоящей
                                        Акции, направления информации, относящейся к целям проведения Акции.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>5.3.</span>{' '}
                                    <span style={{ fontWeight: 400 }}>
                                        Направление своих персональных данных для участия в Акции означает также предоставление Участником
                                        Организатору согласия на обработку персональных данных в целях проведения Акции.{' '}
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>5.4.</span>{' '}
                                    <span style={{ fontWeight: 400 }}>Оператором персональных данных является Организатор акции.</span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>5.5.</span>{' '}
                                    <span style={{ fontWeight: 400 }}>
                                        Обработка Персональных данных может осуществляться Организатором лично, а также уполномоченными ими
                                        лицами, с применением автоматизированных средств обработки данных, так и без них.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>5.6.</span>{' '}
                                    <span style={{ fontWeight: 400 }}>
                                        Обработка персональных данных Пользователя производится Оператором с использованием баз данных на
                                        территории Российской Федерации.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>5.7.</span>{' '}
                                    <span style={{ fontWeight: 400 }}>
                                        Персональные данные могут передаваться от операторов персональных данных третьим лицам, привлекаемым
                                        операторами на основании соответствующих договоров при условии обеспечения такими третьими лицами
                                        конфиденциальности обработки персональных данных.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>5.8.</span>{' '}
                                    <span style={{ fontWeight: 400 }}>
                                        Субъект персональных данных вправе отозвать свое согласие, отправив электронное письмо Организатору
                                        на адрес электронной почты.{' '}
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        5.9. Отзыв Участником согласия на обработку персональных данных до вручения Приза автоматически
                                        влечет за собой выход соответствующего Участника из участия в Акции и делает невозможным получение
                                        им Приза.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <ol start="6">
                                    <li>
                                        <span style={{ fontWeight: 400 }}>Прочие условия Акции:</span>
                                    </li>
                                </ol>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>6.1.</span>{' '}
                                    <span style={{ fontWeight: 400 }}>
                                        Организатор имеет право вносить изменения в настоящие Правила, при этом информация об изменениях
                                        Правил размещается на Сайте Акции.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>6.2.</span>{' '}
                                    <span style={{ fontWeight: 400 }}>
                                        Возврат товаров, приобретённых в рамках Акции, осуществляется в соответствии с законодательством РФ
                                        и условиями публичной оферты интернет-магазина.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        6.3. Для возврата денежных средств необходимо отправить{' '}
                                        <a href="https://docs.google.com/document/d/1QEekzXEb8ZAl45tRmIpV7CheMzMpQOyXvVECkfCc1bA/edit?usp=sharing">
                                            заявление
                                        </a>
                                        Организатору в письменной форме по адресу: 115432, Москва, ул.Сайкина, д.19.{' '}
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <span style={{ fontWeight: 400 }}>
                                        6.4. Во всем, что не предусмотрено настоящими Правилами, Организатор и Участники Акции
                                        руководствуются действующим законодательством Российской Федерации.
                                    </span>
                                </p>
                                <p>&nbsp;</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default RulesScretch;
