import { Breadcrumbs, Typography } from '@mui/material';
import Effect from 'components/effect';
import Ingredients from 'components/ingredients';
import Header from 'components/header';
import React from 'react';
import { useParams } from 'react-router';
import ProductApplication from 'components/product-application';
import AppBlock from 'components/app-block';
import ProductInfoVertical from 'components/product-info-vertical';
import { Link as LinkRout } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProductFull = () => {
    const { slug } = useParams();
    const products = useSelector((state) => state.state.products);

    const product = products.find((item) => +item.id === +slug);

    return (
        <div>
            <Header />
            <div className="wrapper owerflow">
                <section className="section">
                    <div className="container">
                        <Breadcrumbs className="breadcrumbs" aria-label="breadcrumb">
                            <LinkRout underline="hover" color="inherit" to="/">
                                Главная
                            </LinkRout>
                            <Typography color="text.primary">Продукт</Typography>
                            <Typography color="text.primary">{product.title}</Typography>
                        </Breadcrumbs>
                    </div>
                </section>
                <ProductInfoVertical {...product} />
                <Effect {...product} />
                <Ingredients {...product} />
                <ProductApplication {...product} />
                <AppBlock />
            </div>
        </div>
    );
};

export default ProductFull;
