import { Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Footer from 'components/footer';
import Menu from 'components/menu';
import PositionedSnackbar from 'components/snackbar/snackbar';
import React from 'react';
import { Outlet } from 'react-router-dom';
import FmdBadIcon from '@mui/icons-material/FmdBad';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    borderRadius: '16px',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
};

const MainLayout = () => {
    const [open, setOpen] = React.useState(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Outlet />
            <Menu />
            <Footer />
            <PositionedSnackbar />
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FmdBadIcon fontSize="large" />
                        <Typography id="modal-modal-title" variant="h4" component="h2">
                            Внимание!!!
                        </Typography>
                    </div>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        В данный момент заказы принимаются только через Wildberries и OZON
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Приносим свои извинения за причиненные неудобства
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
};

export default MainLayout;
