// action - current reducer
export const CURRENT_SET_MENU = '@current/SET_MENU';
export const CURRENT_MENU_TOGGLE = '@current/MENU_TOGGLE';
export const ADD_TO_CART = '@current/ADD_TO_CART';
export const REPLACE_CART = '@current/REPLACE_CART';
export const MINUS_CART_PRODUCT = '@current/MINUS_CART_PRODUCT';
export const DEL_CART_PRODUCT = '@current/DEL_CART_PRODUCT';

// actions

export const addTocCard = (state, action) => {
    if (state.cart.find((x) => x.id === action.id)) {
        const item = state.cart.find((x) => x.id === action.id);
        return [...state.cart.filter((x) => x.id !== action.id), { ...item, count: item.count + 1 }].sort((a, b) => a.id - b.id);
    }
    return [...state.cart, { ...action, count: 1 }].sort((a, b) => a.id - b.id);
};

export const minusCartProduct = (state, action) => {
    const item = state.cart.find((x) => x.id === action);
    if (item && item.count > 1) {
        return [...state.cart.filter((x) => x.id !== action), { ...item, count: item.count - 1 }].sort((a, b) => a.id - b.id);
    }
    return [...state.cart.filter((x) => x.id !== action)].sort((a, b) => a.id - b.id);
};
