import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import { useSelector } from 'react-redux';

export default function PositionedSnackbar() {
    const cart = useSelector((state) => state.state.cart);
    const [cartItemsCount, setCartItemsCount] = React.useState(0);
    const [cartItemsCountLocal, setCartItemsCountLocal] = React.useState(0);
    const [state, setState] = React.useState({
        open: false
    });

    React.useEffect(() => {
        if (cartItemsCount > cartItemsCountLocal) {
            setCartItemsCountLocal(cartItemsCount);
            setState({ open: true });
        }
    }, [cartItemsCount, cartItemsCountLocal]);

    React.useEffect(() => {
        if (cart && cart.length > 0) {
            let count = 0;
            cart.forEach((element) => {
                count += element.count;
            });
            setCartItemsCount(count);
        }
    }, [cart]);

    const { open } = state;

    const handleClose = () => {
        setState({ open: false });
    };

    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
            <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                Товар успешно добавлен в корзину
            </Alert>
        </Snackbar>
    );
}
