import React from 'react';
import { useLocation } from 'react-router-dom';
import Helmet from 'react-helmet/lib/Helmet';

const Payments = () => {
    const location = useLocation();
    const { amount, userEmail, orderId } = location.state;
    const CP_PUBLIC_ID = process.env.REACT_APP_CP_PUBLIC_ID;
    console.log(CP_PUBLIC_ID);
    /* const CP_PUBLIC_ID = 'pk_adaf9d7cd75bfec5b9540321cb49c'; */

    return (
        <div>
            <Helmet>
                <script>
                    {`
                    pay = function () {
                        var widget = new cp.CloudPayments();
                            widget.pay('charge', // или 'charge'
                                { //options
                                    publicId: '${CP_PUBLIC_ID}', //id из личного кабинета
                                    description: 'Оплата ваших товаров', //назначение
                                    amount: ${amount}, //сумма
                                    currency: 'RUB', //валюта
                                    invoiceId: '${orderId}', //номер заказа  (необязательно)
                                    email: '${userEmail}', //email плательщика (необязательно)
                                    skin: "mini", //дизайн виджета (необязательно)
                                },
                                {
                                    onSuccess: function (options) { 
                                        window.location.href = '/prizes/${orderId}';
                                    },
                                    onFail: function (reason, options) { // fail
                                        window.location.href = '/payments/failed';
                                    },
                                    onComplete: function (paymentResult, options) { //Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
                                        //например вызов вашей аналитики Facebook Pixel
                                    }
                                }
                            )
                        };
                        $(function () {
                            pay();
                        });
                `}
                </script>
            </Helmet>
        </div>
    );
};

export default Payments;
