import config from 'config';
import React from 'react';

const AppBlock = () => {
    return (
        <section className="section app">
            <img
                src="data:image/svg+xml,%3Csvg%20xmlns='https://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                loading="lazy"
                alt=""
                className="app_bg_light"
                data-lazy-src={`${config.storeUrl}/619ff18de8de32983801e66d_Light20Setup202.png`}
            />
            <div className="container for_app">
                <div className="app_side">
                    <h2>СКАЧАЙТЕ НАШЕ ПРИЛОЖЕНИЕ </h2>
                    <div className="app_desc_items">
                        <div className="app_desc_item">
                            <img
                                src="data:image/svg+xml,%3Csvg%20xmlns='https://www.w3.org/2000/svg'%20viewBox='0%200%20123%200'%3E%3C/svg%3E"
                                loading="lazy"
                                width="123"
                                alt=""
                                className="liza_img dhide"
                                data-lazy-src={`${config.storeUrl}/61b07cdcbf7c56feb3fd0749_Group20110.png`}
                            />
                            <img
                                src="data:image/svg+xml,%3Csvg%20xmlns='https://www.w3.org/2000/svg'%20viewBox='0%200%20123%200'%3E%3C/svg%3E"
                                loading="lazy"
                                width="123"
                                alt=""
                                className="liza_img mhide"
                                data-lazy-src={`${config.storeUrl}/61ae7acf9889ec357d658677_D184D0BED182D0BAD0B0-D0BBD0B8D0B7D18B-D0BDD0B0-D0B1D0B5D0BBD0BED0BC-D184D0BED0BDD0B5201.png`}
                            />
                            <div className="app_desc for_liza">
                                И смотрите обучающие видео лично от Лизы Миллер о том,{' '}
                                <span className="medium">
                                    как правильно пользоваться косметикой Millidge для достижения лучшего результата
                                </span>{' '}
                            </div>
                            <div className="app_line" />
                        </div>
                        <div className="app_desc_item">
                            <img
                                src="data:image/svg+xml,%3Csvg%20xmlns='https://www.w3.org/2000/svg'%20viewBox='0%200%20123%200'%3E%3C/svg%3E"
                                loading="lazy"
                                width="123"
                                alt=""
                                className="app_item_ico"
                                data-lazy-src={`${config.storeUrl}/61ae7ac1f9c5bfb9b6a0a9f2_Group20119.png`}
                            />
                            <div className="item-desc_box">
                                <div className="item" />
                                <div className="app_desc">Видео по каждому товару </div>
                            </div>
                            <div className="app_line" />
                        </div>
                        <div className="app_desc_item">
                            <img
                                src="data:image/svg+xml,%3Csvg%20xmlns='https://www.w3.org/2000/svg'%20viewBox='0%200%20123%200'%3E%3C/svg%3E"
                                loading="lazy"
                                width="123"
                                alt=""
                                className="app_item_ico2"
                                data-lazy-src={`${config.storeUrl}/61ae7ac1f9c5bf1e70a0a9f3_Group20120.png`}
                            />
                            <div className="item-desc_box">
                                <div className="item" />
                                <div className="app_desc">Индивидуальный календарь процедур </div>
                            </div>
                            <div className="app_line" />
                        </div>
                    </div>
                    <div className="app_items">
                        <a
                            rel="noreferrer"
                            href="https://play.google.com/store/apps/details?id=com.millidge&hl=ru&gl=US"
                            target="_blank"
                            className="app_link w-inline-block"
                        >
                            <img
                                src="data:image/svg+xml,%3Csvg%20xmlns='https://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                loading="lazy"
                                alt=""
                                className="app_ico"
                                title=""
                                data-lazy-src={`${config.storeUrl}/619ff18dd3d3cd65e6df290b_Mobile20app20store20badge.svg`}
                            />
                        </a>
                        <a
                            rel="noreferrer"
                            href="https://apps.apple.com/ru/app/millidge/id1599331863"
                            target="_blank"
                            className="app_link w-inline-block"
                        >
                            <img
                                src="data:image/svg+xml,%3Csvg%20xmlns='https://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                                loading="lazy"
                                alt=""
                                className="app_ico"
                                title=""
                                data-lazy-src={`${config.storeUrl}/619ff18d9e8db8f20640799a_Mobile20app20store20badge2.svg`}
                            />
                        </a>
                    </div>
                </div>
                <img
                    src="data:image/svg+xml,%3Csvg%20xmlns='https://www.w3.org/2000/svg'%20viewBox='0%200%20879%200'%3E%3C/svg%3E"
                    loading="lazy"
                    width="879.5"
                    alt=""
                    className="app_hand"
                    title=""
                    data-lazy-src={`${config.storeUrl}/Prilozhene-1.png`}
                />
                <div className="app_label">
                    <img
                        src="data:image/svg+xml,%3Csvg%20xmlns='https://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                        loading="lazy"
                        alt=""
                        className="app_label_ico"
                        data-lazy-src={`${config.storeUrl}/619ff18c0d6060de751113ed_Group2044.svg`}
                    />
                    <div className="app_percent">100%</div>
                    <div className="app_percent_desc">бесплатно</div>
                </div>
            </div>
        </section>
    );
};

export default AppBlock;
