import {
    Breadcrumbs,
    Button,
    Card,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    Typography
} from '@mui/material';
import { useTheme } from '@mui/styles';
import { Box } from '@mui/system';
import Header from 'components/header';
import { Formik } from 'formik';
import useScriptRef from 'hooks/useScriptRef';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, Link, Link as LinkRout } from 'react-router-dom';
import { deliveryTypesParse } from 'utils/delivery-parse';
import { useAsyncFn } from 'react-use';
import * as Yup from 'yup';
import { ordersAPI } from 'api';
import LoadingOverlay from 'react-loading-overlay';

const Checkout = () => {
    const navigation = useNavigate();
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const cart = useSelector((state) => state.state.cart);
    const [checkedPrivacy, setCheckedPrivacy] = useState(false);
    const [checkedOferta, setCheckedOferta] = useState(false);
    const [deliveryTypeList, setDeliveryTypeList] = useState([]);
    const [deliveryType, setDeliveryType] = useState({
        title: 'Курьерской службой',
        type: 'COURIER',
        deliveryCost: 490
    });
    const [data, setData] = useState(null);

    const [currentCountry, setCurrentCountry] = useState('Russia');

    const [cartItemsCost, setCartItemsCost] = useState(0);

    useEffect(() => {
        if (cart) {
            let cost = 0;
            cart.forEach((element) => {
                cost += element.cost * element.count;
            });
            setCartItemsCost(cost);
        }
    }, [cart]);

    useEffect(() => {
        setDeliveryTypeList(deliveryTypesParse(currentCountry));
    }, [data]);

    const [state, createOrderFn] = useAsyncFn(
        async (values) => {
            if (deliveryType == null || checkedPrivacy === false) {
                return;
            }
            try {
                const response = await ordersAPI.createOrder({
                    name: values.billing_first_name,
                    surname: values.billing_last_name,
                    patronymic: '',
                    phoneNumber: values.billing_phone,
                    email: values.billing_email,
                    index: values.billing_postcode.toString(),
                    country: values.billing_country,
                    region: '',
                    city: values.billing_city,
                    street: values.billing_address_1,
                    building: '',
                    flat: '',
                    deliveryCost: cartItemsCost > 3000 ? 0 : deliveryType.deliveryCost,
                    deliveryType: deliveryType.type,
                    items: cart.map((item) => ({
                        productName: item.name,
                        quantity: item.count,
                        initialPrice: item.cost
                    }))
                });
                if (response.status === 201) {
                    const data = await response.json();
                    navigation('../payments/', {
                        replace: true,
                        state: {
                            amount: data.amount,
                            userEmail: values.billing_email,
                            orderId: data.retailOrderId,
                            type: 'cloudPayments'
                        }
                    });
                }
                // eslint-disable-next-line consistent-return
                return response;
            } catch (err) {
                Object.entries(err).forEach(([key, value]) => {
                    console.log(key, value);
                });
            }
        },
        [deliveryType, checkedPrivacy]
    );
    return (
        <LoadingOverlay active={state.loading} spinner text="Создаем заказ. Подождите">
            <div>
                <Header />
                <div className="wrapper owerflow">
                    <section className="section">
                        <div className="container">
                            <Breadcrumbs className="breadcrumbs" aria-label="breadcrumb">
                                <LinkRout underline="hover" color="inherit" to="/">
                                    Главная
                                </LinkRout>
                                <Typography color="text.primary">Оформление заказа</Typography>
                            </Breadcrumbs>
                            {cart.length > 0 ? (
                                <div data-content="cart_content">
                                    <h1 className="page_title centerred">Оформление заказа</h1>
                                    <div data-action="checkout">
                                        <div>
                                            <div className="checkout_nav">
                                                <a href="/cart/" className="checkout_nav_item for_cart w-inline-block">
                                                    <div className="checkout_step_box cart" />
                                                    <div>Корзина</div>
                                                    <div className="dotted_line" />
                                                </a>
                                                <div className="checkout_nav_item">
                                                    <div className="checkout_step_box pay active" />
                                                    <div>Оформление заказа</div>
                                                </div>
                                            </div>
                                            <div className="checkout_form w-form">
                                                <div className="woocommerce">
                                                    <div className="woocommerce-notices-wrapper" />
                                                    <div className="woocommerce-notices-wrapper" />
                                                    <div className="flexible-shipping-notice-container" />
                                                    {/* //! start */}
                                                    <div className="checkout woocommerce-checkout">
                                                        <h3 id="order_review_heading">Оплата и доставка</h3>
                                                        <Box>
                                                            <Formik
                                                                initialValues={{
                                                                    billing_first_name: '',
                                                                    billing_last_name: '',
                                                                    billing_country: 'Russia',
                                                                    billing_city: '',
                                                                    billing_address_1: '',
                                                                    billing_postcode: '',
                                                                    billing_phone: '',
                                                                    billing_email: ''
                                                                }}
                                                                validationSchema={Yup.object().shape({
                                                                    billing_first_name: Yup.string()
                                                                        .nullable()
                                                                        .max(20)
                                                                        .required('Имя является обязательным полем'),
                                                                    billing_last_name: Yup.string()
                                                                        .nullable()
                                                                        .max(20)
                                                                        .required('Фамилия является обязательным полем'),
                                                                    billing_country: Yup.string()
                                                                        .nullable()
                                                                        .max(20)
                                                                        .required('Страна является обязательным полем'),
                                                                    billing_city: Yup.string()
                                                                        .nullable()
                                                                        .max(20)
                                                                        .required('Город является обязательным полем'),
                                                                    billing_address_1: Yup.string()
                                                                        .max(150)
                                                                        .nullable()
                                                                        .required('Адрес является обязательным полем'),
                                                                    billing_postcode: Yup.string()
                                                                        .nullable()
                                                                        .max(10)
                                                                        .required('Индекс является обязательным полем'),
                                                                    billing_phone: Yup.string()
                                                                        .nullable()
                                                                        .max(20)
                                                                        .required('Телефон является обязательным полем'),
                                                                    billing_email: Yup.string()
                                                                        .nullable()
                                                                        .email('Ввеите корректный email')
                                                                        .max(50)
                                                                        .required('Email является обязательным полем')
                                                                })}
                                                                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                                                                    setData(values);
                                                                    createOrderFn(values);
                                                                    try {
                                                                        if (scriptedRef.current) {
                                                                            setStatus({ success: true });
                                                                            setSubmitting(false);
                                                                        }
                                                                    } catch (err) {
                                                                        console.error(err);
                                                                        if (scriptedRef.current) {
                                                                            setStatus({ success: false });
                                                                            setErrors({ submit: err.message });
                                                                            setSubmitting(false);
                                                                        }
                                                                    }
                                                                }}
                                                            >
                                                                {({
                                                                    errors,
                                                                    handleBlur,
                                                                    handleChange,
                                                                    handleSubmit,
                                                                    isSubmitting,
                                                                    touched,
                                                                    values
                                                                }) => (
                                                                    <form noValidate onSubmit={handleSubmit}>
                                                                        <Grid spacing={1} container columns={12}>
                                                                            <Grid item sm={6}>
                                                                                <FormControl
                                                                                    fullWidth
                                                                                    error={Boolean(
                                                                                        touched.billing_first_name &&
                                                                                            errors.billing_first_name
                                                                                    )}
                                                                                    sx={{ ...theme.typography.customInput }}
                                                                                >
                                                                                    <InputLabel htmlFor="billing_first_name">
                                                                                        Ваше имя *
                                                                                    </InputLabel>
                                                                                    <OutlinedInput
                                                                                        id="billing_first_name"
                                                                                        type="text"
                                                                                        value={values.billing_first_name}
                                                                                        name="billing_first_name"
                                                                                        onBlur={handleBlur}
                                                                                        onChange={handleChange}
                                                                                        label="Введите ваше имя"
                                                                                        inputProps={{}}
                                                                                    />
                                                                                    {touched.billing_first_name &&
                                                                                        errors.billing_first_name && (
                                                                                            <FormHelperText error>
                                                                                                {errors.billing_first_name}
                                                                                            </FormHelperText>
                                                                                        )}
                                                                                </FormControl>
                                                                                <FormControl
                                                                                    fullWidth
                                                                                    error={Boolean(
                                                                                        touched.billing_country && errors.billing_country
                                                                                    )}
                                                                                    sx={{ ...theme.typography.customInput }}
                                                                                >
                                                                                    <Select
                                                                                        id="billing_country"
                                                                                        value={values.billing_country}
                                                                                        name="billing_country"
                                                                                        onBlur={handleBlur}
                                                                                        onChange={(e) => {
                                                                                            handleChange(e);
                                                                                            setCurrentCountry(e.target.value);
                                                                                        }}
                                                                                        label="Страна/регион *"
                                                                                        inputProps={{}}
                                                                                        style={{ height: 60 }}
                                                                                    >
                                                                                        <MenuItem value="Russia">Россия</MenuItem>
                                                                                    </Select>

                                                                                    {/* <OutlinedInput
                                                                                        id="billing_country"
                                                                                        value={values.billing_country}
                                                                                        name="billing_country"
                                                                                        onBlur={handleBlur}
                                                                                        onChange={handleChange}
                                                                                        label="Страна/регион *"
                                                                                        inputProps={{}}
                                                                                    /> */}
                                                                                    {touched.billing_country && errors.billing_country && (
                                                                                        <FormHelperText error>
                                                                                            {errors.billing_country}
                                                                                        </FormHelperText>
                                                                                    )}
                                                                                </FormControl>
                                                                                <FormControl
                                                                                    fullWidth
                                                                                    error={Boolean(
                                                                                        touched.billing_address_1 &&
                                                                                            errors.billing_address_1
                                                                                    )}
                                                                                    sx={{ ...theme.typography.customInput }}
                                                                                >
                                                                                    <InputLabel htmlFor="billing_address_1">
                                                                                        Адрес *
                                                                                    </InputLabel>
                                                                                    <OutlinedInput
                                                                                        id="billing_address_1"
                                                                                        value={values.billing_address_1}
                                                                                        name="billing_address_1"
                                                                                        onBlur={handleBlur}
                                                                                        onChange={handleChange}
                                                                                        label="Адрес *"
                                                                                        inputProps={{}}
                                                                                    />
                                                                                    {touched.billing_address_1 &&
                                                                                        errors.billing_address_1 && (
                                                                                            <FormHelperText error>
                                                                                                {errors.billing_address_1}
                                                                                            </FormHelperText>
                                                                                        )}
                                                                                </FormControl>
                                                                                <FormControl
                                                                                    fullWidth
                                                                                    error={Boolean(
                                                                                        touched.billing_phone && errors.billing_phone
                                                                                    )}
                                                                                    sx={{ ...theme.typography.customInput }}
                                                                                >
                                                                                    <InputLabel htmlFor="billing_phone">
                                                                                        Телефон *
                                                                                    </InputLabel>
                                                                                    <OutlinedInput
                                                                                        id="billing_phone"
                                                                                        type="tel"
                                                                                        value={values.billing_phone}
                                                                                        name="billing_phone"
                                                                                        onBlur={handleBlur}
                                                                                        onChange={handleChange}
                                                                                        label="Телефон *"
                                                                                        inputProps={{}}
                                                                                    />
                                                                                    {touched.billing_phone && errors.billing_phone && (
                                                                                        <FormHelperText error>
                                                                                            {errors.billing_phone}
                                                                                        </FormHelperText>
                                                                                    )}
                                                                                </FormControl>
                                                                            </Grid>
                                                                            <Grid item sm={6}>
                                                                                <FormControl
                                                                                    fullWidth
                                                                                    error={Boolean(
                                                                                        touched.billing_last_name &&
                                                                                            errors.billing_last_name
                                                                                    )}
                                                                                    sx={{ ...theme.typography.customInput }}
                                                                                >
                                                                                    <InputLabel htmlFor="billing_last_name">
                                                                                        Фамилия *
                                                                                    </InputLabel>
                                                                                    <OutlinedInput
                                                                                        id="billing_last_name"
                                                                                        value={values.billing_last_name}
                                                                                        name="billing_last_name"
                                                                                        onBlur={handleBlur}
                                                                                        onChange={handleChange}
                                                                                        label="Фамилия *"
                                                                                        inputProps={{}}
                                                                                    />
                                                                                    {touched.billing_last_name &&
                                                                                        errors.billing_last_name && (
                                                                                            <FormHelperText error>
                                                                                                {errors.billing_last_name}
                                                                                            </FormHelperText>
                                                                                        )}
                                                                                </FormControl>
                                                                                <FormControl
                                                                                    fullWidth
                                                                                    error={Boolean(
                                                                                        touched.billing_city && errors.billing_city
                                                                                    )}
                                                                                    sx={{ ...theme.typography.customInput }}
                                                                                >
                                                                                    <InputLabel htmlFor="billing_city">
                                                                                        Населённый пункт *
                                                                                    </InputLabel>
                                                                                    <OutlinedInput
                                                                                        id="billing_city"
                                                                                        value={values.billing_city}
                                                                                        name="billing_city"
                                                                                        onBlur={handleBlur}
                                                                                        onChange={handleChange}
                                                                                        label="Населённый пункт *"
                                                                                        inputProps={{}}
                                                                                    />
                                                                                    {touched.billing_city && errors.billing_city && (
                                                                                        <FormHelperText error>
                                                                                            {errors.billing_city}
                                                                                        </FormHelperText>
                                                                                    )}
                                                                                </FormControl>
                                                                                <FormControl
                                                                                    fullWidth
                                                                                    error={Boolean(
                                                                                        touched.billing_postcode && errors.billing_postcode
                                                                                    )}
                                                                                    sx={{ ...theme.typography.customInput }}
                                                                                >
                                                                                    <InputLabel htmlFor="billing_postcode">
                                                                                        Почтовый индекс *
                                                                                    </InputLabel>
                                                                                    <OutlinedInput
                                                                                        id="billing_postcode"
                                                                                        type="number"
                                                                                        value={values.billing_postcode}
                                                                                        name="billing_postcode"
                                                                                        onBlur={handleBlur}
                                                                                        onChange={handleChange}
                                                                                        label="Почтовый индекс *"
                                                                                        inputProps={{}}
                                                                                    />
                                                                                    {touched.billing_postcode &&
                                                                                        errors.billing_postcode && (
                                                                                            <FormHelperText error>
                                                                                                {errors.billing_postcode}
                                                                                            </FormHelperText>
                                                                                        )}
                                                                                </FormControl>
                                                                                <FormControl
                                                                                    fullWidth
                                                                                    error={Boolean(
                                                                                        touched.billing_email && errors.billing_email
                                                                                    )}
                                                                                    sx={{ ...theme.typography.customInput }}
                                                                                >
                                                                                    <InputLabel htmlFor="billing_email">
                                                                                        Email Address *
                                                                                    </InputLabel>
                                                                                    <OutlinedInput
                                                                                        id="billing_email"
                                                                                        type="email"
                                                                                        value={values.billing_email}
                                                                                        name="billing_email"
                                                                                        onBlur={handleBlur}
                                                                                        onChange={handleChange}
                                                                                        label="Email Address *"
                                                                                        inputProps={{}}
                                                                                    />
                                                                                    {touched.billing_email && errors.billing_email && (
                                                                                        <FormHelperText error>
                                                                                            {errors.billing_email}
                                                                                        </FormHelperText>
                                                                                    )}
                                                                                </FormControl>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Box>
                                                                            <h3 style={{ textAlign: 'center' }} id="order_review_heading">
                                                                                Ваш заказ
                                                                            </h3>
                                                                            <div
                                                                                id="order_review"
                                                                                className="woocommerce-checkout-review-order"
                                                                            >
                                                                                <table className="shop_table woocommerce-checkout-review-order-table">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th className="product-name">Товар</th>
                                                                                            <th className="product-total">Подытог</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody style={{ marginTop: 12 }}>
                                                                                        {/* // элемент списка товаров */}
                                                                                        {cart.map((product) => (
                                                                                            <tr key={product.id} className="cart_item">
                                                                                                <td className="product-name">
                                                                                                    {product.name} &nbsp;
                                                                                                    <strong className="product-quantity">
                                                                                                        &times;&nbsp;{product.count}
                                                                                                    </strong>
                                                                                                </td>
                                                                                                <td className="product-total">
                                                                                                    {product.count * product.cost},00&nbsp;
                                                                                                    <span className="woocommerce-Price-currencySymbol">
                                                                                                        <span className="rur">&#x440;</span>
                                                                                                    </span>
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                    <tfoot>
                                                                                        <tr className="cart-subtotal">
                                                                                            <th className="product-name">
                                                                                                <Card
                                                                                                    fullWidth
                                                                                                    style={{
                                                                                                        marginTop: 6,
                                                                                                        marginBottom: 6,
                                                                                                        height: 1,
                                                                                                        backgroundColor: '#f0f0f0'
                                                                                                    }}
                                                                                                />
                                                                                            </th>
                                                                                            <td className="product-total">
                                                                                                <Card
                                                                                                    fullWidth
                                                                                                    style={{
                                                                                                        marginTop: 6,
                                                                                                        marginBottom: 6,
                                                                                                        height: 1,
                                                                                                        backgroundColor: '#f0f0f0'
                                                                                                    }}
                                                                                                />
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr className="cart-subtotal">
                                                                                            <th className="product-name">Подытог</th>
                                                                                            <td className="product-total">
                                                                                                {cartItemsCost},00&nbsp;
                                                                                                <span className="woocommerce-Price-currencySymbol">
                                                                                                    <span className="rur">&#x440;</span>
                                                                                                </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                        {/* //! Итого  доставка */}
                                                                                        <tr
                                                                                            style={{ marginTop: 12 }}
                                                                                            className="woocommerce-shipping-totals shipping"
                                                                                        >
                                                                                            <th>Доставка</th>
                                                                                            <td data-title="Доставка">
                                                                                                <ul
                                                                                                    id="shipping_method"
                                                                                                    className="woocommerce-shipping-methods"
                                                                                                >
                                                                                                    {cartItemsCost > 3000 ? (
                                                                                                        <div>Бесплатная доставка</div>
                                                                                                    ) : (
                                                                                                        deliveryTypeList.map((item) => (
                                                                                                            <li key={item.title}>
                                                                                                                <span
                                                                                                                    role="button"
                                                                                                                    tabIndex={0}
                                                                                                                    onClick={() =>
                                                                                                                        setDeliveryType(
                                                                                                                            item
                                                                                                                        )
                                                                                                                    }
                                                                                                                    onKeyDown={() =>
                                                                                                                        setDeliveryType(
                                                                                                                            item
                                                                                                                        )
                                                                                                                    }
                                                                                                                    className={`shipping_method ${
                                                                                                                        deliveryType?.type ===
                                                                                                                        item.type
                                                                                                                            ? 'shipping_method-active'
                                                                                                                            : undefined
                                                                                                                    }`}
                                                                                                                >
                                                                                                                    {item.title}:<br />
                                                                                                                    {item.deliveryCost} руб
                                                                                                                </span>
                                                                                                            </li>
                                                                                                        ))
                                                                                                    )}
                                                                                                </ul>
                                                                                            </td>
                                                                                        </tr>
                                                                                        {/* //! Итого  стоимость с доставкой */}
                                                                                        <tr
                                                                                            style={{ marginTop: 12 }}
                                                                                            className="order-total"
                                                                                        >
                                                                                            <th className="product-name">Итого</th>
                                                                                            <td className="product-total">
                                                                                                <strong>
                                                                                                    {cartItemsCost +
                                                                                                        (cartItemsCost < 3000
                                                                                                            ? deliveryType?.deliveryCost ??
                                                                                                              0
                                                                                                            : 0)}
                                                                                                    ,00&nbsp;
                                                                                                    <span className="woocommerce-Price-currencySymbol">
                                                                                                        <span className="rur">
                                                                                                            &#x440;
                                                                                                            <span>&#x443;&#x431;.</span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </strong>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tfoot>
                                                                                </table>
                                                                                <div id="payment" className="woocommerce-checkout-payment">
                                                                                    <Stack
                                                                                        direction="row"
                                                                                        alignItems="center"
                                                                                        justifyContent="space-between"
                                                                                        spacing={1}
                                                                                    >
                                                                                        <FormControlLabel
                                                                                            control={
                                                                                                <Checkbox
                                                                                                    checked
                                                                                                    name="checked"
                                                                                                    color="primary"
                                                                                                />
                                                                                            }
                                                                                            label="Оплата онлайн"
                                                                                        />
                                                                                    </Stack>
                                                                                    <Stack
                                                                                        direction="row"
                                                                                        alignItems="center"
                                                                                        justifyContent="space-between"
                                                                                        spacing={1}
                                                                                    >
                                                                                        <FormControlLabel
                                                                                            control={
                                                                                                <Checkbox
                                                                                                    checked={checkedOferta}
                                                                                                    onChange={(event) =>
                                                                                                        setCheckedOferta(
                                                                                                            event.target.checked
                                                                                                        )
                                                                                                    }
                                                                                                    name="checked"
                                                                                                    color="primary"
                                                                                                />
                                                                                            }
                                                                                            label={
                                                                                                <div>
                                                                                                    <span
                                                                                                        className="w-form-label"
                                                                                                        htmlFor="privacy_policy_checkbox"
                                                                                                    >
                                                                                                        Я ознакомлен и согласен с{' '}
                                                                                                        <Link to="/oferta" className="link">
                                                                                                            условиями продажи Товаров
                                                                                                        </Link>{' '}
                                                                                                        а также согласен с{' '}
                                                                                                        <Link
                                                                                                            to="/rules-scretch"
                                                                                                            className="link"
                                                                                                        >
                                                                                                            правилами проведения акции
                                                                                                        </Link>
                                                                                                    </span>
                                                                                                    <FormHelperText
                                                                                                        error={checkedOferta === false}
                                                                                                    >
                                                                                                        Необходимо подтвердить согласие на
                                                                                                        условия продажи товаров и правила
                                                                                                        проведения акции
                                                                                                    </FormHelperText>
                                                                                                </div>
                                                                                            }
                                                                                        />
                                                                                    </Stack>
                                                                                    <Stack
                                                                                        direction="row"
                                                                                        alignItems="center"
                                                                                        justifyContent="space-between"
                                                                                        spacing={1}
                                                                                    >
                                                                                        <FormControlLabel
                                                                                            control={
                                                                                                <Checkbox
                                                                                                    checked={checkedPrivacy}
                                                                                                    onChange={(event) =>
                                                                                                        setCheckedPrivacy(
                                                                                                            event.target.checked
                                                                                                        )
                                                                                                    }
                                                                                                    name="checked"
                                                                                                    color="primary"
                                                                                                />
                                                                                            }
                                                                                            label={
                                                                                                <div>
                                                                                                    <span
                                                                                                        className="w-form-label"
                                                                                                        htmlFor="privacy_policy_checkbox"
                                                                                                    >
                                                                                                        Я ознакомлен &nbsp;и даю согласие на{' '}
                                                                                                        <Link
                                                                                                            to="/personal-data-use"
                                                                                                            className="link"
                                                                                                        >
                                                                                                            обработку моих персональных
                                                                                                            данных
                                                                                                        </Link>{' '}
                                                                                                        в соответствии с{' '}
                                                                                                        <Link
                                                                                                            to="/privacy-policy"
                                                                                                            className="link"
                                                                                                        >
                                                                                                            политикой обработки персональных
                                                                                                            данных
                                                                                                        </Link>
                                                                                                    </span>
                                                                                                    <FormHelperText
                                                                                                        error={checkedPrivacy === false}
                                                                                                    >
                                                                                                        Необходимо подтвердить согласие на
                                                                                                        обработку данных
                                                                                                    </FormHelperText>
                                                                                                </div>
                                                                                            }
                                                                                        />
                                                                                    </Stack>

                                                                                    {errors.submit && (
                                                                                        <Box sx={{ mb: 3 }}>
                                                                                            <FormHelperText error>
                                                                                                {errors.submit}
                                                                                            </FormHelperText>
                                                                                        </Box>
                                                                                    )}
                                                                                    {errors.billing_address_1 ||
                                                                                    errors.billing_city ||
                                                                                    errors.billing_country ||
                                                                                    errors.billing_email ||
                                                                                    errors.billing_first_name ||
                                                                                    errors.billing_last_name ||
                                                                                    errors.billing_phone ||
                                                                                    errors.billing_postcode ? (
                                                                                        <Box
                                                                                            sx={{ mb: 0 }}
                                                                                            display="flex"
                                                                                            justifyContent="center"
                                                                                            alignItems="center"
                                                                                        >
                                                                                            <FormHelperText error>
                                                                                                Проверьте заполнение формы
                                                                                            </FormHelperText>
                                                                                        </Box>
                                                                                    ) : null}
                                                                                    <Grid
                                                                                        style={{ marginLeft: 'auto', marginRight: 'auto' }}
                                                                                        maxWidth={520}
                                                                                        spacing={1}
                                                                                        container
                                                                                        columns={2}
                                                                                    >
                                                                                        <Grid
                                                                                            style={{
                                                                                                marginLeft: 'auto',
                                                                                                marginRight: 'auto'
                                                                                            }} //! del style after yookassa integration
                                                                                            item
                                                                                            sm={1}
                                                                                        >
                                                                                            <Button
                                                                                                disabled={
                                                                                                    isSubmitting ||
                                                                                                    !checkedPrivacy ||
                                                                                                    !checkedOferta
                                                                                                }
                                                                                                type="submit"
                                                                                                className="border-payment-button"
                                                                                            >
                                                                                                <img
                                                                                                    style={{
                                                                                                        width: '62px',
                                                                                                        height: '32px',
                                                                                                        marginRight: '6px'
                                                                                                    }}
                                                                                                    src="/cloud.png"
                                                                                                    alt=""
                                                                                                />
                                                                                                <p>
                                                                                                    Оплатить с помощью <b>CloudPayments</b>
                                                                                                </p>
                                                                                            </Button>
                                                                                        </Grid>
                                                                                        {/* <Grid item sm={1}>
                                                                                            <Button
                                                                                                disabled={
                                                                                                    isSubmitting ||
                                                                                                    !checkedPrivacy ||
                                                                                                    !checkedOferta
                                                                                                }
                                                                                                type="submit"
                                                                                                className="border-payment-button"
                                                                                            >
                                                                                                <img
                                                                                                    style={{
                                                                                                        width: '62px',
                                                                                                        height: '32px',
                                                                                                        marginRight: '6px'
                                                                                                    }}
                                                                                                    src="/yoo.png"
                                                                                                    alt=""
                                                                                                />
                                                                                                <p>
                                                                                                    Оплатить с помощью <b>YooKassa</b>
                                                                                                </p>
                                                                                            </Button>
                                                                                        </Grid> */}
                                                                                    </Grid>
                                                                                </div>
                                                                            </div>
                                                                        </Box>
                                                                    </form>
                                                                )}
                                                            </Formik>
                                                        </Box>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="cart_empty" data-content="cart_empty">
                                    <div>Ваша корзина пуста</div>
                                </div>
                            )}
                        </div>
                    </section>
                </div>
            </div>
        </LoadingOverlay>
    );
};

export default Checkout;
