import React from 'react';
import Prizes from 'pages/prizes';
import { Outlet } from 'react-router';

// login option 3 routing

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const PrizesRoutes = {
    path: '/prizes/',
    element: <Outlet />,
    children: [
        {
            path: '/:orderId',
            element: <Prizes />
        }
    ]
};

export default PrizesRoutes;
