import config from 'config';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CURRENT_SET_MENU } from 'store/current-actions';

const Menu = () => {
    const dispatch = useDispatch();
    const isOpen = useSelector((state) => state.state.isOpen);

    const handleOpenMenu = () => {
        dispatch({ type: CURRENT_SET_MENU, payload: !isOpen });
    };

    return (
        <div style={{ display: isOpen ? 'block' : 'none' }} className="nav_wrapper">
            <div className="container navbox">
                <div
                    role="button"
                    tabIndex={0}
                    onClick={() => handleOpenMenu()}
                    onKeyDown={() => handleOpenMenu()}
                    className="nav_close_block"
                >
                    <img
                        src="data:image/svg+xml,%3Csvg%20xmlns='https://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"
                        loading="lazy"
                        data-w-id="a8e5a361-90f9-a955-abfc-5c37aea98f3b"
                        alt=""
                        className="nav_close-ico"
                        data-lazy-src={`${config.storeUrl}/61a89ad8292c64179d38f7bc_GroupD0B2204.svg`}
                    />
                    <div data-w-id="a8e5a361-90f9-a955-abfc-5c37aea98f3c" className="nav_close_text">
                        ЗАКРЫТЬ МЕНЮ
                    </div>
                </div>
                <div className="nav_collumns">
                    <div className="nav_col1">
                        <Link className="nav_link" to="/products/catalog" onClick={handleOpenMenu}>
                            Каталог
                        </Link>
                        <div className="subnav_items">
                            <Link className="subnav_link" to="/products/bestsellers" onClick={handleOpenMenu}>
                                бестселлеры
                            </Link>{' '}
                            <Link className="subnav_link" to="/products/anticellulite" onClick={handleOpenMenu}>
                                антицеллюлитные средства
                            </Link>{' '}
                            <Link className="subnav_link" to="/products/for-face" onClick={handleOpenMenu}>
                                средства для лица
                            </Link>{' '}
                            <Link className="subnav_link" to="/products/for-hands-foots" onClick={handleOpenMenu}>
                                Для рук и ног
                            </Link>{' '}
                        </div>
                    </div>
                    <div className="nav_col2">
                        <Link className="nav_link" to="/about" onClick={handleOpenMenu}>
                            О компании
                        </Link>{' '}
                        <Link className="nav_link" to="/delivery" onClick={handleOpenMenu}>
                            Доставка и оплата
                        </Link>{' '}
                        <Link className="nav_link" to="/faq" onClick={handleOpenMenu}>
                            FAQ
                        </Link>{' '}
                    </div>
                    <div className="nav_col3">
                        <div className="nav_box_title">Возникли вопросы по доставке заказа?</div>
                        <a href="tel:+" className="nav_link connect">
                            <br />
                        </a>
                        <a
                            href="https://api.whatsapp.com/send?phone=79951566228"
                            rel="noreferrer"
                            target="_blank"
                            className="subnav_link whatsapp"
                        >
                            Написать в Whatsapp
                        </a>
                        <div className="nav_separator" />
                        <div className="nav_box_title">вы можете написать нам на e-mail</div>
                        <a href="mailto:millidgecosmetic@gmail.com" className="nav_link connect">
                            <span className="__cf_email__">millidgecosmetic@gmail.com</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Menu;
