export const instanceUrl =
    process.env.NODE_ENV === 'production'
        ? 'https://millidge.xyz/api' // production server
        : 'https://millidge.xyz/api';

// import { axios } from 'axios';

// export function instance() {
//     const instanceUrl =
//         process.env.NODE_ENV === 'production'
//             ? 'https://millerartemiy.ru/api/' // production server
//             : 'http://127.0.0.1:4000/api/'; // debug server
//     // const credentials = await getCredentials()
//     return axios.create({
//         baseURL: instanceUrl
//         // headers: {
//         //   Authorization: `Bearer ${credentials?.access_token ?? ''}`
//         // }
//     });
// }
