import React from 'react';

// dashboard routing
// import MainPage from 'pages/home';

// sample page routing
import MainLayout from 'layout/main-layout';
import DeliveryPage from 'pages/delivery';
import About from 'pages/about';
import PrivacyPolicy from 'pages/privacy-policy';
import PrivacyPolicyFull from 'pages/privacy-policy-full';
import Faq from 'pages/faq';
import Cart from 'pages/cart';
import Catalog from 'pages/catalog';
import RulesScretch from 'pages/rules-scretch';
import Oferta from 'pages/oferta';
import PersonalDataUse from 'pages/personal-data-use';
import Checkout from 'pages/checkout';

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <Catalog />
        },
        {
            path: '/delivery',
            element: <DeliveryPage />
        },
        {
            path: '/about',
            element: <About />
        },
        {
            path: '/privacy-policy',
            element: <PrivacyPolicy />
        },
        {
            path: '/privacy-policy-full',
            element: <PrivacyPolicyFull />
        },
        {
            path: '/rules-scretch',
            element: <RulesScretch />
        },
        {
            path: '/oferta',
            element: <Oferta />
        },
        {
            path: '/personal-data-use',
            element: <PersonalDataUse />
        },
        {
            path: '/faq',
            element: <Faq />
        },
        {
            path: '/cart',
            element: <Cart />
        },
        {
            path: '/checkout',
            element: <Checkout />
        }
    ]
};

export default MainRoutes;
