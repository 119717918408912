// project imports
import produce from 'immer';

// action - state management
import * as actionTypes from './current-actions';
import { products } from './products_data/products_data';

export const initialState = {
    isOpen: false,
    cart: [],
    products
};

const currentReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case actionTypes.CURRENT_SET_MENU:
                draft.isOpen = action.payload;
                return;
            case actionTypes.ADD_TO_CART:
                draft.cart = actionTypes.addTocCard(state, action.payload);
                return;
            case actionTypes.REPLACE_CART:
                draft.cart = action.payload;
                return;
            case actionTypes.MINUS_CART_PRODUCT:
                draft.cart = actionTypes.minusCartProduct(state, action.payload);
                return;
            case actionTypes.DEL_CART_PRODUCT:
                draft.cart = state.cart.filter((item) => item.id !== action.payload);
                return;
            default:
                draft = state;
        }
    });

export default currentReducer;
