import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './main-routes';
import ProductsRoutes from './products-routes';
import config from 'config';
import PrizesRoutes from './prizes-routes';
import PaymentsRoutes from './payments-routes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([MainRoutes, ProductsRoutes, PrizesRoutes, PaymentsRoutes], config.basename);
}
