import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { useCookies } from 'react-cookie';
import { REPLACE_CART } from 'store/current-actions';
// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);
    const dispatch = useDispatch();
    const [isInitCookies, setIsInitCookies] = useState(false);

    const [cookies] = useCookies(['xxx']);

    useEffect(() => {
        // removeCookie('productsCart');
        if (!isInitCookies && cookies.productsCart && cookies.productsCart.length > 0) {
            dispatch({ type: REPLACE_CART, payload: cookies.productsCart });
            setIsInitCookies(true);
            console.log('cookies was restored');
        }
    }, [cookies, isInitCookies, dispatch]);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <Routes />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
