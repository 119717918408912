import { Breadcrumbs, Typography } from '@mui/material';
import Header from 'components/header';
import { PlusMinusCountItem } from 'components/plus-minus-count-item';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Link as LinkRout } from 'react-router-dom';
import { ADD_TO_CART, DEL_CART_PRODUCT, MINUS_CART_PRODUCT } from 'store/current-actions';

const delivery = 0;

const Cart = () => {
    const dispatch = useDispatch();
    const cart = useSelector((state) => state.state.cart);
    const [cartItemsCount, setCartItemsCount] = useState(0);
    const [cartItemsCost, setCartItemsCost] = useState(0);
    const [cartItemsWeight, setCartItemsWeight] = useState(0);

    useEffect(() => {
        if (cart) {
            let count = 0;
            let cost = 0;
            let weight = 0;
            cart.forEach((element) => {
                count += element.count;
                cost += element.cost * element.count;
                weight += element.weight * element.count;
            });
            setCartItemsCount(count);
            setCartItemsCost(cost);
            setCartItemsWeight(weight);
        }
    }, [cart]);

    const handleAddToCart = (cartItem) => {
        dispatch({ type: ADD_TO_CART, payload: cartItem });
    };

    const handleMinusProduct = (id) => {
        dispatch({ type: MINUS_CART_PRODUCT, payload: id });
    };

    const handleDelCartProduct = (id) => {
        dispatch({ type: DEL_CART_PRODUCT, payload: id });
    };

    return (
        <div>
            <Header />
            <div className="wrapper owerflow">
                <section className="section">
                    <div className="container">
                        <Breadcrumbs className="breadcrumbs" aria-label="breadcrumb">
                            <LinkRout underline="hover" color="inherit" to="/">
                                Главная
                            </LinkRout>
                            <Typography color="text.primary">Оформление заказа</Typography>
                        </Breadcrumbs>
                        {cart.length > 0 ? (
                            <div data-content="cart_content">
                                <h1 className="page_title centerred">Оформление заказа</h1>
                                <div className="checkout_nav">
                                    <Link className="checkout_nav_item for_cart w-inline-block" to="/cart">
                                        <div className="checkout_step_box cart" />
                                        <div>Корзина</div>
                                        <div className="dotted_line" />
                                    </Link>
                                    <Link className="checkout_nav_item w-inline-block" to="/checkout">
                                        <div className="checkout_step_box delivery" />
                                        <div>Доставка и Оплата</div>
                                    </Link>
                                </div>
                                <div className="checkout-box cart" data-wc="full_cart" data-content="cart_content">
                                    <div className="cart_items">
                                        {cart.map((product) => (
                                            <div key={product.id} className="cart_item_box" data-content="cart_item">
                                                <div className="cart_cell">
                                                    <div className="cart_image_box">
                                                        <img
                                                            src={product.imageUrl}
                                                            loading="eager"
                                                            wp_size="product-thumbnail"
                                                            alt=""
                                                            className="cart_image"
                                                        />
                                                    </div>
                                                    <div className="cart_col">
                                                        <div className="cart_product_info">
                                                            <h4>{product.name}</h4>
                                                            <div className="cart_product_weight">
                                                                <span>{product.weight}</span> <span>{product.weightType}</span>
                                                            </div>
                                                        </div>
                                                        <div className="cart_item_price" data-content="product_price">
                                                            <span className="woocommerce-Price-amount amount">
                                                                <bdi>
                                                                    {product.cost},00&nbsp;
                                                                    <span className="woocommerce-Price-currencySymbol">
                                                                        <span className="rur">
                                                                            &#x440;<span>&#x443;&#x431;.</span>
                                                                        </span>
                                                                    </span>
                                                                </bdi>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="cart_cell_right">
                                                    <div className="cart_item_form w-form">
                                                        <PlusMinusCountItem
                                                            handleMinusProduct={() => handleMinusProduct(product.id)}
                                                            handleAddToCart={() => handleAddToCart(product)}
                                                            count={product.count}
                                                        />
                                                    </div>
                                                    <div
                                                        role="button"
                                                        tabIndex={0}
                                                        onClick={() => handleDelCartProduct(product.id)}
                                                        onKeyDown={() => handleDelCartProduct(product.id)}
                                                        className="cart_dell w-inline-block"
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="checkout_total">
                                        <Link className="button w-button" to="/checkout">
                                            Оформить доставку
                                        </Link>
                                        <div className="cart_total_box">
                                            <div className="cart_title">Ваша корзина</div>
                                            <div className="cart_total_item">
                                                <div>Товара ({cartItemsCount} шт)</div>
                                                <div className="item_index" data-wc="cart_total">
                                                    <span className="woocommerce-Price-amount amount">
                                                        <bdi>
                                                            {cartItemsCost},00&nbsp;
                                                            <span className="woocommerce-Price-currencySymbol">
                                                                <span className="rur">
                                                                    &#x440;<span>&#x443;&#x431;.</span>
                                                                </span>
                                                            </span>
                                                        </bdi>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="cart_total_item">
                                                <div>Вес посылки</div>
                                                <div className="item_index">
                                                    <span>{cartItemsWeight}</span> Г
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="delivery_total">
                                            <div className="cart_total_item">
                                                <div>Доставка</div>
                                                <div className="item_index">{delivery} ₽</div>
                                            </div>
                                        </div> */}
                                        <div className="cart_total_item total">
                                            <div className="cart_total">Итого:</div>
                                            <div className="item_index total" data-wc="cart_total">
                                                <span className="woocommerce-Price-amount amount">
                                                    <bdi>
                                                        {cartItemsCost + delivery},00&nbsp;
                                                        <span className="woocommerce-Price-currencySymbol">
                                                            <span className="rur">
                                                                &#x440;<span>&#x443;&#x431;.</span>
                                                            </span>
                                                        </span>
                                                    </bdi>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="cart_empty" data-content="cart_empty">
                                <div>Ваша корзина пуста</div>
                            </div>
                        )}
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Cart;
